import {
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CreateJobForm,
  JOB_CONTACT_EMAIL_MAX_LENGTH,
  JOB_CONTACT_NAME_MAX_LENGTH,
  JOB_CONTACT_PHONE_MAX_LENGTH,
  UpdateJobDraftSchema,
} from '../../../../../models';
import { getOptionalLabelText } from '../../../../../utils';
import { useSaveField } from '../create-job.helpers';

function ContactNameInput() {
  const formContext = useFormContext<CreateJobForm>();

  const saveContactName = useSaveField('contactName', {
    schema: UpdateJobDraftSchema.contactName,
    defaultValue: formContext.getValues('contactName'),
  });

  return (
    <ControlledInput
      name="contactName"
      maxLength={JOB_CONTACT_NAME_MAX_LENGTH}
      onBlur={saveContactName}
      leadingElements={[
        {
          type: 'icon',
          name: 'user-outline',
        },
      ]}
      labelTx="label.job-form.name"
    />
  );
}

function ContactEmailInput() {
  const formContext = useFormContext<CreateJobForm>();

  const saveContactEmail = useSaveField('contactEmail', {
    schema: UpdateJobDraftSchema.contactEmail,
    defaultValue: formContext.getValues('contactEmail'),
  });

  return (
    <ControlledInput
      name="contactEmail"
      maxLength={JOB_CONTACT_EMAIL_MAX_LENGTH}
      onBlur={saveContactEmail}
      leadingElements={[
        {
          type: 'icon',
          name: 'envelope-outline',
        },
      ]}
      labelTx="label.job-form.email"
    />
  );
}

function ContactPhoneInput() {
  const formContext = useFormContext<CreateJobForm>();

  const saveContactPhone = useSaveField('contactPhone', {
    schema: UpdateJobDraftSchema.contactPhone,
    defaultValue: formContext.getValues('contactPhone'),
  });

  return (
    <ControlledInput
      name="contactPhone"
      maxLength={JOB_CONTACT_PHONE_MAX_LENGTH}
      onBlur={saveContactPhone}
      leadingElements={[
        {
          type: 'icon',
          name: 'phone-outline',
        },
      ]}
      label={getOptionalLabelText('label.job-form.phone-number')}
    />
  );
}

const contactDetailsSectionHeader = (
  <FormSectionHeader>
    <Text
      tx="label.job-form.steps.6.title"
      variant="bodyMdBold"
      color="formSectionHeader"
    />
    <Text
      color="formSectionDescription"
      tx="label.job-form.steps.6.subtitle"
      variant="bodyMd"
    />
  </FormSectionHeader>
);

function _ContactDetails() {
  return (
    <FormSection>
      {contactDetailsSectionHeader}

      <ContactNameInput />
      <ContactEmailInput />
      <ContactPhoneInput />
    </FormSection>
  );
}

_ContactDetails.displayName = 'ContactDetails';
export const ContactDetails = React.memo(_ContactDetails);
