import {
  INITIAL_MODAL_STATE,
  ModalState,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

export const QuickActionsContext = React.createContext<{
  createProfileModalState: ModalState;
}>({
  createProfileModalState: INITIAL_MODAL_STATE,
});

export function QuickActionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const createProfileModalState = useModalState();

  return (
    <QuickActionsContext.Provider value={{ createProfileModalState }}>
      {children}
    </QuickActionsContext.Provider>
  );
}
