import styled, { css, keyframes } from 'styled-components';

import { Box } from '../box';
import { StyledSpinnerProps } from './spinner.types';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Box)<StyledSpinnerProps>`
  border-radius: 50%;
  animation: ${spin} 0.5s linear infinite;
  border-style: solid;

  ${(props) => {
    const { theme, size } = props;

    return css`
      border-color: ${theme.colors['spinnerBackground']};
      border-top-color: ${theme.colors['spinnerColor']};

      width: ${size || 30}px;
      height: ${size || 30}px;
      border-width: ${(size || 30) / 10}px;
    `;
  }}
`;

export const Styled = { Spinner };
