import React from 'react';

import { useOnClickOutside } from '../../helpers';
import { Placement, getPlacementCords } from '../../utils';
import { Box } from '../box';
import { Button } from '../button';
import { Checkbox } from '../checkbox';
import { Icon } from '../icon';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './menu.styled';
import {
  MenuDividerProps,
  MenuFooterProps,
  MenuItemProps,
  MenuProps,
  UseAnchoredMenuProps,
  UseMenuProps,
} from './menu.types';

export function useMenu(props?: UseMenuProps) {
  const [isOpen, setIsOpen] = React.useState(props?.defaultIsOpen ?? false);

  const menuRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideRef = React.useRef<HTMLDivElement>(null);

  const closeMenu = React.useCallback(() => setIsOpen(false), []);
  const openMenu = React.useCallback(() => setIsOpen(true), []);
  const toggleMenu = React.useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    [],
  );

  useOnClickOutside({
    handler: () => {
      props?.onClickOutside?.();
      closeMenu();
    },
    isActive: isOpen,
    ref: clickOutsideRef,
  });

  return { closeMenu, isOpen, menuRef, clickOutsideRef, openMenu, toggleMenu };
}

function placeMenu(
  anchorRef: React.MutableRefObject<HTMLElement | null>,
  menuRef: React.MutableRefObject<HTMLElement | null>,
  placement: Placement,
  syncWidth: boolean,
) {
  if (!anchorRef.current || !menuRef.current) return;

  menuRef.current.style.position = 'fixed';

  const { left, top } = getPlacementCords(
    menuRef.current,
    anchorRef.current,
    placement,
  );

  if (syncWidth) {
    menuRef.current.style.width = `${anchorRef.current.offsetWidth}px`;
  }

  menuRef.current.style.left = `${left}px`;
  menuRef.current.style.top = `${top}px`;
}

export function useAnchoredMenu<T extends HTMLElement = HTMLDivElement>(
  props: UseAnchoredMenuProps,
) {
  const { autoCloseDuration, placement, syncWidth = false } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const clickOutsideRef = React.useRef<HTMLDivElement>(null);
  const anchorRef = React.useRef<T>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const closeMenu = () => setIsOpen(false);
  const openMenu = () => setIsOpen(true);
  const toggleMenu = () => setIsOpen((isOpen) => !isOpen);

  useOnClickOutside({
    handler: () => {
      props.onClickOutside?.();
      closeMenu();
    },
    isActive: isOpen,
    ref: clickOutsideRef,
  });

  React.useEffect(() => {
    if (!anchorRef.current || !menuRef.current) return;

    const onResize = () => placeMenu(anchorRef, menuRef, placement, syncWidth);

    const observer = new ResizeObserver(onResize);

    observer.observe(anchorRef.current);
    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', onResize);
    };
  }, [anchorRef, menuRef, isOpen, placement, syncWidth]);

  React.useEffect(() => {
    if (!isOpen || !autoCloseDuration) return;

    let timeout = window.setTimeout(closeMenu, autoCloseDuration);

    menuRef.current?.addEventListener('mouseenter', () => {
      window.clearTimeout(timeout);
    });

    menuRef.current?.addEventListener('mouseleave', () => {
      timeout = window.setTimeout(closeMenu, autoCloseDuration);
    });

    return () => window.clearTimeout(timeout);
  }, [autoCloseDuration, isOpen]);

  return {
    closeMenu,
    isOpen,
    menuRef,
    clickOutsideRef,
    anchorRef,
    openMenu,
    toggleMenu,
  };
}

export type AnchoredMenuState<T extends HTMLElement = HTMLDivElement> =
  ReturnType<typeof useAnchoredMenu<T>>;

export function MenuDivider(props: MenuDividerProps) {
  const { children, tx, txArgs, text, ...rest } = props;

  return (
    <Box backgroundColor="menuDividerBackground" p={12} {...rest}>
      {children}
      <Text
        color="menuDividerLabelColor"
        text={text}
        tx={tx}
        txArgs={txArgs}
        variant="bodySm"
      />
    </Box>
  );
}

export function MenuFooter(props: MenuFooterProps) {
  const {
    primaryButtonIsLoading,
    primaryButtonOnClick,
    primaryButtonText,
    primaryButtonTx,
    primaryButtonTxArgs,
    primaryButtonVariant = 'primary',
    primaryButtonDisabled,
    primaryButtonTooltipTx,
    primaryButtonTooltipPlacement = 'bottom',
    primaryButtonTooltipTxArgs,

    secondaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonTx,
    secondaryButtonTxArgs,
    secondaryButtonVariant = 'tertiary',
  } = props;

  const primaryButton = (
    <Button
      height={28}
      onClick={primaryButtonOnClick}
      text={primaryButtonText}
      tx={primaryButtonTx}
      txArgs={primaryButtonTxArgs}
      variant={primaryButtonVariant}
      isLoading={primaryButtonIsLoading}
      disabled={primaryButtonDisabled}
    />
  );

  return (
    <Styled.MenuFooter
      backgroundColor="menuFooterBackground"
      bottom={0}
      flex
      flexJustify="end"
      p={8}
      sticky
      zIndex={10}
      gap={8}
    >
      <Button
        height={28}
        onClick={secondaryButtonOnClick}
        text={secondaryButtonText}
        tx={secondaryButtonTx}
        txArgs={secondaryButtonTxArgs}
        variant={secondaryButtonVariant}
      />

      {primaryButtonTooltipTx ? (
        <Tooltip
          titleTx={primaryButtonTooltipTx}
          placement={primaryButtonTooltipPlacement}
          titleTxArgs={primaryButtonTooltipTxArgs}
        >
          {primaryButton}
        </Tooltip>
      ) : (
        primaryButton
      )}
    </Styled.MenuFooter>
  );
}

function _MenuItem(props: MenuItemProps, ref: React.ForwardedRef<HTMLElement>) {
  const {
    children,
    to,
    index,
    text,
    tx,
    txArgs,
    textVariant = 'bodyMd',
    icon,
    iconColor,
    color,
    ...rest
  } = props;

  const lineHeight = icon || props.checkbox ? 'unset' : undefined;

  if (to) {
    return (
      <Styled.MenuItemLink p={8} ref={ref} role="menuitem" to={to} {...rest}>
        {icon && <Icon name={icon} color={iconColor} />}

        {props.checkbox && (
          <Checkbox
            disabled={props.disabled}
            onChange={() => {}}
            checked={props.isSelected ?? false}
          />
        )}

        {children || (
          <Text
            as="span"
            text={text}
            tx={tx}
            txArgs={txArgs}
            variant={textVariant}
            lineHeight={lineHeight}
            color={color}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          />
        )}
      </Styled.MenuItemLink>
    );
  }

  return (
    <Styled.MenuItem p={8} ref={ref} role="menuitem" {...rest}>
      {icon && <Icon name={icon} color={iconColor} />}

      {props.checkbox && (
        <Checkbox
          disabled={props.disabled}
          onChange={() => {}}
          checked={props.isSelected ?? false}
        />
      )}

      {children || (
        <Text
          as="span"
          text={text}
          tx={tx}
          txArgs={txArgs}
          variant={textVariant}
          color={color}
          lineHeight={lineHeight}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        />
      )}
    </Styled.MenuItem>
  );
}

function _Menu(props: MenuProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { children, isOpen = true, onToggle, ...rest } = props;

  return (
    <Styled.Menu
      backgroundColor="backgroundPrimary"
      isOpen={isOpen}
      role="menu"
      ref={ref}
      {...rest}
    >
      {children}
    </Styled.Menu>
  );
}

export const MenuItem = React.forwardRef(_MenuItem);
export const Menu = React.forwardRef(_Menu);
