import styled, { css, keyframes } from 'styled-components';

import { Box } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';
import { AspectRatio, StyledUploadProps } from './upload.types';

const bounceAnimation = keyframes`
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
`;

function getUploadContainerStyles(aspectRatio: AspectRatio) {
  switch (aspectRatio) {
    case '16:10':
      return css`
        aspect-ratio: 16/10;
        border-radius: 8px;
      `;

    case '1':
      return css`
        aspect-ratio: 1;
        border-radius: 50%;
      `;
  }
}

const UploadText = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      transition: color ${theme.transitions.default};
    `;
  }}
`;

const UploadBackdrop = styled(Box)<{ aspectRatio?: AspectRatio }>`
  ${(props) => {
    const { theme, aspectRatio = '16:10' } = props;

    return css`
      ${getUploadContainerStyles(aspectRatio)};
      transition: all ${theme.transitions.default};
      opacity: 0;
      background-color: rgba(0, 0, 0, 0);
    `;
  }}
`;

const Upload = styled(Box)<StyledUploadProps>`
  ${(props) => {
    const { aspectRatio = '16:10', disabled, theme, src } = props;

    return css`
      ${getUploadContainerStyles(aspectRatio)};

      background-color: ${theme.colors.uploadBackground};
      background-image: url(${src});
      background-position: center;
      background-size: cover;
      cursor: pointer;
      max-width: 100%;
      min-width: fill-available;

      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
      `}

      @media (min-width: ${theme.breakpoints.xs}px) {
        min-width: fit-content;
      }

      ${UploadText} {
        color: ${theme.colors.textPrimary};
      }

      ${src &&
      css`
        &:hover {
          ${UploadText} {
            color: ${theme.colors.textLight};
          }
        }
      `}

      ${!src &&
      css`
        border: 1px dashed ${theme.colors.uploadBorder};
      `}

      &:hover {
        background-color: ${theme.colors.uploadBackgroundHover};

        ${UploadBackdrop} {
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    `;
  }}
`;

const UploadIcon = styled(Icon)`
  animation: ${bounceAnimation} 1s infinite;
`;

export const Styled = { Upload, UploadIcon, UploadBackdrop, UploadText };
