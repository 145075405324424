import React from 'react';

import { useFilter, useScroll } from '../../helpers';
import { theme } from '../../theme';
import { getPlacementMargin } from '../../utils';
import { Avatar, AvatarProps } from '../avatar';
import { Box } from '../box';
import { Divider } from '../divider';
import { Link, LinkProps } from '../link';
import { AnchoredMenuState, Menu, useAnchoredMenu } from '../menu';
import { ResponsiveBox } from '../responsive-box';
import { SearchInput } from '../search-input';
import { Sheet } from '../sheet';
import { Text } from '../text';
import { Styled } from './select-workspace.styled';

export interface SelectWorkspaceMenuItem {
  label: string;

  to?: string;

  avatarVariant: AvatarProps['variant'];
  avatarSrc?: string;

  switchTx?: TxString;
}

interface SelectWorkspaceMenuProps {
  label: string;

  avatarSrc?: string;
  avatarVariant?: AvatarProps['variant'];

  workspaces: SelectWorkspaceMenuItem[];

  emptyStateTx: TxString;

  emptyStateLinkTo?: string;
  emptyStateLinkTx?: TxString;
  emptyStateLinkHref?: string;
  emptyStateLinkTarget?: LinkProps['target'];

  filterPlaceholderTx: TxString;
}

interface SelectWorkspaceContextType extends SelectWorkspaceMenuProps {
  showSearchInput: boolean;
  workspacesIsEmpty: boolean;
  filter: ReturnType<typeof useFilter<SelectWorkspaceMenuItem>>;
  anchoredMenu: AnchoredMenuState;
}

const SelectWorkspaceContext = React.createContext<SelectWorkspaceContextType>(
  {} as SelectWorkspaceContextType,
);

function renderWorkspaceMenuItem(
  menuItem: SelectWorkspaceMenuItem,
  index: number,
) {
  return (
    <Styled.WorkspaceMenuItem
      key={`workspace-menu-item-${index}`}
      to={menuItem.to}
    >
      <Box width="100%" flex flexJustify="between" gap={8}>
        <Box flexGrow={1} flexAlign="center" overflow="hidden" gap={8} flex>
          <Avatar
            fallbackLetter={menuItem.label.charAt(0)}
            src={menuItem.avatarSrc}
            variant={menuItem.avatarVariant}
            height={24}
            minHeight={24}
            width={24}
            minWidth={24}
          />

          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            variant="bodyMd"
            text={menuItem.label}
            whiteSpace="nowrap"
          />
        </Box>

        {menuItem.switchTx && <Styled.SwitchLink tx={menuItem.switchTx} />}
      </Box>
    </Styled.WorkspaceMenuItem>
  );
}

function renderWorkspaceSheetMenuItem(
  menuItem: SelectWorkspaceMenuItem,
  index: number,
) {
  return (
    <Link plain key={`workspace-sheet-menu-item-${index}`} to={menuItem.to}>
      <Box p={16} flex flexJustify="between" gap={8}>
        <Box flexAlign="center" overflow="hidden" gap={8} flex>
          <Avatar
            fallbackLetter={menuItem.label.charAt(0)}
            src={menuItem.avatarSrc}
            variant={menuItem.avatarVariant}
            height={24}
            minHeight={24}
            width={24}
            minWidth={24}
          />

          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            variant="bodyMd"
            text={menuItem.label}
            whiteSpace="nowrap"
          />
        </Box>
      </Box>

      <Divider />
    </Link>
  );
}

function SelectWorkspaceAvatar() {
  const { avatarSrc, avatarVariant, label } = React.useContext(
    SelectWorkspaceContext,
  );

  return (
    <ResponsiveBox
      xs={
        <Avatar
          width={40}
          height={40}
          alt={label}
          src={avatarSrc}
          fallbackLetter={label.charAt(0)}
          variant={avatarVariant}
        />
      }
    >
      <Avatar
        width={32}
        height={32}
        alt={label}
        src={avatarSrc}
        fallbackLetter={label.charAt(0)}
        variant={avatarVariant}
      />
    </ResponsiveBox>
  );
}

function SelectWorkspacesMenuContent() {
  const { anchoredMenu, filter, showSearchInput, workspacesIsEmpty } =
    React.useContext(SelectWorkspaceContext);

  const menuHeaderRef = React.useRef<HTMLDivElement>(null);

  useScroll(anchoredMenu.menuRef, (value) => {
    if (!menuHeaderRef.current) return;

    menuHeaderRef.current.style.boxShadow = value.isAtTop
      ? 'none'
      : theme.elevation.strong;
  });

  return (
    <Menu
      width={workspacesIsEmpty ? 'auto' : 300}
      isOpen={anchoredMenu.isOpen}
      ref={anchoredMenu.menuRef}
      fixed
      maxHeight={320}
      {...getPlacementMargin('bottom-start')}
    >
      {showSearchInput && (
        <Box
          ref={menuHeaderRef}
          sticky
          top={0}
          backgroundColor="menuItemBackground"
          p={8}
        >
          <FilterWorkspacesInput />
        </Box>
      )}

      {workspacesIsEmpty ? (
        <Styled.WorkspaceMenuItem>
          <Box flex gap={16}>
            <EmptyStateText />
            <EmptyStateLink />
          </Box>
        </Styled.WorkspaceMenuItem>
      ) : (
        filter.items.map(renderWorkspaceMenuItem)
      )}
    </Menu>
  );
}

function SelectWorkspacesSheet() {
  const { anchoredMenu, filter, showSearchInput, workspacesIsEmpty } =
    React.useContext(SelectWorkspaceContext);

  const sheetHeaderRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  useScroll(scrollContainerRef, (value) => {
    if (!sheetHeaderRef.current) return;

    sheetHeaderRef.current.style.boxShadow = value.isAtTop
      ? 'none'
      : theme.elevation.strong;
  });

  return (
    <Sheet
      isOpen={anchoredMenu.isOpen}
      onClose={anchoredMenu.closeMenu}
      pt={16}
    >
      <Box ref={scrollContainerRef} overflow="auto" height="100%">
        {showSearchInput && (
          <Box
            ref={sheetHeaderRef}
            sticky
            top={0}
            backgroundColor="menuItemBackground"
            p={12}
          >
            <FilterWorkspacesInput />
          </Box>
        )}

        {workspacesIsEmpty ? (
          <Box
            mt={64}
            flexAlign="center"
            flex
            flexDirection="column"
            flexJustify="center"
            gap={16}
          >
            <EmptyStateText />
            <EmptyStateLink />
          </Box>
        ) : (
          filter.items.map(renderWorkspaceSheetMenuItem)
        )}
      </Box>
    </Sheet>
  );
}

function EmptyStateText() {
  const { emptyStateTx } = React.useContext(SelectWorkspaceContext);

  return (
    <Text
      color="selectWorkspaceEmptyStateLabel"
      overflow="hidden"
      textOverflow="ellipsis"
      tx={emptyStateTx}
      variant="bodySmItalic"
      whiteSpace="nowrap"
    />
  );
}

function EmptyStateLink() {
  const {
    emptyStateLinkTo,
    emptyStateLinkHref,
    emptyStateLinkTarget,
    emptyStateLinkTx,
  } = React.useContext(SelectWorkspaceContext);

  if (!emptyStateLinkTo && !emptyStateLinkHref) {
    return null;
  }

  return (
    <Link
      href={emptyStateLinkHref}
      target={emptyStateLinkTarget}
      to={emptyStateLinkTo}
      tx={emptyStateLinkTx}
      variant="secondary"
      small
    />
  );
}

function FilterWorkspacesInput() {
  const { filter, filterPlaceholderTx } = React.useContext(
    SelectWorkspaceContext,
  );

  return (
    <SearchInput
      placeholderTx={filterPlaceholderTx}
      value={filter.searchString}
      onChange={filter.handleSearchStringChange}
      max={255}
    />
  );
}

function SelectWorkspaceMenuContent() {
  const { anchoredMenu, label } = React.useContext(SelectWorkspaceContext);

  return (
    <Box overflow="hidden" ref={anchoredMenu.clickOutsideRef}>
      <Styled.SelectWorkspaceBox
        cursor="pointer"
        flex
        flexAlign="center"
        gap={8}
        isOpen={anchoredMenu.isOpen}
        onClick={anchoredMenu.toggleMenu}
        overflow="hidden"
        ref={anchoredMenu.anchorRef}
        p={4}
        r={4}
      >
        <SelectWorkspaceAvatar />

        <Styled.SelectWorkspaceBoxText
          userSelect="none"
          overflow="hidden"
          maxWidth="24ch"
          textOverflow="ellipsis"
          variant="bodyMd"
          whiteSpace="nowrap"
          text={label}
        />

        <Styled.SelectWorkspaceBoxIcon
          color="iconButtonIcon"
          name={anchoredMenu.isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </Styled.SelectWorkspaceBox>

      <ResponsiveBox xs={<SelectWorkspacesMenuContent />}>
        <SelectWorkspacesSheet />
      </ResponsiveBox>
    </Box>
  );
}

export function SelectWorkspaceMenu(props: SelectWorkspaceMenuProps) {
  const filter = useFilter(props.workspaces, ['label']);

  const workspacesIsEmpty = props.workspaces.length === 0;
  const showSearchInput = props.workspaces.length > 8;

  const anchoredMenu = useAnchoredMenu({
    placement: 'bottom-start',
  });

  return (
    <SelectWorkspaceContext.Provider
      value={{
        ...props,
        anchoredMenu,
        filter,
        showSearchInput,
        workspacesIsEmpty,
      }}
    >
      <SelectWorkspaceMenuContent />
    </SelectWorkspaceContext.Provider>
  );
}
