import { Box, FormSection, IconButton, Text } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const FormSectionWithActionsIconButton = styled(IconButton)`
  ${(props) => {
    const { theme } = props;

    return css`
      opacity: 0;
      pointer-events: none;

      transition: opacity ${theme.transitions.fast};
    `;
  }}
`;

const FormSectionWithActions = styled(FormSection)`
  &:hover {
    ${FormSectionWithActionsIconButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const TextAreaContainer = styled(Box)`
  textarea {
    overflow: hidden;

    &:focus {
      overflow: unset;
    }
  }
`;

const SelectedPerk = styled(Text)`
  opacity: 0.5;
`;

export const Styled = {
  FormSectionWithActions,
  FormSectionWithActionsIconButton,
  TextAreaContainer,
  SelectedPerk,
};
