import { joiResolver } from '@hookform/resolvers/joi';
import {
  BreadcrumbsToolbar,
  ContentContainer,
  InnerContentContainerAlt,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  getFileFromUrl,
  useFeatureFlag,
  useFetch,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import {
  JOB_MAX_TOTAL_BUDGET,
  UpdateJobForm,
  UpdateJobFormValidation,
} from '../../../../models';
import {
  CompanySelector,
  JobSelector,
  OfficeLocationsSelector,
  SubjectAreaCategoriesSelector,
  getJobThunk,
  getOfficeLocationsThunk,
  getSubjectAreaCategoriesThunk,
  jobsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { JobFormHeader } from './job-form-header';

function ViewJobToolbar() {
  const jobTitle = useSelector(JobSelector.selectTitle);
  const jobKey = useSelector(JobSelector.selectJobKey);

  if (!jobTitle) {
    return null;
  }

  return (
    <BreadcrumbsToolbar
      flexGrow={0.7}
      breadcrumbListItems={[
        {
          to: '/jobs',
          tx: 'label.breadcrumbs.jobs.jobs',
        },
        {
          to: `/jobs/${jobKey}`,
          text: jobTitle,
          truncate: true,
        },
      ]}
      tabButtonListItems={[
        {
          to: `/jobs/${jobKey}`,
          tx: 'label.jobs.tab-buttons.job',
        },
        {
          to: `/jobs/${jobKey}/stats`,
          tx: 'label.jobs.tab-buttons.stats',
        },
      ]}
    />
  );
}

function ViewJobContent(props: UpdateJobForm) {
  const officeLocationKeys = useSelector(
    OfficeLocationsSelector.selectOfficeLocationKeys,
  );

  const formMethods = useForm<UpdateJobForm>({
    defaultValues: props,
    resolver: joiResolver(
      UpdateJobFormValidation({
        dailyBudget: props.budgetData.dailyBudget ?? 0,
        officeLocationKeys: officeLocationKeys,
        totalBudget: props.budgetData.totalBudget ?? 0,
      }),
    ),
  });

  return (
    <FormProvider {...formMethods}>
      <JobFormHeader />

      <Outlet />
    </FormProvider>
  );
}

function useDefaultValues() {
  const jobData = useSelector(JobSelector.selectData);

  const enableJobBudget = useFeatureFlag('company_dashboard_enable_job_budget');

  const getDefaultValues =
    React.useCallback(async (): Promise<UpdateJobForm | null> => {
      if (!jobData) {
        return null;
      }

      const coverImage = await getFileFromUrl(jobData.coverImage.original.url);

      const updateJobForm: UpdateJobForm = {
        coverImage,
        budgetData: enableJobBudget
          ? jobData.budgetData
          : {
              budgetType: 'total',
              dailyBudget: null,
              totalBudget: JOB_MAX_TOTAL_BUDGET,
            },
        contactEmail: jobData.contactEmail,
        contactName: jobData.contactName,
        contactPhone: jobData.contactPhone,
        description: jobData.description,
        employmentType: jobData.employmentType,
        endDate: jobData.endDate,
        locationData: {
          workModel: jobData.locationData.workModel,
          officeLocations:
            jobData.locationData.officeLocations?.map((location) => ({
              officeLocationKey: location.officeLocationKey,
              index: location.index,
            })) ?? null,
        },
        originalJobUrl: jobData.originalJobUrl,
        qualifications: jobData.qualifications ?? [],
        startDate: jobData.startDate,
        studyLevels: jobData.studyLevels,
        subjectAreaKeys: jobData.subjectAreas.map(
          (subjectArea) => subjectArea.subjectAreaKey,
        ),
        title: jobData.title,
      };

      return updateJobForm;
    }, [jobData, enableJobBudget]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

export function ViewJob() {
  const jobStatus = useSelector(JobSelector.selectStatus);
  const jobError = useSelector(JobSelector.selectError);
  const companyStatus = useSelector(CompanySelector.selectStatus);
  const subjectAreaCategoriesStatus = useSelector(
    SubjectAreaCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(OfficeLocationsSelector.selectStatus);

  const dispatch = useDispatch();

  const params = useParams<{ jobKey: string }>();

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    dispatch(getOfficeLocationsThunk());
    dispatch(getSubjectAreaCategoriesThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (!params.jobKey) return;

    dispatch(getJobThunk(params.jobKey));

    return () => {
      dispatch(jobsActions.resetJob());
    };
  }, [dispatch, params.jobKey]);

  if (jobError || !params.jobKey) {
    return <Navigate to="/jobs" />;
  }

  if (
    !defaultValues ||
    isAnyPending(
      jobStatus,
      companyStatus,
      locationsStatus,
      subjectAreaCategoriesStatus,
    )
  ) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <ViewJobToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainerAlt>
            <ViewJobContent {...defaultValues} />
          </InnerContentContainerAlt>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
