import {
  Box,
  BoxProps,
  Checkbox,
  FormSection,
  FormSectionHeader,
  InputChip,
  InputChips,
  InputRefContext,
  InputRefProvider,
  Menu,
  MenuDivider,
  MenuItem,
  Text,
  createFilterContext,
  getListHeight,
  isTxString,
  useMenu,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import { PickProfileModal } from '../../../../../components';
import {
  StudyLevel,
  SubjectArea,
  UpdateJobForm,
  studyLevels,
} from '../../../../../models';
import {
  JobSelector,
  SubjectAreaCategoriesSelector,
  SubjectAreasSelector,
  setAlert,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { getStudyLevelTx } from '../../../../../utils';
import { Styled } from './target-group-styled';

const SubjectAreaSearchContext = createFilterContext<SubjectArea>();
const StudyLevelSearchContext = createFilterContext<{
  studyLevel: StudyLevel;
}>();

const SUBJECT_AREAS_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = SUBJECT_AREAS_ITEM_HEIGHT * 9;

function SubjectAreaCategoryMenuItem(
  props: { subjectAreaCategoryKey: string } & BoxProps,
) {
  const { subjectAreaCategoryKey, ...rest } = props;

  const { setValue, watch } = useFormContext<UpdateJobForm>();

  const subjectAreaCategory = useSelector((state) =>
    SubjectAreaCategoriesSelector.selectById(state, subjectAreaCategoryKey),
  );

  const subjectAreaKeys = watch('subjectAreaKeys');

  const isChecked = React.useMemo(() => {
    return !!subjectAreaCategory?.subjectAreas.every((subjectArea) =>
      subjectAreaKeys.includes(subjectArea.subjectAreaKey),
    );
  }, [subjectAreaKeys, subjectAreaCategory]);

  const inputRef = React.useContext(InputRefContext);

  const toggleSubjectAreaKeys = () => {
    if (isChecked) {
      const newValue = subjectAreaKeys.filter(
        (subjectAreaKey) =>
          !subjectAreaCategory?.subjectAreas.some(
            (subjectArea) => subjectArea.subjectAreaKey === subjectAreaKey,
          ),
      );

      setValue('subjectAreaKeys', newValue, {
        shouldDirty: true,
      });

      inputRef.current?.focus();

      return;
    }

    const newValue: string[] = [];

    subjectAreaCategory?.subjectAreas.forEach((subjectArea) => {
      if (!subjectAreaKeys.includes(subjectArea.subjectAreaKey)) {
        newValue.push(subjectArea.subjectAreaKey);
      }
    });

    setValue('subjectAreaKeys', [...subjectAreaKeys, ...newValue], {
      shouldDirty: true,
    });

    inputRef.current?.focus();
  };

  return (
    <MenuDivider
      flex
      flexAlign="center"
      gap={8}
      text={subjectAreaCategory?.name}
      pl={8}
      onClick={toggleSubjectAreaKeys}
      cursor="pointer"
      {...rest}
    >
      <Checkbox checked={isChecked} onChange={() => {}} />
    </MenuDivider>
  );
}

function SubjectAreaMenuItem(
  props: SubjectArea & { style: React.CSSProperties },
) {
  const { subjectAreaKey, name, ...rest } = props;

  const formContext = useFormContext<UpdateJobForm>();

  const inputRef = React.useContext(InputRefContext);

  const subjectAreaKeys = formContext.watch('subjectAreaKeys') ?? [];

  const isChecked = subjectAreaKeys?.includes(subjectAreaKey);

  const toggleSubjectAreaKey = () => {
    formContext.setValue(
      'subjectAreaKeys',
      isChecked
        ? subjectAreaKeys.filter(
            (_subjectAreaKey) => _subjectAreaKey !== subjectAreaKey,
          )
        : subjectAreaKeys.concat(subjectAreaKey),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    inputRef.current?.focus();
  };

  return (
    <MenuItem
      onClick={toggleSubjectAreaKey}
      gap={8}
      flex
      flexAlign="center"
      pl={32}
      isSelected={isChecked}
      checkbox
      text={name}
      {...rest}
    />
  );
}

function SubjectAreas(props: {
  setSubjectAreasFromProfile: React.MutableRefObject<
    (subjectAreaKeys: string[]) => void
  >;
}) {
  const { setSubjectAreasFromProfile } = props;

  const canEditJob = useSelector(JobSelector.selectCanEditJob);
  const jobSubjectAreaKeys = useSelector(JobSelector.selectSubjectAreaKeys);
  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);
  const subjectAreaCategories = useSelector(
    SubjectAreaCategoriesSelector.selectSubjectAreaCategoryWithNameAndKey,
  );

  const dispatch = useDispatch();

  const searchContext = React.useContext(
    SubjectAreaSearchContext.FilterContext,
  );

  const inputRef = React.useContext(InputRefContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const formContext = useFormContext<UpdateJobForm>();

  setSubjectAreasFromProfile.current = (subjectAreaKeys: string[]) => {
    dispatch(setAlert('copy-subject-areas:success'));

    formContext.setValue('subjectAreaKeys', subjectAreaKeys, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const menuState = useMenu();

  const subjectAreaKeys = formContext.watch('subjectAreaKeys') ?? [];

  React.useEffect(() => {
    formContext.resetField('subjectAreaKeys', {
      defaultValue: jobSubjectAreaKeys ?? [],
    });
  }, [jobSubjectAreaKeys, formContext]);

  const subjectAreasMap = new Map(
    subjectAreas.map((subjectArea) => [
      subjectArea.subjectAreaKey,
      subjectArea.name,
    ]),
  );

  const items = React.useMemo(() => {
    return subjectAreaCategories.flatMap((subjectAreaCategory) => {
      const items = searchContext.items.filter(
        (value) =>
          value.subjectAreaCategoryKey ===
          subjectAreaCategory.subjectAreaCategoryKey,
      );

      if (items.length === 0) {
        return [];
      }

      return [
        {
          key: subjectAreaCategory.subjectAreaCategoryKey,
          name: subjectAreaCategory.name,
          isCategory: true,
        },
        ...items.map((item) => item),
      ];
    });
  }, [searchContext.items, subjectAreaCategories]);

  const menuIsOpen = menuState.isOpen && !!searchContext.items.length;

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.handleSearchStringChange(e);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<number>[] = subjectAreaKeys.map(
    (subjectAreaKey, index) => ({
      id: index,
      text: subjectAreasMap.get(subjectAreaKey)!,
      maxWidth: 'unset',
    }),
  );

  const removeChip = (id: number) => {
    formContext.setValue(
      'subjectAreaKeys',
      subjectAreaKeys.filter((_, index) => index !== id),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
  };

  const rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const item = items[index];

    if ('isCategory' in item) {
      return (
        <SubjectAreaCategoryMenuItem
          key={key}
          style={style}
          subjectAreaCategoryKey={item.key}
        />
      );
    }

    return <SubjectAreaMenuItem key={key} style={style} {...item} />;
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const listHeight = getListHeight(
    items.length,
    SUBJECT_AREAS_ITEM_HEIGHT,
    MENU_MAX_HEIGHT,
  );

  return (
    <Box ref={menuState.clickOutsideRef}>
      <InputChips
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={1000}
        disabled={!canEditJob}
        value={searchContext.searchString}
        ref={inputRef}
        chips={chips}
        showInput={menuState.isOpen || !!searchContext.searchString}
        errorTx={
          isTxString(formContext.formState.errors.subjectAreaKeys?.message)
            ? formContext.formState.errors.subjectAreaKeys?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElements={[
          {
            type: 'icon',
            name: 'magnifying-glass',
          },
        ]}
        trailingElements={[
          {
            type: 'button',
            icon: menuIsOpen ? 'chevron-up' : 'chevron-down',
            onClick: handleToggleMenu,
            disabled: !canEditJob,
            tooltipTx: menuIsOpen
              ? 'label.tooltip.collapse'
              : 'label.tooltip.expand',
            tooltipPlacement: 'top',
          },
        ]}
        labelTx="label.job-form.select-subject-areas"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
            left={0}
          >
            <List
              containerStyle={{ width: '100%', maxWidth: '100%' }}
              style={{ width: '100%' }}
              width={1}
              height={listHeight}
              rowCount={items.length}
              rowHeight={SUBJECT_AREAS_ITEM_HEIGHT}
              rowRenderer={rowRenderer}
            />
          </Menu>
        }
      />
    </Box>
  );
}

function StudyLevelMenuItem(props: { studyLevel: StudyLevel }) {
  const { studyLevel } = props;

  const formContext = useFormContext<UpdateJobForm>();

  const inputRef = React.useContext(InputRefContext);

  const selectedStudyLevels = formContext.watch('studyLevels') ?? [];

  const isChecked = selectedStudyLevels.includes(studyLevel);

  const toggleStudyLevel = () => {
    formContext.setValue(
      'studyLevels',
      isChecked
        ? selectedStudyLevels.filter(
            (_studyLevel) => _studyLevel !== studyLevel,
          )
        : selectedStudyLevels.concat(studyLevel),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    inputRef.current?.focus();
  };

  return (
    <MenuItem
      tx={getStudyLevelTx(studyLevel)}
      onClick={toggleStudyLevel}
      gap={8}
      flex
      flexAlign="center"
      isSelected={isChecked}
      checkbox
    />
  );
}

function renderStudyLevel(item: { studyLevel: StudyLevel }) {
  return (
    <StudyLevelMenuItem key={item.studyLevel} studyLevel={item.studyLevel} />
  );
}

function StudyLevels() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  const formContext = useFormContext<UpdateJobForm>();

  const searchContext = React.useContext(StudyLevelSearchContext.FilterContext);

  const inputRef = React.useContext(InputRefContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu();

  const selectedStudyLevels = formContext.watch('studyLevels') ?? [];

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.handleSearchStringChange(e);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<StudyLevel>[] = selectedStudyLevels.map(
    (studyLevel) => ({
      id: studyLevel,
      tx: getStudyLevelTx(studyLevel),
      maxWidth: 'unset',
    }),
  );

  const removeChip = (id: StudyLevel) => {
    const newState = selectedStudyLevels.filter(
      (studyLevel) => studyLevel !== id,
    );

    formContext.setValue('studyLevels', newState, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.items.length;

  return (
    <Box ref={menuState.clickOutsideRef}>
      <InputChips
        disabled={!canEditJob}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={1000}
        showInput={menuState.isOpen || !!searchContext.searchString}
        value={searchContext.searchString}
        chips={chips}
        ref={inputRef}
        errorTx={
          isTxString(formContext.formState.errors.studyLevels?.message)
            ? formContext.formState.errors.studyLevels?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElements={[
          {
            type: 'icon',
            name: 'magnifying-glass',
          },
        ]}
        trailingElements={[
          {
            type: 'button',
            icon: menuIsOpen ? 'chevron-up' : 'chevron-down',
            onClick: handleToggleMenu,
            disabled: !canEditJob,
            tooltipTx: menuIsOpen
              ? 'label.tooltip.collapse'
              : 'label.tooltip.expand',
            tooltipPlacement: 'top',
          },
        ]}
        labelTx="label.job-form.select-study-levels"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
            left={0}
          >
            {searchContext.items.map(renderStudyLevel)}
          </Menu>
        }
      />
    </Box>
  );
}

export function TargetGroup() {
  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);

  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  const modalState = useModalState();

  const setSubjectAreasFromProfile = React.useRef<
    (subjectAreaKeys: string[]) => void
  >(() => {});

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.3.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />

        <Box>
          <Styled.DescriptionText
            tx="label.job-form.steps.3.subtitle"
            color="formSectionDescription"
            variant="bodyMd"
            pr={4}
          />

          <Styled.DescriptionLink
            disabled={!canEditJob}
            onClick={modalState.openModal}
            tx="label.job-form.steps.3.link"
            variant="secondary"
          />

          <PickProfileModal
            isOpen={modalState.isOpen}
            closeModal={modalState.closeModal}
            onSave={setSubjectAreasFromProfile.current}
          />
        </Box>
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <SubjectAreaSearchContext.Provider keys={['name']} items={subjectAreas}>
          <InputRefProvider>
            <SubjectAreas
              setSubjectAreasFromProfile={setSubjectAreasFromProfile}
            />
          </InputRefProvider>
        </SubjectAreaSearchContext.Provider>

        <StudyLevelSearchContext.Provider
          keys={['studyLevel']}
          items={studyLevels.map((studyLevel) => ({ studyLevel }))}
        >
          <InputRefProvider>
            <StudyLevels />
          </InputRefProvider>
        </StudyLevelSearchContext.Provider>
      </Box>
    </FormSection>
  );
}
