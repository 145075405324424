import {
  AlertProvider,
  OrbiLoader,
  SidebarProvider,
  shouldForwardProp,
  useSignInWithCustomToken,
} from '@orbiapp/components';
import * as Firebase from 'firebase/auth';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { GlobalSnackbar, QuickActionsProvider } from '../components';
import { LanguageConfig } from '../i18n';
import { ErrorBoundary } from '../pages/shared';
import { routes } from '../routes';
import { firebase, onAuthStateChanged } from '../services/firebase/firebase';
import { signInWithCustomTokenThunk, store, useDispatch } from '../store';
import { theme } from '../theme';

Firebase.getAuth(firebase).onAuthStateChanged(onAuthStateChanged);

function AppContent() {
  const dispatch = useDispatch();

  const canContinue = useSignInWithCustomToken(async (customToken: string) => {
    await dispatch(signInWithCustomTokenThunk(customToken));
  });

  if (!canContinue) {
    return <OrbiLoader />;
  }

  return <RouterProvider router={createBrowserRouter(routes)} />;
}

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <I18nextProvider i18n={LanguageConfig.i18n}>
              <AlertProvider>
                <SidebarProvider>
                  <QuickActionsProvider>
                    <GlobalSnackbar />

                    <AppContent />
                  </QuickActionsProvider>
                </SidebarProvider>
              </AlertProvider>
            </I18nextProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
