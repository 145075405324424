import styled, { css } from 'styled-components';

import { Box } from '../../components/box';

const WysiwygActionsContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      opacity: 0;
      pointer-events: none;
      transition: opacity ${theme.transitions.fast};
    `;
  }}
`;

const WysiwygPreviewContainer = styled(Box)`
  cursor: default;

  &:hover {
    ${WysiwygActionsContainer} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Styled = { WysiwygPreviewContainer, WysiwygActionsContainer };
