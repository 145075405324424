import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Icon } from '../icon';
import { Text, getTextStyles } from '../text';

const SidebarContent = styled(Box)``;

const SidebarItemIcon = styled(Icon)`
  min-width: 24px;
`;

const SidebarItemLabel = styled(Text)``;

const SidebarItemNotificationIndicator = styled(Box)<{ collapsed: boolean }>`
  ${(props) => {
    const { collapsed, theme } = props;

    return css`
      width: 8px;
      height: 8px;
      background-color: ${theme.colors
        .sidebarItemNotificationIndicatorBackground};
      border-radius: 50%;
      position: absolute;
      right: 8px;

      ${collapsed &&
      css`
        top: 2px;
        right: 4px;
      `}
    `;
  }}
`;

const ToggleSidebarCollapsedButton = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      display: none;
      align-items: center;
      justify-content: center;

      border: 1px solid ${theme.colors.toggleSidebarCollapsedButtonBorder};
      background-color: ${theme.colors.toggleSidebarCollapsedButtonBackground};
      border-radius: 50%;

      opacity: 0;
      pointer-events: none;

      @media (min-width: ${theme.breakpoints.xs}px) {
        display: flex;
      }

      &:active {
        border: 1px solid
          ${theme.colors.toggleSidebarCollapsedButtonBorderActive};
        background-color: ${theme.colors
          .toggleSidebarCollapsedButtonBackgroundActive};
      }
    `;
  }}
`;

const Sidebar = styled(Box)<{ collapsed: boolean }>`
  ${(props) => {
    const { collapsed, theme } = props;

    return css`
      width: 176px;
      min-width: 176px;
      background-color: ${theme.colors.sidebarBackground};
      border-right: 1px solid ${theme.colors.sidebarBorder};
      transition: ${theme.transitions.fast};
      padding: 48px 8px 24px;

      position: fixed;
      height: 100%;
      z-index: 50;

      ${collapsed &&
      css`
        width: 0;
        min-width: 0;
        overflow-x: hidden;
        padding: 48px 0px 24px;
      `};

      @media (min-width: ${theme.breakpoints.xs}px) {
        position: relative;
        height: unset;

        ${SidebarContent} {
          height: 100%;
        }

        ${collapsed &&
        css`
          width: 56px;
          min-width: 56px;
          overflow-x: unset;
          padding: 48px 8px 24px;
        `};
      }

      &:hover {
        ${ToggleSidebarCollapsedButton} {
          opacity: 1;
          pointer-events: all;
        }
      }
    `;
  }}
`;

const SidebarItem = styled(Box)<{ collapsed: boolean; isActive: boolean }>`
  ${(props) => {
    const { collapsed, isActive, theme } = props;

    return css`
      padding: 8px;
      background-color: ${theme.colors.sidebarItemBackground};
      transition: ${theme.transitions.fast};

      ${collapsed &&
      css`
        border-radius: 50%;
      `}

      ${SidebarItemIcon} {
        color: ${theme.colors.sidebarItemIcon};
      }

      ${SidebarItemLabel} {
        ${getTextStyles('bodySm', theme)};
        color: ${theme.colors.sidebarItemLabel};
      }

      ${!isActive &&
      css`
        &:hover {
          background-color: ${theme.colors.sidebarItemBackgroundHover};

          ${SidebarItemIcon} {
            color: ${theme.colors.sidebarItemIconHover};
          }

          ${SidebarItemLabel} {
            color: ${theme.colors.sidebarItemLabelHover};
          }
        }

        &:active {
          background-color: ${theme.colors.sidebarItemBackgroundActive};

          ${SidebarItemIcon} {
            color: ${theme.colors.sidebarItemIconActive};
          }

          ${SidebarItemLabel} {
            color: ${theme.colors.sidebarItemLabelActive};
          }
        }
      `}

      ${isActive &&
      css`
        background-color: ${theme.colors.sidebarItemBackgroundToggled};
        pointer-events: none;

        ${SidebarItemIcon} {
          color: ${theme.colors.sidebarItemIconToggled};
        }

        ${SidebarItemLabel} {
          ${getTextStyles('bodySmBold', theme)};
          color: ${theme.colors.sidebarItemLabelToggled};
        }
      `};
    `;
  }}
`;

const SidebarMessage = styled(Box)<{ collapsed: boolean; isActive: boolean }>`
  ${(props) => {
    const { collapsed, isActive, theme } = props;

    return css`
      padding: 8px;

      transition: ${theme.transitions.fast};

      ${collapsed &&
      css`
        border-radius: 50%;
      `}

      ${collapsed
        ? css`
            background-color: ${theme.colors.sidebarItemBackground};

            ${SidebarItemIcon} {
              color: ${theme.colors.sidebarItemIcon};
            }

            ${!isActive &&
            css`
              &:hover {
                background-color: ${theme.colors.sidebarItemBackgroundHover};

                ${SidebarItemIcon} {
                  color: ${theme.colors.sidebarItemIconHover};
                }
              }

              &:active {
                background-color: ${theme.colors.sidebarItemBackgroundActive};

                ${SidebarItemIcon} {
                  color: ${theme.colors.sidebarItemIconActive};
                }

                ${SidebarItemLabel} {
                  color: ${theme.colors.sidebarItemLabelActive};
                }
              }
            `}

            ${isActive &&
            css`
              background-color: ${theme.colors.sidebarItemBackgroundToggled};

              ${SidebarItemIcon} {
                color: ${theme.colors.sidebarItemIconToggled};
              }
            `};
          `
        : css`
            background-color: ${theme.colors.sidebarMessageBackground};
            border: 1px solid ${theme.colors.sidebarMessageBorder};

            ${SidebarItemIcon} {
              color: ${theme.colors.sidebarMessageIcon};
            }

            ${!isActive &&
            css`
              &:hover {
                border: 1px solid ${theme.colors.sidebarMessageBorderHover};
              }

              &:active {
                border: 1px solid ${theme.colors.sidebarMessageBorderActive};
              }
            `}

            ${isActive &&
            css`
              border: 1px solid ${theme.colors.sidebarMessageBorderToggled};

              ${SidebarItemIcon} {
                color: ${theme.colors.sidebarMessageIconToggled};
              }
            `};
          `}
    `;
  }}
`;

const SidebarMessageLabel = styled(Text)`
  ${(props) => {
    const { theme } = props;
    return css`
      background-color: ${theme.colors.sidebarMessageLabelBackground};
      color: ${theme.colors.sidebarMessageLabelColor};

      letter-spacing: 0.75px;
    `;
  }}
`;

const SidebarBackdrop = styled(Box)<{ collapsed: boolean }>`
  ${(props) => {
    const { collapsed, theme } = props;

    return css`
      display: block;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      inset: 0;
      z-index: 40;
      transition: ${theme.transitions.fast};

      ${collapsed &&
      css`
        background-color: transparent;
        pointer-events: none;
      `}

      @media (min-width: ${theme.breakpoints.xs}px) {
        display: none;
      }
    `;
  }}
`;

export const Styled = {
  Sidebar,
  SidebarBackdrop,
  SidebarContent,
  SidebarItem,
  SidebarItemIcon,
  SidebarItemLabel,
  SidebarItemNotificationIndicator,
  SidebarMessage,
  SidebarMessageLabel,
  ToggleSidebarCollapsedButton,
};
