import styled, { css } from 'styled-components';

import { Box } from '../box';
import { getTextStyles } from '../text';

interface ChipsInputBoxProps {
  showInput?: boolean;
}

const OuterChipsInputBox = styled(Box)`
  &:focus-within {
    p {
      transform: scaleY(100%);
      opacity: 1;
    }
  }
`;

const ChipsInputBox = styled(Box)<ChipsInputBoxProps>`
  ${(props) => {
    const { showInput, theme } = props;

    return css`
      &:focus-within {
        label {
          ${getTextStyles('bodySm', theme)};
          top: 14px;
        }
      }

      input {
        ${getTextStyles('bodyMd', theme)}

        border: none;
        outline: none;
        height: 0;
        flex-grow: 1;
        line-height: 0;
        position: absolute;
        background-color: transparent;

        &:focus {
          height: 30px;
          position: relative;
        }

        ${showInput &&
        css`
          height: 30px;
          position: relative;
        `}
      }
    `;
  }}
`;

export const Styled = { ChipsInputBox, OuterChipsInputBox };
