import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { getOptionalLabelText } from '../../../../../utils';
import { ProfileContext } from './profile-form.context';

export function ContactDetails() {
  const { saveField, formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const saveContactEmail = () => {
    saveField('contactEmail');
  };

  const saveContactPhone = () => {
    saveField('contactPhone');
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.profile.contact-details.header"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          tx="label.profile.contact-details.description"
          variant="bodyMd"
          color="formSectionDescription"
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <ControlledInput
          labelTx="label.profile.contact-details.inputs.email"
          leadingElements={[
            {
              type: 'icon',
              name: 'envelope-outline',
            },
          ]}
          name="contactEmail"
          onBlur={isDraft ? saveContactEmail : undefined}
        />

        <ControlledInput
          label={getOptionalLabelText(
            'label.profile.contact-details.inputs.phone',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'phone-outline',
            },
          ]}
          name="contactPhone"
          onBlur={isDraft ? saveContactPhone : undefined}
        />
      </Box>
    </FormSection>
  );
}
