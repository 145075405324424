import React from 'react';
import { useController } from 'react-hook-form';

import { INPUT_X_PADDING } from '../../constants';
import { useCombinedRefs } from '../../helpers';
import { translate } from '../../i18n';
import { formatDate, isTxString } from '../../utils';
import { Box } from '../box';
import { InputBox } from '../input-box';
import { InputElementBox } from '../input-element-box';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import { InputStrength } from '../password-strength';
import { Styled } from './input.styled';
import {
  ControlledDatePickerProps,
  ControlledInputProps,
  InputProps,
  datePickerTypes,
} from './input.types';

function _ControlledDatePicker(
  props: ControlledDatePickerProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    defaultValue,
    deps,
    disabled,
    maxLength,
    name,
    onBlur,
    onChange,
    type,
    ...rest
  } = props;

  const controller = useController({
    name,
    rules: { deps },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const date = new Date(e.target.value).getTime();

    if (isNaN(date)) {
      e.preventDefault();
      return;
    }

    controller.field.onChange({
      ...e,
      target: { ...e.target, value: date },
    });
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const errorTx = isTxString(controller.fieldState.error?.message)
    ? controller.fieldState.error?.message
    : undefined;

  const value =
    type === 'date'
      ? formatDate(controller.field.value, 'YYYY-MM-DD')
      : formatDate(controller.field.value, 'YYYY-MM-DDTHH:mm');

  return (
    <Input
      disabled={disabled}
      errorTx={errorTx}
      name={name}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      ref={combinedRefs}
      type={type}
      value={value}
      {...rest}
    />
  );
}

function _ControlledInput(
  props: ControlledInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    defaultValue,
    deps,
    disabled,
    maxLength,
    name,
    onBlur,
    onChange,
    ...rest
  } = props;

  const controller = useController({
    defaultValue,
    name,
    rules: { deps, maxLength },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    controller.field.onChange(e);
    onChange?.(e);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const errorTx = isTxString(controller.fieldState.error?.message)
    ? controller.fieldState.error?.message
    : undefined;

  return (
    <Input
      disabled={disabled}
      maxLength={maxLength}
      name={controller.field.name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      ref={combinedRefs}
      value={controller.field.value ?? ''}
      errorTx={errorTx}
      {...rest}
    />
  );
}

function useInput(props: {
  defaultValue?: InputProps['defaultValue'];
  maxLength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  type?: InputProps['type'];
  value?: InputProps['value'];
  disabled?: InputProps['disabled'];
}) {
  const { defaultValue, value, onFocus, onBlur, onChange, maxLength, type } =
    props;

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus?.(e);
    setIsFocused(true);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur?.(e);
    setIsFocused(false);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const length = e.target.value.length;

    if (maxLength === undefined) {
      onChange?.(e);
      return;
    }

    if (length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
      onChange?.(e);
      return;
    }

    onChange?.(e);
  };

  const hasValue = typeof value === 'string' ? !!value : value !== undefined;

  const hasDefaultValue =
    typeof defaultValue === 'string'
      ? !!defaultValue
      : defaultValue !== undefined;

  const floatLabel =
    hasValue ||
    hasDefaultValue ||
    datePickerTypes.includes(type as any) ||
    isFocused;

  React.useEffect(() => {
    if (props.disabled) {
      setIsFocused(false);
    }
  }, [props.disabled]);

  return { handleFocus, handleBlur, handleChange, floatLabel, isFocused };
}

function _Input(props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    defaultValue,
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    label,
    labelTx,
    labelTxArgs,
    leadingElements,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    trailingElements,
    type,
    value,
    width = '100%',
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);

  const combinedRefs = useCombinedRefs(ref, inputRef);

  const { handleFocus, handleBlur, handleChange, floatLabel, isFocused } =
    useInput({
      defaultValue,
      value,
      onFocus,
      onBlur,
      onChange,
      maxLength,
      type,
      disabled,
    });

  const hasError = !!error || !!errorTx;

  const openPicker = () => {
    combinedRefs.current?.showPicker();
    combinedRefs.current?.focus();
    combinedRefs.current?.click();
  };

  const focusInput = () => {
    combinedRefs.current?.focus();
  };

  const isDatePicker = datePickerTypes.includes(type as any);

  const handleWheel: React.WheelEventHandler<HTMLInputElement> = (e) => {
    if (type !== 'number') return;
    e.currentTarget.blur();
  };

  return (
    <Styled.OuterInputBox isFocused={isFocused} relative width={width}>
      <InputBox
        disabled={disabled}
        hasError={hasError}
        floatLabel={floatLabel}
        onClick={focusInput}
        isFocused={isFocused}
      >
        <InputElementBox items={leadingElements} />

        <Box flexGrow={1} flex flexAlign="center" relative>
          <Styled.Input
            defaultValue={defaultValue}
            disabled={disabled}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={combinedRefs}
            type={type}
            value={value}
            onWheel={handleWheel}
            placeholder={
              placeholderTx
                ? translate(placeholderTx, placeholderTxArgs ?? {}).toString()
                : placeholder
            }
            {...rest}
          />

          <InputLabel
            disabled={disabled}
            hasError={hasError}
            ref={labelRef}
            text={label}
            tx={labelTx}
            txArgs={labelTxArgs}
          />
        </Box>

        <InputElementBox
          items={
            isDatePicker
              ? [
                  ...(trailingElements ?? []),
                  {
                    type: 'button',
                    icon: 'calendar-days-outline',
                    disabled,
                    onClick: openPicker,
                  },
                ]
              : trailingElements
          }
        />
      </InputBox>

      {props.passwordStrengthOptions && (
        <InputStrength
          px={INPUT_X_PADDING}
          value={value?.toString() ?? ''}
          hasError={hasError}
          {...props.passwordStrengthOptions}
        />
      )}

      <InputHelper
        currentLength={value?.toString().length}
        disabled={disabled}
        hasError={hasError}
        helperText={error || helperText}
        helperTx={errorTx || helperTx}
        helperTxArgs={errorTxArgs || helperTxArgs}
        maxLength={maxLength}
      />
    </Styled.OuterInputBox>
  );
}

export const ControlledInput = React.forwardRef(_ControlledInput);
export const ControlledDatePicker = React.forwardRef(_ControlledDatePicker);
export const Input = React.forwardRef(_Input);
