import styled, { css } from 'styled-components';

import { Box } from '../box';
import { StyledEmptyStateProps } from './empty-state.types';

const EmptyState = styled(Box)<StyledEmptyStateProps>`
  ${(props) => {
    const { disabled, theme } = props;

    return css`
      border-radius: 8px;
      padding: 24px 8px;
      min-height: 300px;
      color: ${theme.colors.emptyStateLabel};
      background-color: ${theme.colors.emptyStateBackground};

      ${disabled &&
      css`
        position: relative;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: inherit;
          z-index: 1;
        }
      `}
    `;
  }}
`;

export const Styled = { EmptyState };
