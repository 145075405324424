import dayjs from 'dayjs';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { formatPublishDate, newDayjs } from '../../utils';
import { Box } from '../box';
import { Button } from '../button';
import { ControlledCalendar } from '../calendar';
import { Divider } from '../divider';
import { Menu, MenuFooter, MenuItem, useMenu } from '../menu';
import { ResponsiveBox } from '../responsive-box';
import { Sheet } from '../sheet';
import { TimePicker } from '../time-picker';
import { Styled } from './publish-button-menu.styled';

export function getPublishMenuItems(now: dayjs.Dayjs) {
  const values: {
    type: 'tomorrow_at_7' | 'today_at_18';
    value: dayjs.Dayjs;
  }[] = [
    {
      type: 'tomorrow_at_7',
      value: now.startOf('hour').add(1, 'day').set('hour', 7),
    },
  ];

  const currentHours = now.get('hour');

  if (currentHours < 18) {
    values.unshift({
      type: 'today_at_18',
      value: now.startOf('hour').set('hour', 18),
    });
  }

  return values;
}

export type PublishEvent =
  | ReturnType<typeof getPublishMenuItems>[number]['type']
  | 'publish_now'
  | 'custom_date';

export interface PublishButtonMenuProps {
  isLoading: boolean;

  onSubmit: (data: any) => void;
  onError?: (err: FieldErrors) => void;

  publishTx: TxString;
  cancelTx: TxString;

  publishNowTx: TxString;
  customTx: TxString;

  onEvent: (event: PublishEvent) => void;

  includeTimePicker?: boolean;
}

export function PublishButtonMenu(props: PublishButtonMenuProps) {
  const {
    isLoading,
    onSubmit,
    onError,
    publishNowTx,
    publishTx,
    cancelTx,
    customTx,
    onEvent,
    includeTimePicker,
  } = props;

  const formContext = useFormContext();

  const menuState = useMenu();

  const [pickCustomDate, setPickCustomDate] = React.useState(false);

  const startDate = formContext.watch('startDate');

  const handlePublish = formContext.handleSubmit(
    (data) => {
      menuState.closeMenu();
      onSubmit(data);
    },
    (err) => {
      menuState.closeMenu();
      onError?.(err);
    },
  );

  const publishNow = () => {
    formContext.setValue('startDate', Date.now());
    handlePublish();

    onEvent('publish_now');
  };

  const openCalendar = () => {
    setPickCustomDate(true);
  };

  const closeCalendar = () => {
    setPickCustomDate(false);
  };

  const menuItems = getPublishMenuItems(newDayjs());

  const publishCustomDate = () => {
    handlePublish();
    onEvent('custom_date');
  };

  const renderMenuItem = (item: (typeof menuItems)[number], index: number) => {
    const handleMenuItemClick = () => {
      formContext.setValue('startDate', item.value);
      handlePublish();
      onEvent(item.type);
    };

    return (
      <MenuItem
        text={formatPublishDate(item.value)}
        key={index}
        onClick={handleMenuItemClick}
      />
    );
  };

  const renderSheetMenuItem = (
    item: (typeof menuItems)[number],
    index: number,
  ) => {
    const handleMenuItemClick = () => {
      formContext.setValue('startDate', item.value);
      handlePublish();
      onEvent(item.type);
    };

    return (
      <React.Fragment key={index}>
        <Divider />

        <MenuItem
          text={formatPublishDate(item.value)}
          onClick={handleMenuItemClick}
        />
      </React.Fragment>
    );
  };

  const handleTimeChange = (date: Date) => {
    formContext.setValue('startDate', date.getTime());
  };

  return (
    <Box ref={menuState.menuRef} relative>
      <Button
        iconPlacement="right"
        icon={menuState.isOpen ? 'chevron-up' : 'chevron-down'}
        isLoading={isLoading}
        tx={publishTx}
        variant="primary"
        onClick={menuState.toggleMenu}
      />

      {!isLoading && (
        <ResponsiveBox
          xs={
            <Menu
              isOpen={menuState.isOpen}
              right={0}
              mt={8}
              absolute
              top="100%"
            >
              {pickCustomDate ? (
                <React.Fragment>
                  <Box flex>
                    <ControlledCalendar
                      minDate={new Date()}
                      name="startDate"
                      preserveTime={includeTimePicker}
                    />

                    {includeTimePicker && (
                      <TimePicker
                        height={267}
                        value={new Date(startDate)}
                        onChange={handleTimeChange}
                      />
                    )}
                  </Box>

                  <MenuFooter
                    primaryButtonOnClick={publishCustomDate}
                    primaryButtonTx={publishTx}
                    secondaryButtonOnClick={closeCalendar}
                    secondaryButtonTx={cancelTx}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MenuItem onClick={publishNow} tx={publishNowTx} />

                  {menuItems.map(renderMenuItem)}

                  <MenuItem onClick={openCalendar} tx={customTx} />
                </React.Fragment>
              )}
            </Menu>
          }
        >
          <Sheet isOpen={menuState.isOpen} onClose={menuState.closeMenu}>
            {pickCustomDate ? (
              <Box height="100%" flexGrow={1} flex flexDirection="column">
                <Styled.StyledCalendar minDate={new Date()} name="startDate" />

                <MenuFooter
                  primaryButtonOnClick={publishCustomDate}
                  primaryButtonTx={publishTx}
                  secondaryButtonOnClick={closeCalendar}
                  secondaryButtonTx={cancelTx}
                />
              </Box>
            ) : (
              <Styled.SheetMenu as="ul">
                <MenuItem onClick={publishNow} tx={publishNowTx} />

                {menuItems.map(renderSheetMenuItem)}

                <Divider />

                <MenuItem onClick={openCalendar} tx={customTx} />
              </Styled.SheetMenu>
            )}
          </Sheet>
        </ResponsiveBox>
      )}
    </Box>
  );
}
