import { joiResolver } from '@hookform/resolvers/joi';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  ContentSidebarContentContainer,
  ControlledDatePicker,
  ControlledTextarea,
  PrintQrCode,
  SolidIconButton,
  Spinner,
  Status,
  Text,
  Tooltip,
  WyiswygEditContainer,
  WysiwygPreviewContainer,
  WysiwygProvider,
  flattenFieldErrorsObject,
  formatDate,
  getAvatarVariantFromString,
  isTxString,
  numberFormatter,
  translate,
  useWysiwyg,
} from '@orbiapp/components';
import React from 'react';
import {
  FieldErrors,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH,
  COMPANY_QR_CODE_NAME_MAX_LENGTH,
  CompanyQr,
  StatusType,
  UpdateCompanyQr,
  UpdateCompanyQrValidation,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  CompanyQrSelector,
  GetCompanyQrLinkSelector,
  UpdateCompanyQrSelector,
  getCompanyQrLinkThunk,
  updateCompanyQrThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import {
  getConnectQrCodeStatus,
  getOptionalLabelText,
} from '../../../../../../utils';
import { TitleInput } from '../../title-input';

interface ViewQrCodeSidebarProviderProps {
  companyQr: CompanyQr;
  companyQrStatus: StatusType;
  updateCompanyQrStatus: StatusType;

  onSubmit: (
    onSuccess?: () => void,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
}

interface EditQrCodeFormProps {
  companyQr: CompanyQr;
  companyQrStatus: StatusType;
  updateCompanyQrStatus: StatusType;

  onSubmit: (data: UpdateCompanyQr) => Promise<boolean>;
  onError: (err: FieldErrors<UpdateCompanyQr>) => void;
}

interface ViewQrCodeSidebarContentProps {
  companyQr: CompanyQr;
}

const ViewQrCodeSidebarContext =
  React.createContext<ViewQrCodeSidebarProviderProps>({} as any);

function ViewQrCodeSidebarProvider(
  props: React.PropsWithChildren<ViewQrCodeSidebarProviderProps>,
) {
  const { children, ...rest } = props;

  return (
    <ViewQrCodeSidebarContext.Provider value={rest}>
      {children}
    </ViewQrCodeSidebarContext.Provider>
  );
}

function EditQrCodeName() {
  const formMethods = useFormContext<UpdateCompanyQr>();
  const { onSubmit } = React.useContext(ViewQrCodeSidebarContext);

  return (
    <TitleInput
      data-testid="edit-qr-code-name"
      placeholder={translate('label.connect.untitled-qr-code')}
      maxLength={COMPANY_QR_CODE_NAME_MAX_LENGTH}
      errorTx={
        isTxString(formMethods.formState.errors.name?.message)
          ? formMethods.formState.errors.name?.message
          : undefined
      }
      {...formMethods.register('name', {
        onBlur: onSubmit(),
      })}
    />
  );
}

function EditQrCodeStartDate() {
  const formMethods = useFormContext<UpdateCompanyQr>();
  const { onSubmit, companyQr, updateCompanyQrStatus } = React.useContext(
    ViewQrCodeSidebarContext,
  );

  const wysiwyg = useWysiwyg();

  if (wysiwyg.mode === 'preview') {
    return (
      <WysiwygPreviewContainer
        px={0}
        py={0}
        labelTx="label.connect.start-date"
        value={formatDate(
          companyQr.startDate ?? Date.now(),
          'DD MMM YYYY, HH:mm',
        )}
        actions={
          companyQr.startDate && companyQr.startDate > Date.now() ? (
            <Tooltip placement="left" titleTx="label.connect.edit-start-date">
              <SolidIconButton
                aria-label={translate('label.connect.edit-start-date')}
                onClick={wysiwyg.setModeToEdit}
                icon="pencil-square-outline"
              />
            </Tooltip>
          ) : undefined
        }
      />
    );
  }

  const resetQrCodeStartDate = () => {
    formMethods.resetField('startDate');
  };

  const isLoading = updateCompanyQrStatus === 'pending';

  return (
    <WyiswygEditContainer
      disableEnterPreviewMode={formMethods.formState.isDirty}
    >
      <ControlledDatePicker
        type="datetime-local"
        labelTx="label.connect.start-date"
        name="startDate"
        min={formatDate(Date.now(), 'YYYY-MM-DDTHH:mm')}
        trailingElements={[
          {
            type: 'button',
            disabled: isLoading,
            icon: 'undo',
            onClick: resetQrCodeStartDate,
            tooltipTx: 'label.connect.reset-start-date',
            tooltipPlacement: 'left',
            'aria-label': translate('label.connect.reset-start-date'),
          },
          {
            type: 'button',
            icon: 'check',
            isLoading,
            onClick: onSubmit(wysiwyg.setModeToPreview),
            tooltipTx: 'label.connect.save-start-date',
            tooltipPlacement: 'left',
            'aria-label': translate('label.connect.save-start-date'),
          },
        ]}
      />
    </WyiswygEditContainer>
  );
}

function EditQrCodeDescription() {
  const formMethods = useFormContext<UpdateCompanyQr>();
  const { companyQr, updateCompanyQrStatus, onSubmit } = React.useContext(
    ViewQrCodeSidebarContext,
  );

  const wysiwyg = useWysiwyg();

  if (wysiwyg.mode === 'preview') {
    return (
      <WysiwygPreviewContainer
        px={0}
        py={0}
        labelTx="label.connect.description"
        value={companyQr.description ?? undefined}
        emptyTextTx="label.connect.no-description-provided"
        actions={
          <Tooltip placement="left" titleTx="label.connect.edit-description">
            <SolidIconButton
              aria-label={translate('label.connect.edit-description')}
              onClick={wysiwyg.setModeToEdit}
              icon="pencil-square-outline"
            />
          </Tooltip>
        }
      />
    );
  }

  const resetDescription = () => {
    formMethods.resetField('description');
  };

  const isLoading = updateCompanyQrStatus === 'pending';

  return (
    <WyiswygEditContainer
      disableEnterPreviewMode={formMethods.formState.isDirty}
    >
      <FormProvider {...formMethods}>
        <ControlledTextarea
          label={getOptionalLabelText('label.connect.description')}
          name="description"
          maxLength={COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH}
          minHeight={85}
          trailingElements={[
            {
              type: 'button',
              icon: 'undo',
              onClick: resetDescription,
              disabled: isLoading,
              tooltipTx: 'label.connect.reset-description',
              tooltipPlacement: 'left',
              'aria-label': translate('label.connect.reset-description'),
            },
            {
              type: 'button',
              icon: 'check',
              isLoading,
              onClick: onSubmit(wysiwyg.setModeToPreview),
              disabled: isLoading,
              tooltipTx: 'label.connect.save-description',
              tooltipPlacement: 'left',
              'aria-label': translate('label.connect.save-description'),
            },
          ]}
        />
      </FormProvider>
    </WyiswygEditContainer>
  );
}

export function EditQrCodeForm(props: EditQrCodeFormProps) {
  const {
    companyQr,
    companyQrStatus,
    updateCompanyQrStatus,
    onSubmit,
    onError,
  } = props;

  const formMethods = useForm<UpdateCompanyQr>({
    resolver: joiResolver(UpdateCompanyQrValidation),
    defaultValues: {
      description: companyQr.description ?? '',
      name: companyQr.name,
      startDate: companyQr.startDate,
    },
  });

  const saveQrCode = (onSuccess?: () => void) => {
    return formMethods.handleSubmit(async (data) => {
      const res = await onSubmit(data);

      if (res) {
        formMethods.reset(data);
      }

      onSuccess?.();
    }, onError);
  };

  return (
    <FormProvider {...formMethods}>
      <ViewQrCodeSidebarProvider
        companyQr={companyQr}
        companyQrStatus={companyQrStatus}
        onSubmit={saveQrCode}
        updateCompanyQrStatus={updateCompanyQrStatus}
      >
        <EditQrCodeName />
        <Box flexWrap flexAlign="center" flex gap={16}>
          <Box flexAlign="center" flex gap={16}>
            <Avatar
              width={24}
              height={24}
              fallbackLetter={companyQr.editMeta.createdBy?.firstName[0]}
              variant={
                companyQr.editMeta.createdBy
                  ? getAvatarVariantFromString(
                      companyQr.editMeta.createdBy.userKey,
                    )
                  : undefined
              }
              src={
                companyQr.editMeta.createdBy?.profilePicture?.thumbnail64.url ??
                undefined
              }
            />

            <Box>
              <Text tx="label.connect.created-by" variant="bodySm" />
              {companyQr.editMeta.createdBy ? (
                <Text
                  text={`${companyQr.editMeta.createdBy.firstName} ${companyQr.editMeta.createdBy.lastName}`}
                  variant="bodySm"
                  color="connectQrCodeCreatedBySubtitle"
                />
              ) : (
                <Text
                  tx="label.connect.unknown-user"
                  variant="bodySm"
                  color="connectQrCodeCreatedBySubtitle"
                />
              )}
            </Box>
          </Box>

          <Status
            {...getConnectQrCodeStatus(companyQr.startDate, companyQr.endDate)}
          />
        </Box>
        <WysiwygProvider>
          <EditQrCodeStartDate />
        </WysiwygProvider>
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.connect.end-date"
            variant="bodySm"
            color="connectViewUserListItemTitle"
          />

          <Text text={formatDate(companyQr.endDate, 'DD MMM YYYY, HH:mm')} />
        </Box>
        <WysiwygProvider>
          <EditQrCodeDescription />
        </WysiwygProvider>
      </ViewQrCodeSidebarProvider>
    </FormProvider>
  );
}

export function ViewQrCodeSidebarContent(props: ViewQrCodeSidebarContentProps) {
  const { companyQr } = props;

  const startDate = useSelector(CompanyQrSelector.selectCompanyQrStartDate);
  const companyQrStatus = useSelector(CompanyQrSelector.selectStatus);
  const updateCompanyQrStatus = useSelector(
    UpdateCompanyQrSelector.selectStatus,
  );
  const getQrCodeLinkStatus = useSelector(
    GetCompanyQrLinkSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const handleUpdate = async (data: UpdateCompanyQr) => {
    if (startDate && startDate < Date.now()) {
      data.startDate = undefined;
    }

    const res = await dispatch(updateCompanyQrThunk(data));

    return res.meta.requestStatus === 'fulfilled';
  };

  const handleError = (err: FieldErrors<UpdateCompanyQr>) => {
    Logger.warning('editCompanyQrCode Validation', {
      err: flattenFieldErrorsObject(err),
    });
  };

  React.useEffect(() => {
    dispatch(getCompanyQrLinkThunk());
  }, [dispatch]);

  return (
    <ContentSidebarContentContainer>
      <EditQrCodeForm
        companyQr={companyQr}
        companyQrStatus={companyQrStatus}
        onError={handleError}
        onSubmit={handleUpdate}
        updateCompanyQrStatus={updateCompanyQrStatus}
      />

      {getQrCodeLinkStatus === 'pending' ? (
        <Box
          width={170}
          height={170}
          flex
          flexJustify="center"
          flexAlign="center"
        >
          <Spinner />
        </Box>
      ) : (
        <PrintQrCode
          expandTx="label.general.expand"
          printTx="label.general.print"
          onError={Logger.exception}
        />
      )}

      <Box gap={16} flex>
        <Card py={8} px={16} flexGrow={1} width="100%">
          <CardHeader
            titleTx="label.connect.scans"
            subtitleText={numberFormatter.format(companyQr.scanCount)}
          />
        </Card>
        <Card py={8} px={16} flexGrow={1} width="100%">
          <CardHeader
            titleTx="label.connect.talent-acquired"
            subtitleText={numberFormatter.format(companyQr.connectCount)}
          />
        </Card>
      </Box>
    </ContentSidebarContentContainer>
  );
}

export function ViewQrCodeSidebar() {
  const companyQr = useSelector(CompanyQrSelector.selectCompanyQr);
  const companyQrStatus = useSelector(CompanyQrSelector.selectStatus);

  if (companyQrStatus === 'pending') {
    return (
      <ContentSidebarContentContainer>
        <Box py={32} flex flexJustify="center">
          <Spinner />
        </Box>
      </ContentSidebarContentContainer>
    );
  }

  if (!companyQr) {
    return null;
  }

  return <ViewQrCodeSidebarContent companyQr={companyQr} />;
}
