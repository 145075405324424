import { Box, Icon } from '@orbiapp/components';
import styled, { css, keyframes } from 'styled-components';

import { StyledUploadCsvProps } from './upload-csv.types';

const bounceAnimation = keyframes`
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
`;

const Upload = styled(Box)<StyledUploadCsvProps>`
  ${(props) => {
    const { disabled, theme } = props;

    return css`
      background-color: ${theme.colors.uploadBackground};
      background-position: center;
      background-size: cover;
      cursor: pointer;
      min-width: fill-available;

      border: 1px dashed ${theme.colors.uploadBorder};

      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
      `}

      @media (min-width: ${theme.breakpoints.xs}px) {
        min-width: fit-content;
      }

      &:hover {
        background-color: ${theme.colors.uploadBackgroundHover};
      }
    `;
  }}
`;

const UploadIcon = styled(Icon)`
  animation: ${bounceAnimation} 1s infinite;
`;

export const Styled = { Upload, UploadIcon };
