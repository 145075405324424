import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  ImageSliderModal,
  ImageSliderModalSlide,
  InnerPageContainer,
  OrbiLoader,
  PageContainer,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { assets } from '../../../assets';
import {
  CompanySelector,
  getTalentPoolUserStatsThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { ConnectSidebar, ConnectSidebarProvider } from './components';

const welcomeToConnectModalSlides: ImageSliderModalSlide[] = [
  {
    type: 'image',
    src: assets.connectModal.connectModal1,
    alt: translate('label.connect-welcome-modal.slide-1-message'),
    descriptionTx: 'label.connect-welcome-modal.slide-1-message',
  },
  {
    type: 'image',
    src: assets.connectModal.connectModal2,
    alt: translate('label.connect-welcome-modal.slide-2-message'),
    descriptionTx: 'label.connect-welcome-modal.slide-2-message',
  },
  {
    type: 'image',
    src: assets.connectModal.connectModal3,
    alt: translate('label.connect-welcome-modal.slide-3-message'),
    descriptionTx: 'label.connect-welcome-modal.slide-3-message',
  },
];

function WelcomeToConnectModal() {
  const [isOpen, setIsOpen] = React.useState(
    !localStorage.getItem('connectModal'),
  );

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('connectModal', 'false');
  };

  return (
    <ImageSliderModal
      width={800}
      isOpen={isOpen}
      onClose={handleClose}
      slides={welcomeToConnectModalSlides}
      titleTx="label.connect-welcome-modal.title"
      primaryButtonTx="button.get-started"
      primaryButtonOnClick={handleClose}
    />
  );
}

const CONNECT_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/connect', tx: 'label.breadcrumbs.connect.connect' },
];

const CONNECT_TAB_BUTTONS: BreadcrumbListItem[] = [
  {
    tx: 'label.connect.talent-pool',
    to: '/connect/talent-pool',
  },
  {
    tx: 'label.connect.qr-codes',
    to: '/connect/qr-codes',
  },
];

export function Connect() {
  const companyStatus = useSelector(CompanySelector.selectStatus);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTalentPoolUserStatsThunk());
  }, [dispatch]);

  if (companyStatus !== 'completed') {
    return <OrbiLoader />;
  }

  return (
    <ConnectSidebarProvider>
      <WelcomeToConnectModal />

      <PageContainer>
        <BreadcrumbsToolbar
          breadcrumbListItems={CONNECT_BREADCRUMBS}
          tabButtonListItems={CONNECT_TAB_BUTTONS}
        />

        <InnerPageContainer>
          <ContentContainer>
            <Outlet />
          </ContentContainer>

          <ConnectSidebar />
        </InnerPageContainer>
      </PageContainer>
    </ConnectSidebarProvider>
  );
}
