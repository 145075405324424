import { joiResolver } from '@hookform/resolvers/joi';
import { Box, InputRefProvider } from '@orbiapp/components';
import { breakpoints } from '@orbiapp/theme';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { NavBlocker } from '../../../../../components';
import {
  CompanyProfilFormValidation,
  CompanyProfileForm,
  UpdateCompanyProfileDraft,
  isCompanyProfileOrCompanyProfileDraft,
} from '../../../../../models';
import {
  SubjectAreasSelector,
  createCompanyProfileThunk,
  setAlert,
  updateCompanyProfileDraftThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  getCompanyProfilForm,
  getCreateCompanyProfile,
  getIsDirtyField,
  setCompanyProfile,
  trimField,
} from '../../../../../utils';
import { PreviewContext } from '../../preview';
import { GeneralInformation } from './1-general-information';
import { FunFacts } from './2-fun-facts';
import { TargetGroup } from './3-target-group';
import { EmploymentTypes } from './4-employment-types';
import { Perks } from './5-perks';
import { LocationData } from './6-location-data';
import { ContactDetails } from './7-contact-details';
import { Links } from './8-links';
import {
  ProfileContext,
  SubjectAreaSearchContext,
} from './profile-form.context';
import { ProfileContextType, checkForDirtyFields } from './profile-form.types';

export function ProfileProvider(
  props: React.PropsWithChildren &
    Pick<ProfileContextType, 'profile' | 'formMode'> & {
      isLoaded: boolean;
    },
) {
  const { children, formMode, isLoaded, profile } = props;

  const [sidebarIsOpen, setSidebarIsOpen] = React.useState<boolean>(
    window.innerWidth > breakpoints.md,
  );

  const closeSidebar = () => setSidebarIsOpen(false);
  const openSidebar = () => setSidebarIsOpen(true);

  const [deleteProfileIsOpen, setDeleteProfileIsOpen] =
    React.useState<boolean>(false);

  const closeConfirmDeleteProfile = () => setDeleteProfileIsOpen(false);
  const openConfirmDeleteProfile = () => setDeleteProfileIsOpen(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useForm<CompanyProfileForm>({
    resolver: joiResolver(CompanyProfilFormValidation),
    defaultValues: getCompanyProfilForm(formMode === 'createProfile', profile),
  });

  const { getValues, watch, reset, setValue, trigger } = form;

  const [defaultValues, setDefaultValues] = React.useState<CompanyProfileForm>(
    getCompanyProfilForm(formMode === 'createProfile', profile),
  );

  React.useEffect(() => {
    const companyProfilForm = getCompanyProfilForm(getValues('draft'), profile);

    setDefaultValues(companyProfilForm);

    if (!isLoaded) {
      reset(companyProfilForm);
    }
  }, [isLoaded, profile, reset, getValues]);

  const values = watch();

  const dirtyFields = React.useMemo(() => {
    const dirtyFields: (keyof CompanyProfileForm)[] = [];

    checkForDirtyFields.forEach((field) => {
      const isDirty = getIsDirtyField({
        field,
        defaultValues,
        values,
      });

      if (isDirty) {
        dirtyFields.push(field);
      }
    });

    return dirtyFields;
  }, [values, defaultValues]);

  const resetField = (field: keyof CompanyProfileForm) => {
    setValue(field, defaultValues[field], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const saveField = async (
    field: keyof CompanyProfileForm,
    afterDone?: () => void,
    forceIsDirty?: boolean,
  ) => {
    const profileKey = !isCompanyProfileOrCompanyProfileDraft(profile)
      ? null
      : 'companyProfileDraftKey' in profile
      ? profile.companyProfileDraftKey
      : profile.companyProfileKey;

    if (!profileKey) {
      return;
    }

    trimField({
      field,
      getValues,
      setValue,
    });

    const isDirty =
      forceIsDirty ??
      getIsDirtyField({
        field,
        defaultValues,
        values: getValues(),
      });

    const isValid = await trigger(field);

    if (!isDirty) {
      afterDone?.();
      return;
    }

    if (!isValid || formMode !== 'createProfile') {
      return;
    }

    const updateCompanyProfileDraft =
      setCompanyProfile<UpdateCompanyProfileDraft>({
        getValues,
        field,
      });

    dispatch(
      updateCompanyProfileDraftThunk({
        companyProfileDraftKey: profileKey,
        updateCompanyProfileDraft,
      }),
    );
  };

  const publishProfile = async () => {
    if (!isCompanyProfileOrCompanyProfileDraft(profile)) {
      return;
    }

    setValue('draft', false, {
      shouldDirty: true,
    });

    const isValid = await trigger();

    if (!isValid) {
      dispatch(setAlert('error-in-form'));

      return;
    }

    const createCompanyProfile = getCreateCompanyProfile(
      getValues(),
      'companyProfileDraftKey' in profile
        ? profile.companyProfileDraftKey
        : null,
    );

    if (!createCompanyProfile) {
      return;
    }

    const res = await dispatch(createCompanyProfileThunk(createCompanyProfile));

    if (!res.payload) {
      return;
    }

    if ('companyProfileKey' in res.payload) {
      navigate(`/profiles/${res.payload.companyProfileKey}`, { replace: true });
    }
  };

  const [showAllPlaceholders, setShowAllPlaceholders] = React.useState(
    formMode === 'createProfile',
  );

  React.useEffect(() => {
    if (showAllPlaceholders && dirtyFields.length > 0) {
      setShowAllPlaceholders(false);
    }
  }, [showAllPlaceholders, dirtyFields]);

  return (
    <React.Fragment>
      {formMode === 'viewProfile' && (
        <NavBlocker shouldBlock={dirtyFields.length > 0} />
      )}

      <ProfileContext.Provider
        value={{
          closeSidebar,
          openSidebar,
          sidebarIsOpen,

          closeConfirmDeleteProfile,
          openConfirmDeleteProfile,
          deleteProfileIsOpen,

          profile,
          publishProfile,

          dirtyFields,
          resetField,
          saveField,

          formMode,

          isLoaded,
        }}
      >
        <FormProvider {...form}>
          <PreviewContext.Provider value={{ showAllPlaceholders }}>
            {children}
          </PreviewContext.Provider>
        </FormProvider>
      </ProfileContext.Provider>
    </React.Fragment>
  );
}

export function ProfileForm() {
  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);

  return (
    <Box
      flex
      flexDirection="column"
      gap={8}
      pb={32}
      maxWidth="100%"
      width={772}
    >
      <GeneralInformation />

      <FunFacts />

      <SubjectAreaSearchContext.Provider keys={['name']} items={subjectAreas}>
        <InputRefProvider>
          <TargetGroup />
        </InputRefProvider>
      </SubjectAreaSearchContext.Provider>

      <EmploymentTypes />

      <Perks />

      <LocationData />

      <ContactDetails />

      <Links />
    </Box>
  );
}
