import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  SelectionCard,
  Text,
  translate,
} from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';
import { useNavigate } from 'react-router';

import {
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
} from '../../models';
import {
  CompanyProfileDraftsSelector,
  CompanyProfilesSelector,
  useSelector,
} from '../../store';
import { Styled } from './create-profile-modal.styled';
import {
  CreateProfileContextType,
  CreateProfileModalProps,
} from './create-profile-modal.types';

const MAX_VISIBLE_PROFILES_IN_DUPLICATE_FROM_LIST = 5;

const DEFAULT_STATE_VALUE: CreateProfileContextType['state'] = {
  selectedCompanyProfileKey: null,
  selectedCompanyProfileDraftKey: null,
  selection: 'from-scratch',
  view: 'main',
};

const CreateProfileContext = React.createContext<CreateProfileContextType>({
  closeModal: () => {},
  isOpen: false,
  setState: () => {},
  state: DEFAULT_STATE_VALUE,
});

function CreateProfileProvider(
  props: React.PropsWithChildren<CreateProfileModalProps>,
) {
  const { children, closeModal, isOpen } = props;

  const [state, setState] = React.useState(DEFAULT_STATE_VALUE);

  const handleClose = () => {
    closeModal();

    window.setTimeout(() => {
      setState(DEFAULT_STATE_VALUE);
    }, transitonDuration.default);
  };

  return (
    <CreateProfileContext.Provider
      value={{ state, setState, isOpen, closeModal: handleClose }}
    >
      {children}
    </CreateProfileContext.Provider>
  );
}

function DuplicateProfileDraftListItem({
  profileDraft,
}: {
  profileDraft: PartialCompanyProfileDraft;
}) {
  const createProfileContext = React.useContext(CreateProfileContext);

  const toggleSelectedCompanyProfileKey = () => {
    createProfileContext.setState((prevState) => ({
      ...prevState,
      selectedCompanyProfileKey: null,
      selectedCompanyProfileDraftKey:
        prevState.selectedCompanyProfileDraftKey ===
        profileDraft.companyProfileDraftKey
          ? null
          : profileDraft.companyProfileDraftKey,
    }));
  };

  return (
    <MenuItem
      onClick={toggleSelectedCompanyProfileKey}
      isSelected={
        createProfileContext.state.selectedCompanyProfileDraftKey ===
        profileDraft.companyProfileDraftKey
      }
    >
      <Box flex flexDirection="column" height={47}>
        <Text
          color="duplicateProfileModalListItemHeader"
          as="span"
          text={profileDraft.name ?? translate('label.general.untitled-draft')}
          variant="bodyMd"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
        <Text
          color="duplicateProfileModalListItemDescription"
          as="span"
          text="Draft"
          variant="bodySm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
      </Box>
    </MenuItem>
  );
}

const renderDuplicateProfileDraftListItem = (
  profileDraft: PartialCompanyProfileDraft,
) => (
  <DuplicateProfileDraftListItem
    key={`duplicate-profile--draft-list-item-${profileDraft.companyProfileDraftKey}`}
    profileDraft={profileDraft}
  />
);

function DuplicateProfileListItem({
  profile,
}: {
  profile: PartialCompanyProfile;
}) {
  const createProfileContext = React.useContext(CreateProfileContext);

  const toggleSelectedCompanyProfileKey = () => {
    createProfileContext.setState((prevState) => ({
      ...prevState,
      selectedCompanyProfileKey:
        prevState.selectedCompanyProfileKey === profile.companyProfileKey
          ? null
          : profile.companyProfileKey,
      selectedCompanyProfileDraftKey: null,
    }));
  };

  return (
    <MenuItem
      onClick={toggleSelectedCompanyProfileKey}
      isSelected={
        createProfileContext.state.selectedCompanyProfileKey ===
        profile.companyProfileKey
      }
    >
      <Box flex flexDirection="column" height={47}>
        <Text
          color="duplicateProfileModalListItemHeader"
          as="span"
          text={profile.name}
          variant="bodyMd"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
        <Text
          color="duplicateProfileModalListItemDescription"
          as="span"
          text={profile.about}
          variant="bodySm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        />
      </Box>
    </MenuItem>
  );
}

const renderDuplicateProfileListItem = (profile: PartialCompanyProfile) => (
  <DuplicateProfileListItem
    key={`duplicate-profile-list-item-${profile.companyProfileKey}`}
    profile={profile}
  />
);

function DuplicateFromList() {
  const createProfileContext = React.useContext(CreateProfileContext);

  const profiles = useSelector(CompanyProfilesSelector.profilesSortedAfterName);
  const profileDrafts = useSelector(CompanyProfileDraftsSelector.selectAll);

  const navigate = useNavigate();

  const goBackToMain = () => {
    createProfileContext.setState((prevState) => ({
      ...prevState,
      view: 'main',
      selectedCompanyProfileKey: null,
      selectedCompanyProfileDraftKey: null,
    }));
  };

  const continueToDuplicateProfile = () => {
    if (
      !createProfileContext.state.selectedCompanyProfileKey &&
      !createProfileContext.state.selectedCompanyProfileDraftKey
    ) {
      return;
    }

    navigate(
      createProfileContext.state.selectedCompanyProfileKey
        ? `/profiles/create-profile/duplicate/${createProfileContext.state.selectedCompanyProfileKey}`
        : `/profiles/create-profile/duplicate-draft/${createProfileContext.state.selectedCompanyProfileDraftKey}`,
    );

    createProfileContext.closeModal();
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer pb={16}>
        <Box flex flexDirection="column" gap={16}>
          <ModalTitle tx="label.profiles.create-profile-modal.duplicate-from-list.title" />
          <Text
            color="duplicateProfileModalDescription"
            tx="label.profiles.create-profile-modal.duplicate-from-list.description"
            variant="bodyMd"
          />
        </Box>
      </ModalHeaderContainer>

      <ModalBodyContainer py={0}>
        <Styled.DuplicateProfilesList
          height={MAX_VISIBLE_PROFILES_IN_DUPLICATE_FROM_LIST * 63 + 2}
        >
          {profiles.map(renderDuplicateProfileListItem)}
          {profileDrafts.map(renderDuplicateProfileDraftListItem)}
        </Styled.DuplicateProfilesList>
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          tx="button.previous"
          variant="tertiary"
          onClick={goBackToMain}
        />
        <Button
          disabled={
            !createProfileContext.state.selectedCompanyProfileKey &&
            !createProfileContext.state.selectedCompanyProfileDraftKey
          }
          tx="button.duplicate"
          variant="primary"
          onClick={continueToDuplicateProfile}
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function Main() {
  const createProfileContext = React.useContext(CreateProfileContext);
  const navigate = useNavigate();

  const setSelection =
    (selection: CreateProfileContextType['state']['selection']) => () => {
      createProfileContext.setState((prevState) => ({
        ...prevState,
        selection,
      }));
    };

  const continueToSelection = () => {
    if (createProfileContext.state.selection === 'from-scratch') {
      navigate('/profiles/create-profile');

      createProfileContext.closeModal();
      createProfileContext.setState(DEFAULT_STATE_VALUE);

      return;
    }

    createProfileContext.setState((prevState) => ({
      ...prevState,
      view: 'duplicate-from-list',
    }));
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer pb={0}>
        <ModalTitle
          textAlign="center"
          tx="label.profiles.create-profile-modal.main.title"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer py={24}>
        <Box gap={24} flex flexJustify="center" flexWrap="wrap">
          <SelectionCard
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            onClick={setSelection('from-scratch')}
            subtitleTx="label.profiles.create-profile-modal.main.from-scratch-subtitle"
            titleTx="label.profiles.create-profile-modal.main.from-scratch-title"
            toggled={createProfileContext.state.selection === 'from-scratch'}
          />
          <SelectionCard
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            onClick={setSelection('duplicate-from-list')}
            subtitleTx="label.profiles.create-profile-modal.main.duplicate-from-list-subtitle"
            titleTx="label.profiles.create-profile-modal.main.duplicate-from-list-title"
            toggled={
              createProfileContext.state.selection === 'duplicate-from-list'
            }
          />
        </Box>
      </ModalBodyContainer>

      <ModalFooterContainer pt={0}>
        <Button
          tx="button.cancel"
          variant="tertiary"
          onClick={createProfileContext.closeModal}
        />
        <Button
          onClick={continueToSelection}
          tx="button.continue"
          variant="primary"
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function ModalContent() {
  const createProfileContext = React.useContext(CreateProfileContext);

  return (
    <Modal
      width={750}
      isOpen={createProfileContext.isOpen}
      onClose={createProfileContext.closeModal}
    >
      {createProfileContext.state.view === 'duplicate-from-list' ? (
        <DuplicateFromList />
      ) : (
        <Main />
      )}
    </Modal>
  );
}

export function CreateProfileModal(props: CreateProfileModalProps) {
  const { closeModal, isOpen } = props;

  return (
    <CreateProfileProvider closeModal={closeModal} isOpen={isOpen}>
      <ModalContent />
    </CreateProfileProvider>
  );
}
