import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  LAYOUT_Z_INDEX,
  Menu,
  MenuButton,
  MenuItem,
  ProfileMenu,
  ProfileMenuHeader,
  ProfileMenuList,
  ProfileMenuListItem,
  ResponsiveBox,
  SelectWorkspaceMenu,
  SelectWorkspaceMenuItem,
  SidebarContext,
  Text,
  Time,
  Toolbar,
  ToolbarContentContainer,
  getAvatarVariantFromString,
  getPlacementMargin,
  numberFormatter,
  useAnchoredMenu,
} from '@orbiapp/components';
import React from 'react';

import { LEARN_MORE_ABOUT_WORKSPACES_URL } from '../../constants';
import {
  AccountSelector,
  CompanySelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../store';
import { CreateProfileModal } from '../create-profile-modal';
import { ProfileMenuContext, ProfileMenuProvider } from '../profile-menu';
import { QuickActionsContext } from '../quick-actions';

function ToggleSidebarButton() {
  const { collapsed, toggleSidebar } = React.useContext(SidebarContext);

  return (
    <ResponsiveBox xs={null}>
      <IconButton
        icon={collapsed ? 'bars-3' : 'x-circle-outline'}
        onClick={toggleSidebar}
      />
    </ResponsiveBox>
  );
}

function CreateProfileMenuItem() {
  const { createProfileModalState } = React.useContext(QuickActionsContext);

  return (
    <MenuItem onClick={createProfileModalState.openModal}>
      <Text
        as="span"
        tx="label.quick-actions.create-profile"
        variant="bodyMd"
      />
    </MenuItem>
  );
}

function CreateNewProfileModal() {
  const { createProfileModalState } = React.useContext(QuickActionsContext);

  return (
    <CreateProfileModal
      isOpen={createProfileModalState.isOpen}
      closeModal={createProfileModalState.closeModal}
    />
  );
}

function CreateJobMenuItem() {
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);

  if (!enableJobs) {
    return null;
  }

  return (
    <MenuItem to="/jobs/create-job">
      <Text as="span" tx="label.quick-actions.create-job" variant="bodyMd" />
    </MenuItem>
  );
}

function CreateOfferMenuItem() {
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  if (!enableOffers) {
    return null;
  }

  return (
    <MenuItem to="/offers/create-offer">
      <Text as="span" tx="label.quick-actions.create-offer" variant="bodyMd" />
    </MenuItem>
  );
}

function QuickActionsMenu() {
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  const anchoredMenu = useAnchoredMenu<HTMLButtonElement>({
    placement: 'bottom',
    autoCloseDuration: 5 * Time.Second,
  });

  const { createProfileModalState } = React.useContext(QuickActionsContext);

  const openModalAndCloseMenu = () => {
    createProfileModalState.openModal();
    anchoredMenu.closeMenu();
  };

  if (!enableJobs && !enableOffers) {
    return (
      <Button
        variant="primary"
        tx="button.profiles.create"
        onClick={openModalAndCloseMenu}
      />
    );
  }

  return (
    <ResponsiveBox
      xs={
        <Box ref={anchoredMenu.clickOutsideRef} minWidth={165}>
          <MenuButton
            isOpen={anchoredMenu.isOpen}
            onClick={openModalAndCloseMenu}
            onToggle={anchoredMenu.toggleMenu}
            ref={anchoredMenu.anchorRef}
            tx="label.quick-actions.create-profile"
          />

          <Menu
            isOpen={anchoredMenu.isOpen}
            ref={anchoredMenu.menuRef}
            minWidth="inherit"
            onClick={anchoredMenu.closeMenu}
            {...getPlacementMargin('bottom-start')}
          >
            <CreateProfileMenuItem />

            <CreateJobMenuItem />

            <CreateOfferMenuItem />
          </Menu>
        </Box>
      }
    />
  );
}

function ToggleUserProfileMenu() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const profileMenuContext = React.useContext(ProfileMenuContext);

  if (!fullName || !userKey) {
    return null;
  }

  return (
    <Avatar
      variant={getAvatarVariantFromString(userKey)}
      cursor="pointer"
      fallbackLetter={fullName[0]}
      highlight={profileMenuContext.isOpen}
      onClick={profileMenuContext.toggleMenu}
      ref={profileMenuContext.anchorRef}
      src={profilePicture?.thumbnail64.url ?? undefined}
    />
  );
}

function UserProfileMenu() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const email = useSelector(AccountSelector.selectEmail);

  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const companyName = useSelector(CompanySelector.selectCompanyName);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);

  const profileMenuContext = React.useContext(ProfileMenuContext);

  if (!fullName || !email || !profileMenuContext.isOpen || !userKey) {
    return null;
  }

  const avatarVariant = getAvatarVariantFromString(userKey);

  return (
    <ProfileMenu
      zIndex={LAYOUT_Z_INDEX.profileMenu}
      ref={profileMenuContext.menuRef}
    >
      <ProfileMenuHeader
        avatarVariant={avatarVariant}
        profilePictureSrc={profilePicture?.thumbnail64.url ?? ''}
        fullName={fullName}
        email={email}
      />

      <ProfileMenuList>
        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.your-profile"
          icon="user-circle-outline"
          to="/settings/account"
          onClick={profileMenuContext.closeMenu}
        />

        <ProfileMenuListItem
          to="/settings/company"
          onClick={profileMenuContext.closeMenu}
        >
          <Avatar
            variant={avatarVariant}
            src={companyLogo?.thumbnail64.url ?? undefined}
            fallbackLetter={companyName?.charAt(0)}
            height={24}
            width={24}
          />
          <Text
            color="profileMenuListItemLabel"
            tx="label.toolbar-profile-menu.company-settings"
            variant="bodySm"
          />
        </ProfileMenuListItem>

        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.team-settings"
          icon="users-outline"
          to="/settings/team"
          onClick={profileMenuContext.closeMenu}
        />

        <ProfileMenuListItem
          href="mailto:support@orbiapp.io"
          icon="question-mark-circle-outline"
          onClick={profileMenuContext.closeMenu}
          tx="label.toolbar-profile-menu.support"
        />

        <Divider />

        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.sign-out"
          icon="power"
          to="/sign-out"
          onClick={profileMenuContext.closeMenu}
        />
      </ProfileMenuList>
    </ProfileMenu>
  );
}

function ProfileMenuWrapper() {
  const profileMenuContext = React.useContext(ProfileMenuContext);

  return (
    <Box ref={profileMenuContext.clickOutsideRef}>
      <ToggleUserProfileMenu />
      <UserProfileMenu />
    </Box>
  );
}

function useWorkspaceMenuItems() {
  const companyKey = useSelector(CompanySelector.selectCompanyKey);
  const workspaces = useSelector(AccountSelector.selectSwitchableWorkspaces);
  const adminWorkspace = useSelector(AccountSelector.selectAdminWorkspace);

  return React.useMemo(() => {
    const workspaceMenuitems = workspaces
      .filter((workspace) =>
        workspace.type === 'company'
          ? workspace.companyKey !== companyKey
          : true,
      )
      .map((workspace): SelectWorkspaceMenuItem => {
        switch (workspace.type) {
          case 'company':
            return {
              avatarSrc: workspace.logo.thumbnail64.url,
              avatarVariant: getAvatarVariantFromString(workspace.companyKey),
              label: workspace.name,
              switchTx: 'label.general.switch',
              to: `/workspace/${workspace.type}/${workspace.companyKey}`,
            };

          default:
            return {
              avatarSrc: workspace.logo?.thumbnail64.url ?? '',
              avatarVariant: getAvatarVariantFromString(
                workspace.departmentKey,
              ),
              label: workspace.name,
              switchTx: 'label.general.switch',
              to: `/workspace/${workspace.type}/${workspace.departmentKey}`,
            };
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    if (adminWorkspace) {
      workspaceMenuitems.unshift({
        avatarVariant: 0,
        label: 'Admin',
        switchTx: 'label.general.switch',
        to: `/workspace/${adminWorkspace.type}/0`,
      });
    }

    return workspaceMenuitems;
  }, [companyKey, workspaces, adminWorkspace]);
}

export function SignedInToolbar() {
  const followerCount = useSelector(CompanySelector.selectFollowerCount);
  const companyName = useSelector(CompanySelector.selectCompanyName);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);
  const companyKey = useSelector(CompanySelector.selectCompanyKey);

  const workspacesMenuItems = useWorkspaceMenuItems();

  return (
    <ProfileMenuProvider>
      <Toolbar
        backgroundColor="toolbarBackground"
        zIndex={LAYOUT_Z_INDEX.pageToolbar}
      >
        <ToolbarContentContainer py={8}>
          <Box flex flexAlign="center" width="100%" gap={56}>
            <Box overflow="hidden" flexAlign="center" gap={16} flex>
              <ToggleSidebarButton />

              <SelectWorkspaceMenu
                avatarSrc={companyLogo?.thumbnail64.url}
                avatarVariant={getAvatarVariantFromString(companyKey ?? '')}
                emptyStateLinkHref={LEARN_MORE_ABOUT_WORKSPACES_URL}
                emptyStateLinkTarget="_blank"
                emptyStateLinkTx="link.general.learn-more"
                emptyStateTx="label.general.workspaces-menu-empty-state"
                filterPlaceholderTx="placeholder.filter-workspaces"
                label={companyName ?? ''}
                workspaces={workspacesMenuItems}
              />
            </Box>

            <QuickAction />

            <Box ml="auto" flex gap={32} flexAlign="center">
              <ResponsiveBox
                xs={
                  <Chip
                    variant={1}
                    tx="label.general.followers"
                    whiteSpace="nowrap"
                    txArgs={{
                      followerCount: numberFormatter.format(followerCount ?? 0),
                    }}
                  />
                }
              />

              <IconButton icon="cog-6-tooth-outline" to="/settings/company" />

              <ProfileMenuWrapper />
            </Box>
          </Box>
        </ToolbarContentContainer>
      </Toolbar>

      <CreateNewProfileModal />
    </ProfileMenuProvider>
  );
}

function QuickAction() {
  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );

  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  if (enableProfiles) {
    return <QuickActionsMenu />;
  }

  if (enableOffers) {
    return (
      <Button
        to="/offers/create-offer"
        tx="button.offers.new-offer"
        variant="primary"
      />
    );
  }

  return null;
}
