import React from 'react';

export type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export type UseFilterSorter<T extends Record<string, any>> = (
  a: T,
  b: T,
) => number;

export function useFilter<T extends Record<string, any>>(
  items: T[],
  keys: StringValues<T>[],
  sorter?: UseFilterSorter<T>,
) {
  const [searchString, setValue] = React.useState('');

  const handleSearchStringChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setValue(e.target.value);
  };

  const filteredItems = React.useMemo(() => {
    const filteredItems = items.filter((item) => {
      return keys.some((key) => {
        return item[key].toLowerCase().includes(searchString.toLowerCase());
      });
    });

    if (sorter) {
      return filteredItems.sort(sorter);
    }

    return filteredItems;
  }, [keys, items, searchString, sorter]);

  const resetSearchString = () => setValue('');

  return {
    searchString,
    items: filteredItems,

    handleSearchStringChange,
    resetSearchString,
  };
}

export type FilterContextState<T extends Record<string, any>> = ReturnType<
  typeof useFilter<T>
>;
