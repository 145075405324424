import styled, { css } from 'styled-components';

import { Box } from '../../box';
import { Icon } from '../../icon';
import { TABLE_ROW_HEIGHT } from '../table.constants';
import { TableHeadProps } from './table-head.types';

const TableHead = styled.th<TableHeadProps>`
  ${(props) => {
    const { theme, fixedLeft, fixedRight } = props;

    return css`
      background-color: inherit;
      padding: 8px 16px;
      white-space: nowrap;
      height: ${TABLE_ROW_HEIGHT}px;
      text-align: left;
      color: ${theme.colors.tableHeaderCell};

      ${fixedLeft &&
      css`
        z-index: 5;
        position: sticky;
        left: 0;
      `}

      ${fixedRight &&
      css`
        position: sticky;
        z-index: 5;
        right: 0;
      `}
    `;
  }}
`;

const TableHeadContent = styled(Box)`
  ${(props) => {
    const { onClick, theme } = props;
    return css`
      ${onClick &&
      css`
        &:hover {
          color: ${theme.colors.tableHeadCellHover};
        }

        &:active {
          color: ${theme.colors.tableHeadCellActive};
        }
      `}
    `;
  }}
`;

const SortingIcon = styled(Icon)<{ enableSorting: boolean }>`
  ${(props) => {
    const { enableSorting } = props;

    return css`
      ${!enableSorting &&
      css`
        opacity: 0;
      `}
    `;
  }}
`;

export const Styled = { TableHead, TableHeadContent, SortingIcon };
