import {
  Box,
  BoxProps,
  Checkbox,
  FormSection,
  FormSectionHeader,
  InputChip,
  InputChips,
  InputRefContext,
  Link,
  Menu,
  MenuDivider,
  MenuItem,
  Text,
  getListHeight,
  isTxString,
  useMenu,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import { PickProfileModal } from '../../../../../components';
import { CompanyProfileForm, SubjectArea } from '../../../../../models';
import {
  SubjectAreaCategoriesSelector,
  SubjectAreasSelector,
  setAlert,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  ProfileContext,
  SubjectAreaSearchContext,
} from './profile-form.context';

const SUBJECT_AREAS_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = SUBJECT_AREAS_ITEM_HEIGHT * 9;

function SubjectAreaCategoryMenuItem(
  props: { subjectAreaCategoryKey: string } & BoxProps,
) {
  const { subjectAreaCategoryKey, ...rest } = props;

  const { setValue, watch } = useFormContext<CompanyProfileForm>();

  const subjectAreaCategory = useSelector((state) =>
    SubjectAreaCategoriesSelector.selectById(state, subjectAreaCategoryKey),
  );

  const subjectAreaKeys = watch('subjectAreaKeys');

  const isChecked = React.useMemo(() => {
    return !!subjectAreaCategory?.subjectAreas.every((subjectArea) =>
      subjectAreaKeys?.includes(subjectArea.subjectAreaKey),
    );
  }, [subjectAreaKeys, subjectAreaCategory]);

  const inputRef = React.useContext(InputRefContext);

  const toggleSubjectAreaKeys = () => {
    if (isChecked) {
      const newValue =
        subjectAreaKeys?.filter(
          (subjectAreaKey) =>
            !subjectAreaCategory?.subjectAreas.some(
              (subjectArea) => subjectArea.subjectAreaKey === subjectAreaKey,
            ),
        ) ?? [];

      setValue('subjectAreaKeys', newValue.length ? newValue : null, {
        shouldDirty: true,
      });

      inputRef.current?.focus();

      return;
    }

    const newValue: string[] = subjectAreaKeys ?? [];

    subjectAreaCategory?.subjectAreas.forEach((subjectArea) => {
      if (!subjectAreaKeys?.includes(subjectArea.subjectAreaKey)) {
        newValue.push(subjectArea.subjectAreaKey);
      }
    });

    setValue('subjectAreaKeys', newValue.length ? newValue : null, {
      shouldDirty: true,
    });

    inputRef.current?.focus();
  };

  return (
    <MenuDivider
      flex
      flexAlign="center"
      gap={8}
      text={subjectAreaCategory?.name}
      pl={8}
      onClick={toggleSubjectAreaKeys}
      cursor="pointer"
      {...rest}
    >
      <Checkbox checked={isChecked} onChange={() => {}} />
    </MenuDivider>
  );
}

function SubjectAreaMenuItem(
  props: SubjectArea & { style: React.CSSProperties },
) {
  const { subjectAreaKey, name, ...rest } = props;

  const { setValue, watch } = useFormContext<CompanyProfileForm>();

  const subjectAreaKeys = watch('subjectAreaKeys') ?? [];

  const isChecked = !!subjectAreaKeys?.includes(subjectAreaKey);

  const inputRef = React.useContext(InputRefContext);

  const toggleSubjectAreaKey = () => {
    const newValue = isChecked
      ? subjectAreaKeys.filter(
          (_subjectAreaKey) => _subjectAreaKey !== subjectAreaKey,
        )
      : [...subjectAreaKeys, subjectAreaKey];

    setValue('subjectAreaKeys', newValue?.length ? newValue : null, {
      shouldDirty: true,
    });

    inputRef.current?.focus();
  };

  return (
    <MenuItem
      onClick={toggleSubjectAreaKey}
      gap={8}
      flex
      flexAlign="center"
      pl={32}
      checkbox
      isSelected={isChecked}
      text={name}
      {...rest}
    />
  );
}

export function TargetGroup() {
  const { saveField, formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const save = () => saveField('subjectAreaKeys');

  const inputRef = React.useContext(InputRefContext);

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CompanyProfileForm>();

  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);
  const subjectAreaCategories = useSelector(
    SubjectAreaCategoriesSelector.selectSubjectAreaCategoryWithNameAndKey,
  );

  const modalState = useModalState();

  const dispatch = useDispatch();

  const searchContext = React.useContext(
    SubjectAreaSearchContext.FilterContext,
  );

  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu({
    onClickOutside: isDraft ? save : undefined,
  });

  const menuIsOpen = menuState.isOpen && !!searchContext.items.length;

  const closeMenu = () => {
    menuState.closeMenu();

    if (isDraft) {
      save();
    }
  };

  const subjectAreaKeys = watch('subjectAreaKeys') ?? [];

  const subjectAreasMap = new Map(
    subjectAreas.map((subjectArea) => [
      subjectArea.subjectAreaKey,
      subjectArea.name,
    ]),
  );

  const items = React.useMemo(() => {
    return subjectAreaCategories.flatMap((subjectAreaCategory) => {
      const items = searchContext.items.filter(
        (value) =>
          value.subjectAreaCategoryKey ===
          subjectAreaCategory.subjectAreaCategoryKey,
      );

      if (items.length === 0) {
        return [];
      }

      return [
        {
          key: subjectAreaCategory.subjectAreaCategoryKey,
          name: subjectAreaCategory.name,
          isCategory: true,
        },
        ...items.map((item) => item),
      ];
    });
  }, [searchContext.items, subjectAreaCategories]);

  const handleUpdateSubjectAreas = (subjectAreaKeys: string[]) => {
    setValue('subjectAreaKeys', subjectAreaKeys, {
      shouldDirty: true,
    });

    dispatch(setAlert('copy-subject-areas:success'));

    if (isDraft) {
      save();
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.handleSearchStringChange(e);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<string>[] =
    subjectAreaKeys?.map((subjectAreaKey) => ({
      id: subjectAreaKey,
      text: subjectAreasMap.get(subjectAreaKey)!,
      maxWidth: 'unset',
    })) ?? [];

  const removeChip = (id: string) => {
    const newValue = subjectAreaKeys?.filter(
      (subjectAreaKey) => subjectAreaKey !== id,
    );

    setValue('subjectAreaKeys', newValue?.length ? newValue : null, {
      shouldDirty: true,
    });

    if (!menuState.isOpen && isDraft) {
      save();
    }
  };

  const rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const item = items[index];

    if ('isCategory' in item) {
      return (
        <SubjectAreaCategoryMenuItem
          key={key}
          style={style}
          subjectAreaCategoryKey={item.key}
        />
      );
    }

    return <SubjectAreaMenuItem key={key} style={style} {...item} />;
  };

  const listHeight = getListHeight(
    items.length,
    SUBJECT_AREAS_ITEM_HEIGHT,
    MENU_MAX_HEIGHT,
  );

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.profile.target-group.header"
          variant="bodyMdBold"
          color="formSectionHeader"
        />

        <Text
          tx="label.profile.target-group.description"
          variant="bodyMd"
          color="formSectionDescription"
        />

        <Link
          onClick={modalState.openModal}
          tx="label.profile.target-group.link"
          variant="secondary"
        />

        <PickProfileModal
          isOpen={modalState.isOpen}
          closeModal={modalState.closeModal}
          onSave={handleUpdateSubjectAreas}
        />
      </FormSectionHeader>

      <Box ref={menuState.clickOutsideRef}>
        <InputChips
          onChange={handleInputChange}
          onFocus={menuState.openMenu}
          maxChips={1000}
          value={searchContext.searchString}
          chips={chips}
          ref={inputRef}
          showInput={menuState.isOpen || !!searchContext.searchString}
          errorTx={
            isTxString(errors.subjectAreaKeys?.message)
              ? errors.subjectAreaKeys?.message
              : undefined
          }
          onRemoveChip={removeChip}
          leadingElements={[
            {
              type: 'icon',
              name: 'magnifying-glass',
            },
          ]}
          trailingElements={[
            {
              type: 'button',
              icon: menuIsOpen ? 'chevron-up' : 'chevron-down',
              onClick: menuIsOpen ? closeMenu : menuState.openMenu,
              tooltipTx: menuIsOpen
                ? 'label.tooltip.collapse'
                : 'label.tooltip.expand',
              tooltipPlacement: 'left',
            },
          ]}
          labelTx="label.profile.target-group.inputs.subject-areas"
          menuElement={
            <Menu
              absolute
              isOpen={menuIsOpen}
              maxHeight={MENU_MAX_HEIGHT}
              ref={menuRef}
              mt={8}
              top="100%"
              width="100%"
              left={0}
            >
              <List
                containerStyle={{ width: '100%', maxWidth: '100%' }}
                style={{ width: '100%' }}
                width={1}
                height={listHeight}
                rowCount={items.length}
                rowHeight={SUBJECT_AREAS_ITEM_HEIGHT}
                rowRenderer={rowRenderer}
              />
            </Menu>
          }
        />
      </Box>
    </FormSection>
  );
}
