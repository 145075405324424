import {
  Alert,
  Avatar,
  Box,
  Button,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  IconButton,
  InnerContentContainer,
  Input,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Snackbar,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Tooltip,
  getAvatarVariantFromString,
  useConfirm,
} from '@orbiapp/components';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { useInviteTeamMembers } from '../../../../helpers';
import { PendingCompanyInvitation, User } from '../../../../models';
import {
  AccountSelector,
  DeleteInvitationSelector,
  PendingInvitationsSelector,
  RemoveUserSelector,
  UsersSelector,
  deleteInvitationThunk,
  getPendingInvitationsThunk,
  getUsersThunk,
  removeUserThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';

const TeamMembersContext = React.createContext<{
  selectedUser: User | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}>({
  selectedUser: null,
  setSelectedUser: () => {},
});

function TeamMembersProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  return (
    <TeamMembersContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </TeamMembersContext.Provider>
  );
}

const TABLE_COLUMN_WIDTHS = {
  name: 200,
  email: 200,
  status: 140,
  actions: 25,
};

function getUserFullname(user: User) {
  return `${user.firstName} ${user.lastName}`;
}

const AddTeamMemberContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

function AddTeamMemberProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <AddTeamMemberContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </AddTeamMemberContext.Provider>
  );
}

const ConfirmDeletePendingInvitationContext = React.createContext<{
  closeModal: () => void;
  isOpen: boolean;
  openModal: (pendingInvitation: PendingCompanyInvitation) => void;
  pendingInvitation: PendingCompanyInvitation | null;
}>({
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
  pendingInvitation: null,
});

function ConfirmDeletePendingInvitationProvider(
  props: React.PropsWithChildren,
) {
  const { children } = props;

  const { closeConfirm, isOpen, openConfirm } =
    useConfirm<PendingCompanyInvitation>();

  const [pendingInvitation, setPendingInvitation] =
    useState<PendingCompanyInvitation | null>(null);

  const openModal = (pendingInvitation: PendingCompanyInvitation) => {
    setPendingInvitation(pendingInvitation);
    openConfirm(pendingInvitation);
  };

  return (
    <ConfirmDeletePendingInvitationContext.Provider
      value={{
        closeModal: closeConfirm,
        isOpen,
        openModal,
        pendingInvitation,
      }}
    >
      {children}
    </ConfirmDeletePendingInvitationContext.Provider>
  );
}

const ConfirmRemoveTeamMemberContext = React.createContext<{
  closeModal: () => void;
  isOpen: boolean;
  openModal: (user: User) => void;
  user: User | null;
}>({
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
  user: null,
});

function ConfirmRemoveTeamMemberProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const { closeConfirm, isOpen, openConfirm } = useConfirm<User>();

  const [user, setTeamMember] = useState<User | null>(null);

  const openModal = (user: User) => {
    setTeamMember(user);
    openConfirm(user);
  };

  return (
    <ConfirmRemoveTeamMemberContext.Provider
      value={{
        closeModal: closeConfirm,
        isOpen,
        openModal,
        user,
      }}
    >
      {children}
    </ConfirmRemoveTeamMemberContext.Provider>
  );
}

function TeamInvitationTableRow({
  pendingInvitation,
}: {
  pendingInvitation: PendingCompanyInvitation;
}) {
  const confirmDeleteInvitation = React.useContext(
    ConfirmDeletePendingInvitationContext,
  );

  const stageForRemoval = () => {
    confirmDeleteInvitation.openModal(pendingInvitation);
  };

  const highlight =
    confirmDeleteInvitation.isOpen &&
    confirmDeleteInvitation.pendingInvitation?.companyInvitationKey ===
      pendingInvitation.companyInvitationKey;

  return (
    <TableRow highlight={highlight}>
      <TableCell width={TABLE_COLUMN_WIDTHS.name} />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.email}
        text={pendingInvitation.email}
      />
      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status tx="label.team.table.pending-invitation" variant="warning" />
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight hoverCell>
        <Box ml="auto" flex flexJustify="center">
          <Tooltip placement="left" titleTx="label.tooltip.delete">
            <IconButton onClick={stageForRemoval} icon="trash-outline" />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function TeamMemberTableRow({ user }: { user: User }) {
  const { selectedUser, setSelectedUser } =
    React.useContext(TeamMembersContext);

  const highlight = selectedUser?.userKey === user.userKey;

  const fullName = getUserFullname(user);

  const selectTeamMember = () => {
    setSelectedUser(user);
  };

  return (
    <TableRow onClick={selectTeamMember} highlight={highlight}>
      <TableCell width={TABLE_COLUMN_WIDTHS.name}>
        <Avatar
          variant={getAvatarVariantFromString(user.userKey)}
          fallbackLetter={fullName.charAt(0)}
          minWidth={40}
          src={user.profilePicture?.thumbnail64.url ?? undefined}
        />
        <Text ml={8} variant="bodySm" text={fullName} as="span" />
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.email} text={user.email} />
      <TableCell width={TABLE_COLUMN_WIDTHS.status}/>
      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
    </TableRow>
  );
}

function renderUserTableRow(user: User) {
  return <TeamMemberTableRow user={user} key={user.userKey} />;
}

function renderPendingInvitationTableRow(
  pendingInvitation: PendingCompanyInvitation,
) {
  return (
    <TeamInvitationTableRow
      pendingInvitation={pendingInvitation}
      key={pendingInvitation.companyInvitationKey}
    />
  );
}

function TeamTable() {
  const usersStatus = useSelector(UsersSelector.selectStatus);
  const users = useSelector(UsersSelector.selectAll);

  const pendingInvitationsStatus = useSelector(
    PendingInvitationsSelector.selectStatus,
  );
  const pendingInvitations = useSelector(PendingInvitationsSelector.selectAll);

  const isLoading = isAnyPending(usersStatus, pendingInvitationsStatus);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead tx="label.team.table.name" />
          <TableHead tx="label.team.table.email" />
          <TableHead tx="label.team.table.status" />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        ) : (
          <React.Fragment>
            {users.map(renderUserTableRow)}
            {pendingInvitations.map(renderPendingInvitationTableRow)}
          </React.Fragment>
        )}
      </TableBody>
    </Table>
  );
}

function AddTeamMemberButton() {
  const { setIsOpen } = React.useContext(AddTeamMemberContext);

  const totalMembersCount = useSelector(UsersSelector.selectCount);

  if (totalMembersCount === 0) {
    return null;
  }

  const openAddTeamMemberModal = () => setIsOpen(true);

  return (
    <Button
      onClick={openAddTeamMemberModal}
      tx="label.team.invite-team-member.open-modal"
      variant="primary"
    />
  );
}

function ConfirmDeletePendingInvitationModal() {
  const dispatch = useDispatch();

  const deletePendingInvitationStatus = useSelector(
    DeleteInvitationSelector.selectStatus,
  );

  const confirmDeleteInvitation = React.useContext(
    ConfirmDeletePendingInvitationContext,
  );

  const deleteInvitation = async () => {
    if (!confirmDeleteInvitation.pendingInvitation) {
      return;
    }

    const res = await dispatch(
      deleteInvitationThunk(
        confirmDeleteInvitation.pendingInvitation.companyInvitationKey,
      ),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      confirmDeleteInvitation.closeModal();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.pending-invitation.cancel"
      confirmTx="prompt.pending-invitation.confirm"
      isLoading={deletePendingInvitationStatus === 'pending'}
      isOpen={confirmDeleteInvitation.isOpen}
      messageTx="prompt.pending-invitation.message"
      messageTxArgs={{
        email: confirmDeleteInvitation.pendingInvitation?.email ?? '',
      }}
      onCancel={confirmDeleteInvitation.closeModal}
      onConfirm={deleteInvitation}
      titleTx="prompt.pending-invitation.title"
    />
  );
}

function ConfirmRemoveTeamMemberModal() {
  const dispatch = useDispatch();

  const removeUserSelector = useSelector(RemoveUserSelector.selectStatus);

  const confirmRemoveTeamMember = React.useContext(
    ConfirmRemoveTeamMemberContext,
  );

  const removeTeamMember = async () => {
    if (!confirmRemoveTeamMember.user) {
      return;
    }

    const res = await dispatch(
      removeUserThunk(confirmRemoveTeamMember.user.userKey),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      confirmRemoveTeamMember.closeModal();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.remove-team-member.cancel"
      confirmTx="prompt.remove-team-member.confirm"
      isLoading={removeUserSelector === 'pending'}
      isOpen={confirmRemoveTeamMember.isOpen}
      messageTx="prompt.remove-team-member.message"
      messageTxArgs={{
        name: confirmRemoveTeamMember.user
          ? getUserFullname(confirmRemoveTeamMember.user)
          : '',
      }}
      onCancel={confirmRemoveTeamMember.closeModal}
      onConfirm={removeTeamMember}
      titleTx="prompt.remove-team-member.title"
    />
  );
}

function AddTeamMemberModal() {
  const { isOpen, setIsOpen } = React.useContext(AddTeamMemberContext);
  const closeModal = () => setIsOpen(false);

  const {
    invites,
    formMethods,
    everyEmailIsEmptyString,
    sendInvites,
    addInvite,
    isLoading,
  } = useInviteTeamMembers({
    onSuccess: closeModal,
  });

  return (
    <React.Fragment>
      <Modal width={600} isOpen={isOpen} onClose={closeModal}>
        <ModalContentContainer>
          <ModalHeaderContainer>
            <ModalTitle tx="label.team.invite-team-member.label" />
          </ModalHeaderContainer>
          <ModalBodyContainer>
            <Box flex flexDirection="column" gap={32}>
              <FormProvider {...formMethods}>{invites}</FormProvider>
            </Box>
          </ModalBodyContainer>

          <ModalFooterContainer>
            <Button
              onClick={addInvite}
              variant="secondary"
              tx="button.add-invite"
              disabled={isLoading}
            />
            <Button
              isLoading={isLoading}
              onClick={sendInvites}
              variant="primary"
              tx="button.invite"
            />
          </ModalFooterContainer>
        </ModalContentContainer>
      </Modal>

      {formMethods.formState.isSubmitted &&
        everyEmailIsEmptyString &&
        isOpen && (
          <Snackbar zIndex={10000} placement="top-end">
            <Alert variant="error" subtitleTx="errors.invitations.empty" />
          </Snackbar>
        )}
    </React.Fragment>
  );
}

function RemoveTeamMemberButton(props: { openConfirm: () => void }) {
  const { openConfirm } = props;

  const { selectedUser } = React.useContext(TeamMembersContext);

  const userKey = useSelector(AccountSelector.selectUserKey);

  if (selectedUser?.userKey === userKey) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.team.invite-team-member.cannot-remove-self"
      >
        <Button disabled tx="button.revoke" variant="destructive" />
      </Tooltip>
    );
  }

  return (
    <Button tx="button.revoke" variant="destructive" onClick={openConfirm} />
  );
}

function TeamMembersSidebar() {
  const { setSelectedUser, selectedUser } =
    React.useContext(TeamMembersContext);

  const { openModal } = React.useContext(ConfirmRemoveTeamMemberContext);

  const isOpen = selectedUser !== null;
  const closeTeamMembersSidebar = () => setSelectedUser(null);

  const openConfirm = () => {
    openModal(selectedUser!);
  };

  return (
    <ContentSidebar
      isOpen={isOpen}
      onClose={closeTeamMembersSidebar}
      width={460}
    >
      <ContentSidebarContentContainer>
        <Box flexAlign="center" flex flexJustify="between">
          <Text
            as="h1"
            tx="label.team.update-team-member.label"
            variant="bodyLgBold"
          />

          <Button disabled tx="button.save" variant="primary" />
        </Box>

        <Box flex flexDirection="column" gap={32}>
          <Input
            disabled
            labelTx="label.team.table.name"
            name="email"
            readOnly
            value={selectedUser ? getUserFullname(selectedUser) : ''}
          />

          <Input
            disabled
            labelTx="label.team.email"
            name="email"
            readOnly
            value={selectedUser?.email ?? ''}
          />

        </Box>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer gap={16} flexWrap>
        <RemoveTeamMemberButton openConfirm={openConfirm} />
      </ContentSidebarFooterContainer>
    </ContentSidebar>
  );
}

export function TeamSettings() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUsersThunk());
    dispatch(getPendingInvitationsThunk());
  }, [dispatch]);

  return (
    <AddTeamMemberProvider>
      <TeamMembersProvider>
        <ConfirmDeletePendingInvitationProvider>
          <ConfirmRemoveTeamMemberProvider>
            <ContentContainer>
              <InnerContentContainer>
                <Box flex flexWrap gap={16} flexJustify="between">
                  <Text
                    color="pageTitle"
                    variant="titleMd"
                    tx="title.team.dashboard"
                    as="h1"
                  />

                  <AddTeamMemberButton />
                </Box>

                <TeamTable />
              </InnerContentContainer>
            </ContentContainer>

            <TeamMembersSidebar />

            <AddTeamMemberModal />
            <ConfirmDeletePendingInvitationModal />
            <ConfirmRemoveTeamMemberModal />
          </ConfirmRemoveTeamMemberProvider>
        </ConfirmDeletePendingInvitationProvider>
      </TeamMembersProvider>
    </AddTeamMemberProvider>
  );
}
