import styled, { css } from 'styled-components';

import { Box } from '../box';
import { ToolbarProps } from './toolbar.types';

const Toolbar = styled(Box)<ToolbarProps>`
  ${(props) => {
    const { theme, backgroundColor, bottomBorderColor } = props;
    const _bottomBorderColor = bottomBorderColor
      ? theme.colors[bottomBorderColor]
      : 'transparent';

    return css`
      background-color: ${theme.colors[backgroundColor]};
      border-bottom: 1px solid ${_bottomBorderColor};
    `;
  }}
`;

const ToolbarContentContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      padding: 8px 16px;
      border-bottom: 1px solid ${theme.colors.toolbarBottomBorder};
      transition: all ${theme.transitions.default};

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 16px 32px;
      }

      &.hidden {
        margin-top: -64px;
      }
    `;
  }}
`;

export const Styled = { Toolbar, ToolbarContentContainer };
