import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';
import { StyledSwitchProps } from './switch.types';

const SwitchContainer = styled(Box)`
  width: 56px;
  height: 28px;
`;

const BlobOverlay = styled(Box)<StyledSwitchProps>`
  ${(props) => {
    const { theme, checked } = props;

    return css`
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      position: absolute;
      left: -4px;
      opacity: 0.7;
      z-index: 1;
      background-color: transparent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ${theme.transitions.fast};

      ${checked &&
      css`
        left: calc(100% - 26px);
      `}
    `;
  }}
`;

const SwitchIcon = styled(Icon)<StyledSwitchProps>`
  ${(props) => {
    const { theme, checked } = props;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.switchIcon};
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      border-radius: 50%;
      transition: all ${theme.transitions.fast};

      ${checked &&
      css`
        left: calc(100% - 18px);
      `}
    `;
  }}
`;

const Switch = styled(Box)<StyledSwitchProps>`
  ${(props) => {
    const { theme, checked, disabled } = props;

    return css`
      width: 52px;
      height: 24px;
      border-radius: 50px;
      position: relative;
      cursor: pointer;

      background-color: ${theme.colors.switchBackground};
      transition: all ${theme.transitions.fast};

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      ${checked &&
      css`
        background-color: ${theme.colors.switchBackgroundToggled};

        ${SwitchIcon} {
          svg {
            fill: ${theme.colors.switchIconFillToggled};
            color: ${theme.colors.switchIconColorToggled};
          }
        }
      `}

      ${disabled &&
      css`
        pointer-events: none;
        background-color: ${theme.colors.switchBackgroundDisabled} !important;

        ${SwitchIcon} {
          ${checked
            ? css`
                svg {
                  fill: ${theme.colors.switchIconFillDisabledToggled};
                  color: ${theme.colors
                    .switchIconColorDisabledToggled} !important;
                }
              `
            : css`
                svg {
                  color: ${theme.colors.switchIconDisabledColor} !important;
                }
              `}
        }
      `}
      
      &:hover {
        ${BlobOverlay} {
          background-color: ${theme.colors.switchOverlayBackgroundHover};
        }
      }

      &:active {
        ${BlobOverlay} {
          background-color: ${theme.colors.switchOverlayBackgroundActive};
        }

        ${SwitchIcon} {
          svg {
            fill: ${theme.colors.switchIconFillActive};
            stroke: ${theme.colors.switchIconStrokeActive};
          }
        }
      }
    `;
  }};
`;

const SwitchText = styled(Text)``;

const SwitchSubtitle = styled(Text)``;

const SwitchTextBox = styled(Box)<StyledSwitchProps>`
  ${(props) => {
    const { theme, checked, disabled } = props;

    return css`
      ${checked &&
      css`
        ${SwitchIcon} {
          svg {
            fill: ${theme.colors.switchIconFillToggled};
            color: ${theme.colors.switchIconColorToggled};
          }
        }

        ${SwitchText} {
          color: ${theme.colors.switchLabelToggled};
        }

        ${SwitchSubtitle} {
          color: ${theme.colors.switchLabelToggled};
        }
      `}

      ${disabled &&
      css`
        pointer-events: none;

        ${SwitchIcon} {
          ${checked
            ? css`
                svg {
                  fill: ${theme.colors.switchIconFillDisabledToggled};
                  color: ${theme.colors
                    .switchIconColorDisabledToggled} !important;
                }
              `
            : css`
                svg {
                  color: ${theme.colors.switchIconDisabledColor} !important;
                }
              `}
        }

        ${SwitchText} {
          color: ${theme.colors.switchLabelDisabled};
        }

        ${SwitchSubtitle} {
          color: ${theme.colors.switchLabelDisabled};
        }
      `}
    `;
  }}
`;

export const Styled = {
  BlobOverlay,
  Switch,
  SwitchContainer,
  SwitchIcon,
  SwitchText,
  SwitchTextBox,
  SwitchSubtitle,
};
