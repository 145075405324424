import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { StyledIconButtonProps } from './icon-button.types';

const SolidIconButton = styled.button<StyledIconButtonProps>`
  ${(props) => {
    const { isLoading, isActive, theme } = props;

    return css`
      ${baseComponentStyles};

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      height: 40px;
      min-height: 40px;
      width: 40px;
      min-width: 40px;
      background-color: ${theme.colors.solidIconButtonBackground};
      color: ${theme.colors.solidIconButtonIcon};
      border: none;
      border-radius: 50%;
      cursor: pointer;

      ${isActive &&
      css`
        background-color: ${theme.colors.solidIconButtonBackgroundActive};
        color: ${theme.colors.solidIconButtonIconActive};
      `}

      &:disabled {
        cursor: auto;
        pointer-events: none;
        color: ${theme.colors.solidIconButtonIconDisabled};
        background-color: ${theme.colors.solidIconButtonBackgroundDisabled};
      }

      &:active {
        transform: scale(0.96);
        background-color: ${theme.colors.solidIconButtonBackgroundActive};
        color: ${theme.colors.solidIconButtonIconActive};
      }

      &:hover {
        background-color: ${theme.colors.solidIconButtonBackgroundHover};
        color: ${theme.colors.solidIconButtonIconHover};
      }

      ${isLoading &&
      css`
        pointer-events: none;
      `}
    `;
  }}
`;

const SuccessIconButton = styled.button<StyledIconButtonProps>`
  ${(props) => {
    const { theme, disabled, isActive, isLoading, iconSize, backgroundSize } =
      props;

    return css`
      ${baseComponentStyles};

      color: ${theme.colors.iconButtonSuccessIcon};
      border: ${theme.colors.iconButtonSuccessBorder};
      background-color: ${theme.colors.iconButtonSuccessBackground};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: ${iconSize}px;
      min-height: ${iconSize}px;
      width: ${iconSize}px;
      min-width: ${iconSize}px;

      &::before {
        height: ${backgroundSize}px;
        min-height: ${backgroundSize}px;
        width: ${backgroundSize}px;
        min-width: ${backgroundSize}px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        content: '';
        border: 1px solid ${theme.colors.iconButtonSuccessBorder};
        background-color: ${theme.colors.iconButtonSuccessBackground};
      }

      &:disabled {
        cursor: auto;
        pointer-events: none;
        color: ${theme.colors.iconButtonSuccessIconDisabled};

        &::before {
          background-color: ${theme.colors.iconButtonSuccessBackgroundDisabled};
          border-color: ${theme.colors.iconButtonSuccessBorderDisabled};
        }
      }

      ${!disabled &&
      css`
        cursor: pointer;

        &:hover {
          color: ${theme.colors.iconButtonSuccessIconHover};

          &::before {
            background-color: ${theme.colors.iconButtonSuccessBackgroundHover};
            border-color: ${theme.colors.iconButtonSuccessBorderHover};
          }
        }

        &:active {
          transform: scale(0.96);

          color: ${theme.colors.iconButtonSuccessIconActive};

          &::before {
            background-color: ${theme.colors.iconButtonSuccessBackgroundActive};
            border-color: ${theme.colors.iconButtonSuccessBorderActive};
          }
        }

        ${isActive &&
        css`
          color: ${theme.colors.iconButtonSuccessIconToggled};

          &::before {
            background-color: ${theme.colors
              .iconButtonSuccessBackgroundToggled};
            border-color: ${theme.colors.iconButtonSuccessBorderToggled};
          }
        `}
      `}

      ${isLoading &&
      css`
        pointer-events: none;
      `}
    `;
  }}
`;

const DangerIconButton = styled.button<StyledIconButtonProps>`
  ${(props) => {
    const { theme, disabled, isActive, isLoading, iconSize, backgroundSize } =
      props;

    const iconButtonBaseStyles = css``;

    return css`
      ${iconButtonBaseStyles};
      ${baseComponentStyles};

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      height: ${iconSize}px;
      min-height: ${iconSize}px;
      width: ${iconSize}px;
      min-width: ${iconSize}px;
      border: 1px solid ${theme.colors.iconButtonDangerBorder};
      background-color: ${theme.colors.iconButtonDangerBackground};

      ${isLoading &&
      css`
        pointer-events: none;
      `}

      color: ${theme.colors.iconButtonDangerIcon};

      &::before {
        height: ${backgroundSize}px;
        min-height: ${backgroundSize}px;
        width: ${backgroundSize}px;
        min-width: ${backgroundSize}px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        content: '';
        border: 1px solid ${theme.colors.iconButtonDangerBorder};
        background-color: ${theme.colors.iconButtonDangerBackground};
      }

      &:disabled {
        cursor: auto;
        pointer-events: none;
        color: ${theme.colors.iconButtonDangerIconDisabled};

        &::before {
          background-color: ${theme.colors.iconButtonDangerBackgroundDisabled};
          border-color: ${theme.colors.iconButtonDangerBorderDisabled};
        }
      }

      ${!disabled &&
      css`
        cursor: pointer;

        &:hover {
          color: ${theme.colors.iconButtonDangerIconHover};

          &::before {
            background-color: ${theme.colors.iconButtonDangerBackgroundHover};
            border-color: ${theme.colors.iconButtonDangerBorderHover};
          }
        }

        &:active {
          transform: scale(0.96);

          color: ${theme.colors.iconButtonDangerIconActive};

          &::before {
            background-color: ${theme.colors.iconButtonDangerBackgroundActive};
            border-color: ${theme.colors.iconButtonDangerBorderActive};
          }
        }

        ${isActive &&
        css`
          color: ${theme.colors.iconButtonDangerIconToggled};

          &::before {
            background-color: ${theme.colors.iconButtonDangerBackgroundToggled};
            border-color: ${theme.colors.iconButtonDangerBorderToggled};
          }
        `}
      `}
    `;
  }}
`;

const OnDarkIconButton = styled.button<StyledIconButtonProps>`
  ${(props) => {
    const { theme, disabled, isActive, isLoading, iconSize, backgroundSize } =
      props;

    const iconButtonBaseStyles = css``;

    return css`
      ${iconButtonBaseStyles};
      ${baseComponentStyles};

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      height: ${iconSize}px;
      min-height: ${iconSize}px;
      width: ${iconSize}px;
      min-width: ${iconSize}px;
      border: 1px solid ${theme.colors.iconButtonOnDarkBorder};
      background-color: ${theme.colors.iconButtonOnDarkBackground};

      ${isLoading &&
      css`
        pointer-events: none;
      `}

      color: ${theme.colors.iconButtonOnDarkIcon};

      &::before {
        height: ${backgroundSize}px;
        min-height: ${backgroundSize}px;
        width: ${backgroundSize}px;
        min-width: ${backgroundSize}px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        content: '';
        border: 1px solid ${theme.colors.iconButtonOnDarkBorder};
        background-color: ${theme.colors.iconButtonOnDarkBackground};
      }

      &:disabled {
        cursor: auto;
        pointer-events: none;
        color: ${theme.colors.iconButtonOnDarkIconDisabled};

        &::before {
          background-color: ${theme.colors.iconButtonOnDarkBackgroundDisabled};
          border-color: ${theme.colors.iconButtonOnDarkBorderDisabled};
        }
      }

      ${!disabled &&
      css`
        cursor: pointer;

        &:hover {
          color: ${theme.colors.iconButtonOnDarkIconHover};

          &::before {
            background-color: ${theme.colors.iconButtonOnDarkBackgroundHover};
            border-color: ${theme.colors.iconButtonOnDarkBorderHover};
          }
        }

        &:active {
          transform: scale(0.96);

          color: ${theme.colors.iconButtonOnDarkIconActive};

          &::before {
            background-color: ${theme.colors.iconButtonOnDarkBackgroundActive};
            border-color: ${theme.colors.iconButtonOnDarkBorderActive};
          }
        }

        ${isActive &&
        css`
          color: ${theme.colors.iconButtonOnDarkIconToggled};

          &::before {
            background-color: ${theme.colors.iconButtonOnDarkBackgroundToggled};
            border-color: ${theme.colors.iconButtonOnDarkBorderToggled};
          }
        `}
      `}
    `;
  }}
`;

const IconButton = styled.button<StyledIconButtonProps>`
  ${(props) => {
    const { theme, disabled, isActive, isLoading, iconSize, backgroundSize } =
      props;

    return css`
      ${baseComponentStyles};

      color: ${theme.colors.iconButtonIcon};
      border: ${theme.colors.iconButtonBorder};
      background-color: ${theme.colors.iconButtonBackground};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: ${iconSize}px;
      min-height: ${iconSize}px;
      width: ${iconSize}px;
      min-width: ${iconSize}px;

      &::before {
        height: ${backgroundSize}px;
        min-height: ${backgroundSize}px;
        width: ${backgroundSize}px;
        min-width: ${backgroundSize}px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        content: '';
        border: 1px solid ${theme.colors.iconButtonBorder};
        background-color: ${theme.colors.iconButtonBackground};
      }

      &:disabled {
        cursor: auto;
        pointer-events: none;
        color: ${theme.colors.iconButtonIconDisabled};

        &::before {
          background-color: ${theme.colors.iconButtonBackgroundDisabled};
          border-color: ${theme.colors.iconButtonBorderDisabled};
        }
      }

      ${!disabled &&
      css`
        cursor: pointer;

        &:hover {
          color: ${theme.colors.iconButtonIconHover};

          &::before {
            background-color: ${theme.colors.iconButtonBackgroundHover};
            border-color: ${theme.colors.iconButtonBorderHover};
          }
        }

        &:active {
          transform: scale(0.96);

          color: ${theme.colors.iconButtonIconActive};

          &::before {
            background-color: ${theme.colors.iconButtonBackgroundActive};
            border-color: ${theme.colors.iconButtonBorderActive};
          }
        }

        ${isActive &&
        css`
          color: ${theme.colors.iconButtonIconToggled};

          &::before {
            background-color: ${theme.colors.iconButtonBackgroundToggled};
            border-color: ${theme.colors.iconButtonBorderToggled};
          }
        `}
      `}

      ${isLoading &&
      css`
        pointer-events: none;
      `}
    `;
  }}
`;

export const Styled = {
  IconButton,
  SolidIconButton,
  OnDarkIconButton,
  DangerIconButton,
  SuccessIconButton,
};
