import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { GetLinkColorsReturn, LinkProps, LinkVariant } from './link.types';

function getLinkColors(variant: LinkVariant): GetLinkColorsReturn {
  switch (variant) {
    case 'primary':
      return {
        label: 'linkPrimaryLabel',
        background: 'linkPrimaryBackground',
        decoration: 'linkPrimaryDecoration',

        labelDisabled: 'linkPrimaryLabelDisabled',
        backgroundDisabled: 'linkPrimaryBackgroundDisabled',
        decorationDisabled: 'linkPrimaryDecorationDisabled',
        disabledOpacity: 'linkPrimaryDisabledOpacity',

        labelHover: 'linkPrimaryLabelHover',
        backgroundHover: 'linkPrimaryBackgroundHover',
        decorationHover: 'linkPrimaryDecorationHover',

        labelActive: 'linkPrimaryLabelActive',
        backgroundActive: 'linkPrimaryBackgroundActive',
        decorationActive: 'linkPrimaryDecorationActive',

        labelVisited: 'linkPrimaryLabelVisited',
        backgroundVisited: 'linkPrimaryBackgroundVisited',
        decorationVisited: 'linkPrimaryDecorationVisited',
      };

    case 'secondary':
      return {
        label: 'linkSecondaryLabel',
        background: 'linkSecondaryBackground',
        decoration: 'linkSecondaryDecoration',

        labelDisabled: 'linkSecondaryLabelDisabled',
        backgroundDisabled: 'linkSecondaryBackgroundDisabled',
        decorationDisabled: 'linkSecondaryDecorationDisabled',
        disabledOpacity: 'linkSecondaryDisabledOpacity',

        labelHover: 'linkSecondaryLabelHover',
        backgroundHover: 'linkSecondaryBackgroundHover',
        decorationHover: 'linkSecondaryDecorationHover',

        labelActive: 'linkSecondaryLabelActive',
        backgroundActive: 'linkSecondaryBackgroundActive',
        decorationActive: 'linkSecondaryDecorationActive',

        labelVisited: 'linkSecondaryLabelVisited',
        backgroundVisited: 'linkSecondaryBackgroundVisited',
        decorationVisited: 'linkSecondaryDecorationVisited',
      };

    case 'tertiary':
      return {
        label: 'linkTertiaryLabel',
        background: 'linkTertiaryBackground',
        decoration: 'linkTertiaryDecoration',

        labelDisabled: 'linkTertiaryLabelDisabled',
        backgroundDisabled: 'linkTertiaryBackgroundDisabled',
        decorationDisabled: 'linkTertiaryDecorationDisabled',
        disabledOpacity: 'linkTertiaryDisabledOpacity',

        labelHover: 'linkTertiaryLabelHover',
        backgroundHover: 'linkTertiaryBackgroundHover',
        decorationHover: 'linkTertiaryDecorationHover',

        labelActive: 'linkTertiaryLabelActive',
        backgroundActive: 'linkTertiaryBackgroundActive',
        decorationActive: 'linkTertiaryDecorationActive',

        labelVisited: 'linkTertiaryLabelVisited',
        backgroundVisited: 'linkTertiaryBackgroundVisited',
        decorationVisited: 'linkTertiaryDecorationVisited',
      };

    case 'quaternary':
      return {
        label: 'linkQuaternaryLabel',
        background: 'linkQuaternaryBackground',
        decoration: 'linkQuaternaryDecoration',

        labelDisabled: 'linkQuaternaryLabelDisabled',
        backgroundDisabled: 'linkQuaternaryBackgroundDisabled',
        decorationDisabled: 'linkQuaternaryDecorationDisabled',
        disabledOpacity: 'linkQuaternaryDisabledOpacity',

        labelHover: 'linkQuaternaryLabelHover',
        backgroundHover: 'linkQuaternaryBackgroundHover',
        decorationHover: 'linkQuaternaryDecorationHover',

        labelActive: 'linkQuaternaryLabelActive',
        backgroundActive: 'linkQuaternaryBackgroundActive',
        decorationActive: 'linkQuaternaryDecorationActive',

        labelVisited: 'linkQuaternaryLabelVisited',
        backgroundVisited: 'linkQuaternaryBackgroundVisited',
        decorationVisited: 'linkQuaternaryDecorationVisited',
      };
  }
}

const linkStyles = css<LinkProps>`
  ${(props) => {
    const { disabled, theme, variant = 'primary' } = props;

    const linkColors = getLinkColors(variant);

    if (disabled) {
      return css`
        display: flex;
        gap: 4px;
        align-items: center;
        text-decoration: underline;
        pointer-events: none;
        color: ${theme.colors[linkColors.labelDisabled]};
        background-color: ${theme.colors[linkColors.backgroundDisabled]};
        text-decoration-color: ${theme.colors[linkColors.decorationDisabled]};
        opacity: ${theme.colors[linkColors.disabledOpacity]};
        width: inherit;
      `;
    }

    return css`
      color: ${theme.colors[linkColors.label]};
      background-color: ${theme.colors[linkColors.background]};
      text-decoration-color: ${theme.colors[linkColors.decoration]};
      cursor: pointer;
      display: flex;
      gap: 4px;
      width: inherit;
      align-items: center;
      text-decoration: underline;

      &:visited {
        * {
          color: ${theme.colors[linkColors.labelVisited]};
        }

        background-color: ${theme.colors[linkColors.backgroundVisited]};
        text-decoration-color: ${theme.colors[linkColors.decorationVisited]};
      }

      &:hover {
        * {
          color: ${theme.colors[linkColors.labelHover]};
        }

        background-color: ${theme.colors[linkColors.backgroundHover]};
        text-decoration-color: ${theme.colors[linkColors.decorationHover]};
      }

      &:active {
        * {
          color: ${theme.colors[linkColors.labelActive]};
        }

        background-color: ${theme.colors[linkColors.backgroundActive]};
        text-decoration-color: ${theme.colors[linkColors.decorationActive]};
      }
    `;
  }}
`;

const HrefLink = styled('a')<LinkProps>`
  ${linkStyles};
`;

const ToLink = styled(Link)<LinkProps>`
  ${linkStyles};
`;

const PlainHrefLink = styled('a')`
  text-decoration: none;
  color: inherit;
`;

const PlainToLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const Styled = {
  HrefLink,
  ToLink,
  PlainHrefLink,
  PlainToLink,
};
