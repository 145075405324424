import { joiResolver } from '@hookform/resolvers/joi';
import {
  BreadcrumbsToolbar,
  Button,
  ContentContainer,
  InnerContentContainerAlt,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  SolidIconButton,
  Tooltip,
  flattenFieldErrorsObject,
  getFileFromUrl,
  useFetch,
} from '@orbiapp/components';
import React from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { NavBlocker, PublishButton } from '../../../../components';
import { usePauseOffer } from '../../../../helpers';
import { UpdateOfferForm, UpdateOfferValidation } from '../../../../models';
import { Logger } from '../../../../services';
import {
  CountriesSelector,
  LocationsSelector,
  OfferCategoriesSelector,
  OfferSelector,
  UpdateOfferSelector,
  getCountriesThunk,
  getLocationsThunk,
  getOfferCategoriesThunk,
  getOfferThunk,
  globalUiStateActions,
  offersActions,
  setAlert,
  updateOfferThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { OfferFormHeader } from '../components';

function DuplicateOfferButton() {
  const offerKey = useSelector(OfferSelector.selectOfferKey);

  return (
    <Tooltip placement="bottom" titleTx="label.tooltip.duplicate">
      <SolidIconButton
        icon="document-duplicate-outline"
        to={`/offers/duplicate-offer/${offerKey}`}
      />
    </Tooltip>
  );
}

function ViewOfferContent(props: { defaultValues: UpdateOfferForm }) {
  const { defaultValues } = props;

  const endDate = useSelector(OfferSelector.selectEndDate);
  const startDate = useSelector(OfferSelector.selectStartDate);
  const disabledAt = useSelector(OfferSelector.selectDisabledAt);
  const offerKey = useSelector(OfferSelector.selectOfferKey);
  const updateOfferStatus = useSelector(UpdateOfferSelector.selectStatus);
  const editMeta = useSelector(OfferSelector.selectEditMeta);
  const hasBeenPublished = useSelector(OfferSelector.selectHasBeenPublished);

  const dispatch = useDispatch();

  const pauseOffer = usePauseOffer({
    endDate,
    offerKey,
    startDate,
  });

  const formMethods = useForm<UpdateOfferForm>({
    defaultValues,
    resolver: joiResolver(UpdateOfferValidation),
  });

  const updateOffer = async (updateOfferForm: UpdateOfferForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(updateOfferThunk(updateOfferForm));
    if (res.meta.requestStatus === 'fulfilled') {
      formMethods.reset(updateOfferForm);
    }
  };

  const handleError = (err: FieldErrors<UpdateOfferForm>) => {
    Logger.warning('updateOffer Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  const saveOffer = formMethods.handleSubmit(updateOffer, handleError);

  return (
    <FormProvider {...formMethods}>
      <NavBlocker shouldBlock={formMethods.formState.isDirty} />

      <OfferFormHeader editMeta={editMeta}>
        {!pauseOffer.hidden && (
          <Tooltip
            placement="bottom"
            titleTx={
              disabledAt ? 'label.tooltip.resume' : 'label.tooltip.pause'
            }
          >
            <SolidIconButton
              isLoading={pauseOffer.isLoading}
              onClick={pauseOffer.toggleIsDisabled}
              icon={disabledAt ? 'play-outline' : 'pause-outline'}
            />
          </Tooltip>
        )}

        <DuplicateOfferButton />

        {hasBeenPublished ? (
          <Button
            variant="primary"
            tx="button.save"
            onClick={saveOffer}
            isLoading={updateOfferStatus === 'pending'}
          />
        ) : (
          <PublishButton
            onSubmit={updateOffer}
            onError={handleError}
            isLoading={updateOfferStatus === 'pending'}
            includeTimePicker
          />
        )}
      </OfferFormHeader>

      <Outlet />
    </FormProvider>
  );
}

function useDefaultValues() {
  const offer = useSelector(OfferSelector.selectData);

  const navigate = useNavigate();

  const getDefaultValues =
    React.useCallback(async (): Promise<UpdateOfferForm | null> => {
      if (!offer) {
        return null;
      }

      const coverImage = await getFileFromUrl(offer.coverImage.original.url);
      if (!coverImage) {
        Logger.log('Offer cover image not found', { offerKey: offer.offerKey });
        navigate('/not-found');
        return null;
      }

      let consumeWaitMinutes: number | null = null;
      let files: UpdateOfferForm['files'] = null;
      let link: string | null = null;
      let code: string | null = null;
      let isConsumable = false;

      if (offer.type === 'online') {
        link = offer.link;

        if (offer.code) {
          if (offer.code.type === 'many') {
            files = offer.code.files;
          } else {
            code = offer.code.code;
          }
        }
      }

      if (offer.type === 'consumable') {
        consumeWaitMinutes = offer.consumeWaitMinutes;
        isConsumable = true;
      }

      const updateOfferForm: UpdateOfferForm = {
        code,
        consumeWaitMinutes,
        contactEmail: offer.contactEmail,
        contactName: offer.contactName,
        coverImage,
        description: offer.description,
        endDate: offer.endDate,
        files,
        isConsumable,
        link,
        locations: offer.locations ?? [],
        offerCategoryKey: offer.offerCategory.offerCategoryKey,
        requireStudentVerification: offer.requireStudentVerification,
        startDate: offer.startDate,
        title: offer.title,
        countryKeys: offer.countryKeys,
      };

      return updateOfferForm;
    }, [offer, navigate]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

export function ViewOffer() {
  const { offerKey } = useParams<{ offerKey: string }>();

  const dispatch = useDispatch();

  const getOfferError = useSelector(OfferSelector.selectError);
  const getOfferStatus = useSelector(OfferSelector.selectStatus);
  const offerCategoriesStatus = useSelector(
    OfferCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(LocationsSelector.selectStatus);
  const countriesStatus = useSelector(CountriesSelector.selectStatus);

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    dispatch(getOfferCategoriesThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (!offerKey) return;

    dispatch(getOfferThunk(offerKey));
    dispatch(getLocationsThunk());
    dispatch(getCountriesThunk());

    return () => {
      dispatch(offersActions.resetOffer());
    };
  }, [offerKey, dispatch]);

  if (getOfferError) {
    return <Navigate to="/offers" />;
  }

  if (
    !offerKey ||
    !defaultValues ||
    isAnyPending(
      getOfferStatus,
      offerCategoriesStatus,
      locationsStatus,
      countriesStatus,
    )
  ) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar
        flexGrow={0.7}
        tabButtonListItems={[
          {
            to: `/offers/${offerKey}`,
            tx: 'label.breadcrumbs.offers.offer',
          },
          {
            to: `/offers/${offerKey}/stats`,
            tx: 'label.breadcrumbs.offers.stats',
          },
        ]}
        breadcrumbListItems={[
          {
            to: '/offers',
            tx: 'label.breadcrumbs.offers.offers',
          },
          {
            to: `/offers/${offerKey}`,
            text: defaultValues.title ?? '',
            truncate: true,
          },
        ]}
      />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainerAlt>
            <ViewOfferContent defaultValues={defaultValues} />
          </InnerContentContainerAlt>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
