import styled, { css } from 'styled-components';

import { Box } from '../box';

const Divider = styled(Box)``;

const DividerSegment = styled.div`
  ${(props) => {
    const { theme } = props;

    return css`
      height: 1px;
      width: 100%;
      background-color: ${theme.colors['dividerSegment']};
    `;
  }}
`;

export const Styled = { Divider, DividerSegment };
