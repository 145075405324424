import styled, { css } from 'styled-components';

import { StyledTableRowProps } from './table-row.types';

const TableRow = styled.tr<StyledTableRowProps>`
  ${(props) => {
    const { highlight, onClick, theme, to, blur } = props;

    if (blur) {
      return css`
        filter: blur(5px);
        pointer-events: none;

        &:nth-child(even) {
          background-color: ${theme.colors.tableRowEvenBackground};
        }

        &:nth-child(odd) {
          background-color: ${theme.colors.tableRowOddBackground};
        }
      `;
    }

    if (highlight) {
      return css`
        background-color: ${theme.colors.tableRowBackgroundActive};

        &:hover {
          .table-cell-content {
            opacity: 1;
          }
        }
      `;
    }

    if (!onClick && !to) {
      return css`
        &:nth-child(even) {
          background-color: ${theme.colors.tableRowEvenBackground};
        }

        &:nth-child(odd) {
          background-color: ${theme.colors.tableRowOddBackground};
        }

        &:hover {
          .table-cell-content {
            opacity: 1;
          }
        }
      `;
    }

    return css`
      cursor: pointer;

      &:hover {
        .table-cell-content {
          opacity: 1;
        }
      }

      &:nth-child(even) {
        background-color: ${theme.colors.tableRowEvenBackground};

        &:hover {
          background-color: ${theme.colors.tableRowBackgroundHover};
        }
      }

      &:nth-child(odd) {
        background-color: ${theme.colors.tableRowOddBackground};

        &:hover {
          background-color: ${theme.colors.tableRowBackgroundHover};
        }
      }
    `;
  }}
`;

export const Styled = { TableRow };
