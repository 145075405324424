import { PublishButtonMenu, PublishButtonMenuProps } from '@orbiapp/components';

import { Analytics } from '../../services';

export function PublishButton(
  props: Pick<
    PublishButtonMenuProps,
    'isLoading' | 'onSubmit' | 'onError' | 'includeTimePicker'
  >,
) {
  const { isLoading, includeTimePicker, onSubmit, onError } = props;

  return (
    <PublishButtonMenu
      onEvent={Analytics.track}
      publishTx="button.publish"
      cancelTx="button.cancel"
      customTx="label.offer-form.pick-date-and-time"
      publishNowTx="label.offer-form.publish-now"
      onSubmit={onSubmit}
      onError={onError}
      isLoading={isLoading}
      includeTimePicker={includeTimePicker}
    />
  );
}
