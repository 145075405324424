export function getListHeight(
  itemCount: number,
  itemHeight: number,
  maxHeight: number,
) {
  const acutalHeight = itemCount * itemHeight;

  if (acutalHeight > maxHeight) {
    return maxHeight;
  }

  return acutalHeight;
}
