import { Banner, LAYOUT_Z_INDEX } from '@orbiapp/components';
import React from 'react';

import { useOrbiBusinessCalendar } from '../../helpers';
import {
  CompanyProfilesSelector,
  CompanyVisibilityFilterSelector,
  useSelector,
} from '../../store';

export function VisibilityFilterBanner() {
  const hasProfiles = useSelector(CompanyProfilesSelector.selectHasProfiles);

  const companyProfilesStatus = useSelector(
    CompanyProfilesSelector.selectStatus,
  );

  const companyIsRestricted = useSelector(
    CompanyVisibilityFilterSelector.companyIsRestricted,
  );

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const [hasDismissed, setHasDismissed] = React.useState(false);

  if (
    !hasProfiles ||
    hasDismissed ||
    companyProfilesStatus === 'pending' ||
    !companyIsRestricted
  ) {
    return null;
  }

  const handleDismiss = () => {
    setHasDismissed(true);
  };

  return (
    <Banner
      onClose={handleDismiss}
      titleTx="label.visibility-filter-banner.title"
      variant="warning"
      zIndex={LAYOUT_Z_INDEX.pageToolbar}
      linkTo={orbiBusinessCalendarUrl}
      linkTx="label.visibility-filter-banner.link"
    />
  );
}
