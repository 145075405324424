import Joi from 'joi';

import { EmailSchema } from '../email';
import { PhoneSchema } from '../phone';
import { UrlSchema } from '../url';
import { CompanyProfileForm } from './company-profile.model';
import { CompanyProfileSchema } from './company-profile.schema';
import { CompanyProfileErrors } from './company-profile.errors';

const validYoutubeUrls = ['youtube.com/watch', 'youtu.be'];

const isTrue = Joi.string().valid(true).required();

export const CompanyProfilFormValidation = Joi.object<CompanyProfileForm>({
  draft: Joi.boolean().valid(true, false).required(),

  about: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.about.allow(null),
    otherwise: CompanyProfileSchema.about,
  }),

  contactEmail: Joi.when('draft', {
    is: isTrue,
    then: EmailSchema.allow(null),
    otherwise: EmailSchema,
  }),

  contactPhone: PhoneSchema.allow(null),

  coverImageBase64: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.coverImage.allow(null),
    otherwise: CompanyProfileSchema.coverImage,
  }),

  employmentTypes: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.employmentTypes.allow(null),
    otherwise: CompanyProfileSchema.employmentTypes,
  }),

  funFacts: CompanyProfileSchema.funFacts.allow(null),

  name: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.name.allow(null),
    otherwise: CompanyProfileSchema.name,
  }),

  oneliner: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.oneliner.allow(null),
    otherwise: CompanyProfileSchema.oneliner,
  }),

  perks: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.perks.allow(null),
    otherwise: CompanyProfileSchema.perks,
  }),

  subjectAreaKeys: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.subjectAreaKeys.allow(null),
    otherwise: CompanyProfileSchema.subjectAreaKeys,
  }),

  videoUrl: UrlSchema.custom((value, helpers) => {
    const isValidYoutubeUrl = validYoutubeUrls.some((url) => value.includes(url));

    if (!isValidYoutubeUrl) {
      return helpers.error('string.pattern.youtube')
    }

    return value;
  }).allow(null).messages(CompanyProfileErrors.videoUrl),

  websiteUrl: Joi.when('draft', {
    is: isTrue,
    then: UrlSchema.allow(null),
    otherwise: UrlSchema,
  }),

  locationData: Joi.when('draft', {
    is: isTrue,
    then: CompanyProfileSchema.locationData.allow(null),
    otherwise: CompanyProfileSchema.locationData,
  }),
});
