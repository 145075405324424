import {
  GeneralApiResult,
  GeneralApiResultWithData,
  RequestVerifyEmailParams,
} from '@orbiapp/components';

import { AUTH_URL } from '../../../../../config';
import { RequestResetPasswordEmailErrorResponse } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce, apisauceAuth } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';

export const createResetPassword = async (params: {
  redirectUrl: string;
  email: string;
}): Promise<GeneralApiResult<RequestResetPasswordEmailErrorResponse>> => {
  const res = await apisauce.post<
    undefined,
    RequestResetPasswordEmailErrorResponse
  >('/v1/reset-password', params, { baseURL: AUTH_URL });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const sendEmailVerification = async (
  params: RequestVerifyEmailParams,
): Promise<GeneralApiResult> => {
  const res = await apisauce.post<undefined, any>(
    '/v1/verify-email-address',
    params,
    {
      baseURL: AUTH_URL,
    },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getCustomSignInToken = async (): Promise<
  GeneralApiResultWithData<string>
> => {
  const res = await apisauceAuth.get<{ token: string }, any>(
    '/v1/token',
    undefined,
    { baseURL: AUTH_URL },
  );

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data.token };
  } catch (err) {
    Logger.error('getCustomSignInToken', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
