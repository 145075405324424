import React from 'react';

import { useCombinedRefs } from '../../helpers';
import { isNumber } from '../../utils';
import { Box } from '../box';
import { Chip } from '../chip';
import { Icon } from '../icon';
import { InputBox } from '../input-box';
import { InputElementBox } from '../input-element-box/input-element-box';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import { Text } from '../text';
import {
  INPUT_CHIPS_BORDER_RADIUS,
  INPUT_CHIPS_X_PADDING,
  INPUT_CHIPS_Y_PADDING,
} from './input-chips.constants';
import { Styled } from './input-chips.styled';
import { InputChip, InputChipsProps } from './input-chips.types';

function useInputChips<
  T extends string | number,
  K extends InputChip<T>,
>(props: {
  chips: K[];
  disabled?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  maxChips?: InputChipsProps<T, K>['maxChips'];
  maxLength?: InputChipsProps<T, K>['maxLength'];
  onAddChip: InputChipsProps<T, K>['onAddChip'];
  onBlur?: InputChipsProps<T, K>['onBlur'];
  onChange?: InputChipsProps<T, K>['onChange'];
  onRemoveChip?: InputChipsProps<T, K>['onRemoveChip'];
  onFocus?: InputChipsProps<T, K>['onFocus'];
}) {
  const {
    chips,
    inputRef,
    disabled,
    maxChips,
    maxLength,
    onAddChip,
    onBlur,
    onChange,
    onRemoveChip,
    onFocus,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const handleInputBoxClick = () => {
    inputRef.current?.removeAttribute('hidden');
    inputRef.current?.focus();
    setIsFocused(true);
  };

  const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur?.(e);
    setIsFocused(false);
  };

  const handleInputFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus?.(e);
    setIsFocused(true);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target;

    if (maxLength && target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    if (isNumber(maxChips) && chips.length > maxChips) {
      return;
    }

    if (onAddChip && target.value.endsWith(',') && target.value.length > 1) {
      target.value = target.value.slice(0, -1);

      onAddChip(target.value);
      return;
    }

    onChange?.(e);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;

    if (e.key === 'Backspace' && target.value === '' && chips.length > 0) {
      onRemoveChip?.(chips[chips.length - 1].id);
      return;
    }

    if (isNumber(maxChips) && chips.length > maxChips) {
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      if (target.value !== '') {
        onAddChip?.(target.value);
      }
    }
  };

  const renderChip = (chip: K, index: number) => {
    const { id, ...rest } = chip;

    const handleRemove: React.MouseEventHandler<HTMLElement> = (e) => {
      e.stopPropagation();

      if (chips.length === 3) {
        inputRef.current?.removeAttribute('hidden');
      }

      onRemoveChip?.(id);
    };

    return (
      <Chip
        variant={10}
        flex
        flexJustify="between"
        key={`input-chip-${index}`}
        overflow="hidden"
        disabled={disabled}
        flexAlign="center"
      >
        <Text
          as="span"
          textOverflow="ellipsis"
          variant="bodySm"
          flexGrow={1}
          overflow="hidden"
          maxWidth="15ch"
          whiteSpace="nowrap"
          lineHeight="unset"
          {...rest}
        />

        {onRemoveChip && !disabled && (
          <Icon
            cursor="pointer"
            name="x-mark"
            onClick={handleRemove}
            size={20}
          />
        )}
      </Chip>
    );
  };

  return {
    isFocused,
    handleInputBlur,
    handleInputFocus,
    handleInputBoxClick,
    handleInputChange,
    handleKeyDown,
    renderChip,
  };
}

function _InputChips<T extends string | number, K extends InputChip<T>>(
  props: InputChipsProps<T, K>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    chips,
    defaultValue,
    disabled,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    label,
    labelTx,
    labelTxArgs,
    maxChips,
    maxLength,
    onAddChip,
    onBlur,
    onChange,
    onFocus,
    onRemoveChip,
    showInput,
    value,
    leadingElements,
    trailingElements,
    menuElement,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);

  const combinedRefs = useCombinedRefs(ref, inputRef);

  const {
    isFocused,
    handleInputBlur,
    handleInputFocus,
    handleInputBoxClick,
    handleInputChange,
    handleKeyDown,
    renderChip,
  } = useInputChips({
    chips,
    disabled,
    inputRef,
    maxChips,
    maxLength,
    onAddChip,
    onBlur,
    onFocus,
    onChange,
    onRemoveChip,
  });

  const floatLabel = isFocused || chips.length > 0 || !!value || !!defaultValue;
  const hasError = !!error || !!errorTx;

  return (
    <Box relative>
      <InputBox
        onClick={disabled ? undefined : handleInputBoxClick}
        minHeight={72.8}
        height={undefined}
        floatLabel={floatLabel}
        disabled={disabled}
        hasError={hasError}
        isFocused={isFocused}
        pb={INPUT_CHIPS_Y_PADDING / 2}
        pt={INPUT_CHIPS_Y_PADDING}
        px={INPUT_CHIPS_X_PADDING}
        r={INPUT_CHIPS_BORDER_RADIUS}
        focusTop={14}
      >
        <InputElementBox items={leadingElements} />

        <Styled.ChipsInputBox showInput={showInput} flexGrow={1} relative>
          <InputLabel
            ref={labelRef}
            text={label}
            tx={labelTx}
            txArgs={labelTxArgs}
            disabled={disabled}
            mb={4}
          />

          <Box flexGrow={1} pb={4} pt={8} gap={4} pr={16} flex flexWrap>
            <Box flex flexWrap width="100%" gap={8} pt={16}>
              {chips.map(renderChip)}

              {(!isNumber(maxChips) || chips.length < maxChips) && (
                <input
                  defaultValue={defaultValue}
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleKeyDown}
                  ref={combinedRefs}
                  type="text"
                  value={value}
                  {...rest}
                />
              )}
            </Box>
          </Box>
        </Styled.ChipsInputBox>

        <InputElementBox items={trailingElements} />

        {menuElement}
      </InputBox>

      <InputHelper
        currentLength={value?.toString().length}
        disabled={disabled}
        hasError={hasError}
        helperText={error || helperText}
        helperTx={errorTx || helperTx}
        helperTxArgs={errorTxArgs || helperTxArgs}
        maxLength={maxLength}
      />
    </Box>
  );
}

export const InputChips = React.forwardRef(_InputChips) as <
  T extends string | number,
  K extends InputChip<T>,
>(
  p: InputChipsProps<T, K> & { ref?: React.Ref<HTMLInputElement> },
) => React.ReactElement;
