import React from 'react';

import {
  FilterContextState,
  StringValues,
  UseFilterSorter,
  useFilter,
} from '../../helpers';

type FilterContextProps<T extends Record<string, any>> = {
  keys: StringValues<T>[];
  items: FilterContextState<T>['items'];
};

export function createFilterContext<T extends Record<string, any>>(options?: {
  sorter?: UseFilterSorter<T>;
}) {
  const FilterContext = React.createContext<FilterContextState<T>>({
    items: [],
    searchString: '',

    handleSearchStringChange: () => {},
    resetSearchString: () => {},
  });

  const Provider = (props: React.PropsWithChildren<FilterContextProps<T>>) => {
    const { children, items, keys } = props;

    const filter = useFilter(items, keys, options?.sorter);

    return (
      <FilterContext.Provider value={filter}>{children}</FilterContext.Provider>
    );
  };

  return { FilterContext, Provider };
}
