import {
  BubbleDataPoint,
  Chart,
  ChartTypeRegistry,
  Point,
  TooltipModel,
} from 'chart.js';
import { createRoot } from 'react-dom/client';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { theme } from '../../../theme';
import {
  numberFormatter,
  removeEmptySpace,
  shouldForwardProp,
} from '../../../utils';
import { Box } from '../../box';
import { Text } from '../../text';
import { Styled } from './line-chart.styled';

function getOrCreateTooltip(
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | Point | [number, number] | BubbleDataPoint | null)[],
    unknown
  >,
) {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = theme.transitions.default;

    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
}

const container = document.createElement('div');
const root = createRoot(container);

function jsxToHtml(jsx: JSX.Element) {
  root.render(
    // @ts-ignore
    <ThemeProvider theme={theme}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        {jsx}
      </StyleSheetManager>
    </ThemeProvider>,
  );
  return container;
}

interface TooltipProps {
  title?: string;
  lines: {
    value: number;
    backgroundColor: string;
  }[];

  comparedLines?: {
    value: number;
    backgroundColor: string;
  }[];
  comparedTitle?: string;
}

function Tooltip(props: TooltipProps) {
  const { title, lines, comparedLines, comparedTitle } = props;

  return (
    <Box flex gap={16} py={8} px={16} r={4} backgroundColor="tooltipBackground">
      <Box gap={4} flex flexDirection="column">
        <Text
          whiteSpace="nowrap"
          color="tooltipLabel"
          variant="bodySmBold"
          text={title}
        />

        {lines.map((line, index) => (
          <Box flex gap={8} key={`tooltip-line-${index}`}>
            <Styled.TooltipIndicatorDot
              width={10}
              height={10}
              r="50%"
              color={line?.backgroundColor}
            />

            <Text
              color="tooltipLabel"
              variant="bodySm"
              text={numberFormatter.format(line.value)}
              whiteSpace="nowrap"
            />
          </Box>
        ))}
      </Box>

      {comparedLines && (
        <Box gap={4} flex flexDirection="column">
          <Text
            color="tooltipLabel"
            variant="bodySmBold"
            whiteSpace="nowrap"
            text={comparedTitle}
          />

          {comparedLines.map((line, index) => (
            <Box flex gap={8} key={`compared-tooltip-line-${index}`}>
              <Styled.TooltipIndicatorDot
                width={10}
                height={10}
                r="50%"
                color={line?.backgroundColor}
              />

              <Text
                color="tooltipLabel"
                variant="bodySm"
                text={numberFormatter.format(line.value)}
                whiteSpace="nowrap"
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export function externalTooltipHandler(
  ctx: {
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >;
    tooltip: TooltipModel<'line'>;
  },
  options: { isComparing: boolean; comparedLabels?: string[] },
) {
  const { chart, tooltip } = ctx;

  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip.body) {
    while (tooltipEl?.firstChild) {
      tooltipEl.firstChild.remove();
    }

    const datasetBackgroundColorMap = new Map(
      chart.config.data.datasets.map((dataset, i) => [
        options.isComparing && i % 2 === 0
          ? `compared_${dataset.label}`
          : dataset.label,
        dataset.backgroundColor as string,
      ]),
    );

    const lines: { value: number; backgroundColor: string }[] = [];
    tooltip.body.forEach((bodyItem, i) => {
      const [label, value] = bodyItem.lines[0].split(':');

      lines.push({
        value: Number(removeEmptySpace(value).replaceAll(',', '')),
        backgroundColor:
          options.comparedLabels && i % 2 === 0
            ? datasetBackgroundColorMap.get(`compared_${label}`)!
            : datasetBackgroundColorMap.get(label)!,
      });
    });

    const dataPoint = tooltip.dataPoints[0];
    let tooltipProps: TooltipProps = {
      lines,
      title: dataPoint.label,
    };
    if (options?.isComparing) {
      tooltipProps.lines = [];
      tooltipProps.comparedLines = [];
      tooltipProps.comparedTitle = options.comparedLabels![dataPoint.dataIndex];

      for (let i = 0; i < lines.length; i += 2) {
        tooltipProps.lines.push(lines[i]);
        tooltipProps.comparedLines.push(lines[i + 1]);
      }
    }

    tooltipEl.appendChild(jsxToHtml(<Tooltip {...tooltipProps} />));
  }

  const tooltipRect = tooltipEl.getBoundingClientRect();

  let top = ctx.tooltip.caretY - tooltipRect.height / 2;

  if (ctx.tooltip.caretX > ctx.chart.width / 2) {
    tooltipEl.style.left = `${
      ctx.tooltip.caretX - 10 - tooltipRect.width / 2
    }px`;
  } else {
    tooltipEl.style.left = `${
      ctx.tooltip.caretX + 10 + tooltipRect.width / 2
    }px`;
  }

  if (top < 0) {
    top = 0;
  }
  if (top > ctx.chart.height - tooltipRect.height) {
    top = ctx.chart.height - tooltipRect.height;
  }

  tooltipEl.style.top = `${top}px`;

  tooltipEl.style.opacity = '1';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
}
