import {
  EmulateUserSessionStorage,
  WorkspacesSessionStorage,
  getUID,
} from '@orbiapp/components';
import { AsyncRequestTransform, AsyncResponseTransform } from 'apisauce';
import { getAuth } from 'firebase/auth';

import { firebase } from '../firebase';
import { Logger } from '../logger';
import { getApiLogExtras } from './api.utils';

export const workspaceTransformer: AsyncRequestTransform = async (req) => {
  req.headers = req.headers ?? {};

  const companyKey = WorkspacesSessionStorage.getWorkspaceKey();

  if (companyKey) {
    req.headers['x-orb-company'] = companyKey;
  }
};

export const emulateUserTransformer: AsyncRequestTransform = async (req) => {
  req.headers = req.headers ?? {};

  const userKey = EmulateUserSessionStorage.getUserKey();

  if (userKey) {
    req.headers['x-emulate-user'] = userKey;
  }
};

export const authTransformer: AsyncRequestTransform = async (req) => {
  const event_id = getUID();

  Logger.addBreadcrumb('info', 'api', 'api call', {
    event_id,
    meta: { url: req.url, headers: req.headers },
  });

  req.headers = req.headers ?? {};

  const token = await getAuth(firebase).currentUser?.getIdToken();

  req.headers['x-orb-trace'] = event_id;

  if (token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }
};

export const loggerTransformer: AsyncResponseTransform = async (res) => {
  if (!res.ok) {
    Logger.warning(res.originalError.message, getApiLogExtras(res));
  }
};
