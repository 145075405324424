import { theme } from '../../../theme';

export const colors = [
  theme.colors['dataVisualisation1'],
  theme.colors['dataVisualisation3'],
  theme.colors['dataVisualisation5'],
  theme.colors['dataVisualisation7'],
  theme.colors['dataVisualisation9'],
  theme.colors['dataVisualisation11'],
  theme.colors['dataVisualisation13'],
  theme.colors['dataVisualisation15'],
];
