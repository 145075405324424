import styled, { css } from 'styled-components';

import { Box } from '../box';

const TimeBox = styled(Box)<{ isSelected: boolean }>`
  ${(props) => {
    const { theme, isSelected } = props;

    return css`
      &:hover {
        background-color: ${theme.colors.listItemBackgroundHover};
      }

      &:active {
        background-color: ${theme.colors.listItemBackgroundActive};
      }

      ${isSelected &&
      css`
        background-color: ${theme.colors.listItemBackgroundActive};
      `}
    `;
  }}
`;

export const Styled = { TimeBox };
