import { Box, BoxProps } from '../box';
import { Text } from '../text';
import { Styled } from './time-picker.styled';

interface TimePickerProps extends Omit<BoxProps, 'value' | 'onChange'> {
  value: Date;
  onChange: (date: Date) => void;
}

const hours = Array.from({ length: 24 });
const minutes = Array.from({ length: 60 });

export function TimePicker(props: TimePickerProps) {
  const { value, onChange, ...rest } = props;

  const currentHour = value.getHours();
  const currentMinute = value.getMinutes();

  const renderHour = (_: any, i: number) => {
    const handleClick = () => {
      const date = new Date(value);
      date.setHours(i);
      onChange(date);
    };

    return (
      <Styled.TimeBox
        onClick={handleClick}
        cursor="pointer"
        py={8}
        px={16}
        key={i}
        isSelected={i === currentHour}
      >
        <Text text={i < 10 ? `0${i}` : i} variant="bodySm" />
      </Styled.TimeBox>
    );
  };

  const renderMinute = (_: any, i: number) => {
    const handleClick = () => {
      const date = new Date(value);
      date.setMinutes(i);
      onChange(date);
    };

    return (
      <Styled.TimeBox
        onClick={handleClick}
        cursor="pointer"
        py={8}
        px={16}
        key={i}
        isSelected={i === currentMinute}
      >
        <Text text={i < 10 ? `0${i}` : i} variant="bodySm" />
      </Styled.TimeBox>
    );
  };

  return (
    <Box flex {...rest}>
      <Box height="100%" overflow="auto" flex flexDirection="column">
        {hours.map(renderHour)}
      </Box>
      <Box height="100%" overflow="auto" flex flexDirection="column">
        {minutes.map(renderMinute)}
      </Box>
    </Box>
  );
}
