import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';

const DropdownButton = styled.button<{ isToggled: boolean }>`
  ${(props) => {
    const { isToggled, theme } = props;

    const { fontSize, letterSpacing, fontFamily, lineHeight } =
      theme.textVariants.buttonSm;

    return css`
      ${baseComponentStyles};

      border-radius: 32px;
      padding: 8px 16px;

      width: fit-content;

      cursor: pointer;
      height: 38px;

      font-size: ${fontSize}px;
      letter-spacing: ${letterSpacing}px;
      line-height: ${lineHeight}px;

      font-family: ${fontFamily};

      background-color: ${theme.colors.dropdownButtonBackground};
      border: 1px solid ${theme.colors.dropdownButtonBorder};
      color: ${theme.colors.dropdownButtonLabel};

      &:hover {
        background-color: ${theme.colors.dropdownButtonBackgroundHover};
        border: 1px solid ${theme.colors.dropdownButtonBorderHover};
        color: ${theme.colors.dropdownButtonLabelHover};
      }

      &:active {
        transform: scale(0.96);

        background-color: ${theme.colors.dropdownButtonBackgroundActive};
        border: 1px solid ${theme.colors.dropdownButtonBorderActive};
        color: ${theme.colors.dropdownButtonLabelActive};
      }

      &:disabled {
        pointer-events: none;
        background-color: ${theme.colors.dropdownButtonBackgroundDisabled};
        border: 1px solid ${theme.colors.dropdownButtonBorderDisabled};
        color: ${theme.colors.dropdownButtonLabelDisabled};
      }

      ${isToggled &&
      css`
        background-color: ${theme.colors.dropdownButtonBackgroundToggled};
        border: 1px solid ${theme.colors.dropdownButtonBorderToggled};
        color: ${theme.colors.dropdownButtonLabelToggled};
      `}
    `;
  }}
`;

export const Styled = { DropdownButton };
