import React from 'react';
import Swiper from 'swiper';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';

import { Box } from '../box';
import { Button } from '../button';
import { SolidIconButton } from '../icon-button';
import { Image } from '../image';
import {
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
} from '../modal';
import { Text } from '../text';
import { Styled } from './image-slider-modal.styled';
import {
  ImageSliderModalProps,
  ImageSliderModalSlide,
} from './image-slider-modal.types';

export function ImageSliderModal(props: ImageSliderModalProps) {
  const {
    slides,

    title,
    titleTx,
    titleTxArgs,

    width,

    isOpen,
    onClose,

    primaryButtonHidden,
    primaryButtonHref,
    primaryButtonOnClick,
    primaryButtonTarget,
    primaryButtonText,
    primaryButtonTo,
    primaryButtonTx,
    primaryButtonTxArgs,
    primaryButtonVariant = 'primary',

    secondaryButtonHidden,
    secondaryButtonHref,
    secondaryButtonOnClick,
    secondaryButtonTarget,
    secondaryButtonText,
    secondaryButtonTo,
    secondaryButtonTx,
    secondaryButtonTxArgs,
    secondaryButtonVariant = 'secondary',

    enableAnimation,
  } = props;

  const swiperRef = React.useRef<Swiper>();

  const prevButtonRef = React.useRef<HTMLButtonElement>(null);
  const nextButtonRef = React.useRef<HTMLButtonElement>(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleNext = () => {
    swiperRef.current?.slideNext();
    setActiveIndex(swiperRef.current?.activeIndex ?? 0);
  };

  const handlePrev = () => {
    swiperRef.current?.slidePrev();
    setActiveIndex(swiperRef.current?.activeIndex ?? 0);
  };

  const slideTo = (index: number) => () => {
    swiperRef.current?.slideTo(index);
    setActiveIndex(swiperRef.current?.activeIndex ?? 0);
  };

  const setSwiper = (swiper: Swiper) => {
    swiperRef.current = swiper;
    setActiveIndex(swiperRef.current?.activeIndex ?? 0);
  };

  const renderSwiperPaginationDot = (_: any, index: number) => {
    return (
      <Box
        key={`pagination-dot-${index}`}
        cursor="pointer"
        r="50%"
        backgroundColor={
          activeIndex === index
            ? 'swiperDotActiveBackground'
            : 'swiperDotBackground'
        }
        onClick={slideTo(index)}
        width={8}
        height={8}
      />
    );
  };

  const swiperPagination = (
    <Box mt={16} gap={16} flex flexJustify="center">
      {slides.map(renderSwiperPaginationDot)}
    </Box>
  );

  const swiperNavigation = (
    <React.Fragment>
      {activeIndex > 0 && (
        <Styled.NavigationButtonBox zIndex={5} top="50%" absolute left={16}>
          <SolidIconButton
            ref={prevButtonRef}
            onClick={handlePrev}
            icon="arrow-left"
          />
        </Styled.NavigationButtonBox>
      )}

      {activeIndex < slides.length - 1 && (
        <Styled.NavigationButtonBox zIndex={5} top="50%" absolute right={16}>
          <SolidIconButton
            ref={nextButtonRef}
            onClick={handleNext}
            icon="arrow-right"
          />
        </Styled.NavigationButtonBox>
      )}
    </React.Fragment>
  );

  const renderImageSliderModalSlide = (
    imageSliderModalSlide: ImageSliderModalSlide,
    index: number,
    slides: ImageSliderModalSlide[],
  ) => (
    <SwiperSlide
      style={{ minWidth: '100%' }}
      key={`image-slider-modal-slide-${index}`}
    >
      {imageSliderModalSlide.type === 'image' ? (
        <Image
          maxWidth="100%"
          src={imageSliderModalSlide.src}
          alt={imageSliderModalSlide.alt}
        />
      ) : (
        <Styled.Video loop autoPlay muted>
          <source src={imageSliderModalSlide.src} type="video/mp4" />
        </Styled.Video>
      )}

      {slides.length > 1 && swiperPagination}

      {imageSliderModalSlide.descriptionElement ? (
        imageSliderModalSlide.descriptionElement
      ) : (
        <Text
          textAlign="center"
          mt={16}
          variant="bodyMd"
          tx={imageSliderModalSlide.descriptionTx}
          text={imageSliderModalSlide.description}
          txArgs={imageSliderModalSlide.descriptionTxArgs}
        />
      )}
    </SwiperSlide>
  );

  const showSecondaryButton =
    !secondaryButtonHidden && (secondaryButtonText || secondaryButtonTx);
  const showPrimaryButton =
    !primaryButtonHidden && (primaryButtonText || primaryButtonTx);
  const showModalFooter = showSecondaryButton || showPrimaryButton;

  return (
    <Modal
      enableAnimation={enableAnimation}
      width={width}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle
            text={title}
            tx={titleTx}
            txArgs={titleTxArgs}
            textAlign="center"
          />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <Styled.ReactSwiperWrapper relative overflow="hidden">
            {swiperNavigation}

            <ReactSwiper
              onBeforeInit={setSwiper}
              onSwiper={setSwiper}
              onSlideChange={setSwiper}
              modules={[Navigation, A11y]}
              navigation={{
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
              }}
            >
              {slides.map(renderImageSliderModalSlide)}
            </ReactSwiper>
          </Styled.ReactSwiperWrapper>
        </ModalBodyContainer>

        {showModalFooter && (
          <ModalFooterContainer flexJustify="center">
            {showSecondaryButton && (
              <Button
                href={secondaryButtonHref}
                onClick={secondaryButtonOnClick}
                target={secondaryButtonTarget}
                text={secondaryButtonText}
                to={secondaryButtonTo}
                tx={secondaryButtonTx}
                txArgs={secondaryButtonTxArgs}
                variant={secondaryButtonVariant}
              />
            )}

            {showPrimaryButton && (
              <Button
                href={primaryButtonHref}
                onClick={primaryButtonOnClick}
                target={primaryButtonTarget}
                text={primaryButtonText}
                to={primaryButtonTo}
                tx={primaryButtonTx}
                txArgs={primaryButtonTxArgs}
                variant={primaryButtonVariant}
              />
            )}
          </ModalFooterContainer>
        )}
      </ModalContentContainer>
    </Modal>
  );
}
