import {
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TextProps,
  translate,
  useClientSort,
} from '@orbiapp/components';
import { useSelector } from 'react-redux';

import { MetricBreakdownTableCell } from '../../../../../components/insights';
import {
  InsightsMetric,
  TableInsightsItems,
  TimeSeriesInsightsItem,
} from '../../../../../models';
import {
  InsightsTimeSeriesItemsSelector,
  InsightsTimeSeriesSelector,
} from '../../../../../store';
import {
  getInsightsMetricTx,
  getProductLabelTx,
  isAnyPending,
} from '../../../../../utils';

const TABLE_COLUMN_WIDTHS = {
  label: 200,
  type: '100%',
  metric: 200,
};

function MetricsBreakdownTableRow(props: {
  label: TextProps['text'];
  type: TimeSeriesInsightsItem['type'];
  value: number;
  comparedValue?: number;
}) {
  const { label, value, type, comparedValue } = props;

  return (
    <TableRow>
      <TableCell width={TABLE_COLUMN_WIDTHS.label} text={label} />

      <TableCell width={TABLE_COLUMN_WIDTHS.type}>
        <Chip variant={2} tx={getProductLabelTx(type)} />
      </TableCell>

      <MetricBreakdownTableCell
        comparedValue={comparedValue}
        value={value}
        width={TABLE_COLUMN_WIDTHS.metric}
      />
    </TableRow>
  );
}

function MetricsBreakdownTable(props: { metric: InsightsMetric }) {
  const { metric } = props;

  const tableEntries = useSelector(
    InsightsTimeSeriesSelector.selectTableEntries,
  );
  const insightsTimeSeriesStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsItemsStatus = useSelector(
    InsightsTimeSeriesItemsSelector.selectStatus,
  );

  const columnNames = {
    label: 'label',
    metric: `values.${metric}`,
  };

  const { items, sortOrder, orderBy, onSort } = useClientSort(tableEntries, {
    defaultOrderBy: columnNames.label,
    defaultSortOrder: 'desc',
  });

  if (isAnyPending(insightsTimeSeriesStatus, insightsItemsStatus)) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.metrics-breakdown.item" />
            <TableHead tx="label.metrics-breakdown.type" />
            <TableHead tx={getInsightsMetricTx(metric)} />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={5}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  const renderTableRow = (item: TableInsightsItems, index: number) => {
    const value = item.values[metric];
    const comparedValue = item.valuesCompared?.[metric];

    const label =
      'label' in item
        ? item.label
        : translate('label.select-products-menu.total');

    return (
      <MetricsBreakdownTableRow
        key={`metrics-breakdown-table-row-${index}`}
        label={label}
        type={item.type}
        value={value ?? 0}
        comparedValue={comparedValue}
      />
    );
  };

  return (
    <Table>
      <TableHeader
        onSort={onSort}
        orderBy={orderBy}
        sortableColumns={Object.values(columnNames)}
        sortOrder={sortOrder}
      >
        <TableRow>
          <TableHead
            tx="label.metrics-breakdown.item"
            name={columnNames.label}
          />
          <TableHead tx="label.metrics-breakdown.type" />
          <TableHead
            tx={getInsightsMetricTx(metric)}
            name={columnNames.metric}
          />
        </TableRow>
      </TableHeader>
      <TableBody>{items.map(renderTableRow)}</TableBody>
    </Table>
  );
}

export function MetricsBreakdown(props: { metric: InsightsMetric }) {
  const { metric } = props;

  return (
    <Card relative>
      <Text variant="bodyMdBold" tx="label.metrics-breakdown.title" />

      <MetricsBreakdownTable metric={metric} />
    </Card>
  );
}
