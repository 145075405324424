import styled, { css } from 'styled-components';

import { baseComponentStyles, getOrbiGradientBorder } from '../../utils';
import { Box } from '../box';
import {
  AvatarProps,
  GetAvatarColorsReturn,
  StyledAvatarProps,
} from './avatar.types';

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

function getAvatarColors(
  variant: AvatarProps['variant'] = 0,
): GetAvatarColorsReturn {
  switch (variant) {
    case 0:
      return {
        text: 'avatarPlaceholder0Text',
        start: 'avatarPlaceholder0BackgroundStart',
        end: 'avatarPlaceholder0BackgroundEnd',
        border: 'avatarPlaceholder0Border',
        borderHover: 'avatarPlaceholder0BorderHover',
      };

    case 1:
      return {
        text: 'avatarPlaceholder1Text',
        start: 'avatarPlaceholder1BackgroundStart',
        end: 'avatarPlaceholder1BackgroundEnd',
        border: 'avatarPlaceholder1Border',
        borderHover: 'avatarPlaceholder1BorderHover',
      };

    case 2:
      return {
        text: 'avatarPlaceholder2Text',
        start: 'avatarPlaceholder2BackgroundStart',
        end: 'avatarPlaceholder2BackgroundEnd',
        border: 'avatarPlaceholder2Border',
        borderHover: 'avatarPlaceholder1BorderHover',
      };

    case 3:
      return {
        text: 'avatarPlaceholder3Text',
        start: 'avatarPlaceholder3BackgroundStart',
        end: 'avatarPlaceholder3BackgroundEnd',
        border: 'avatarPlaceholder3Border',
        borderHover: 'avatarPlaceholder3BorderHover',
      };
  }
}

const AvatarBox = styled(Box)<StyledAvatarProps>`
  ${(props) => {
    const {
      fallbackSrc,
      highlight,
      square,
      variant = 0,
      src,
      onClick,
      theme,
    } = props;

    const avatarColors = getAvatarColors(variant);

    return css`
      ${baseComponentStyles};

      user-select: none;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;

      ${!src &&
      !fallbackSrc &&
      css`
        background: linear-gradient(
          142deg,
          ${theme.colors[avatarColors.start]} 14.28%,
          ${theme.colors[avatarColors.end]} 85.84%
        );
      `}

      ${fallbackSrc &&
      css`
        background: url(${fallbackSrc}) -0.216px 0px / 133.333% 100% no-repeat;
      `}

      color: ${theme.colors[avatarColors.text]};
      box-shadow: 0 0 0 2px ${theme.colors[avatarColors.border]};

      ${onClick &&
      css`
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 0 2px ${theme.colors[avatarColors.borderHover]};
        }

        &:active {
          ${getOrbiGradientBorder(2)}
        }
      `}

      ${highlight && getOrbiGradientBorder(2)};

      ${square &&
      css`
        border-radius: 0;
      `}
    `;
  }}
`;

export const Styled = { Avatar, AvatarBox };
