import React from 'react';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useController } from 'react-hook-form';

import { Styled } from './calendar.styled';

type CalendarProps = React.ComponentProps<typeof ReactCalendar>;
export type CalendarOnChange = CalendarProps['onChange'];

interface ControlledCalendarProps extends Omit<CalendarProps, 'value' | 'ref'> {
  deps?: string[];
  name: string;

  preserveTime?: boolean;
}

export function ControlledCalendar(props: ControlledCalendarProps) {
  const { deps, name, onChange, ...rest } = props;

  const controller = useController({
    name,
    rules: { deps },
  });

  const handleCalendarChange: CalendarOnChange = (date, e) => {
    onChange?.(date, e);

    if (date instanceof Date) {
      if (props.preserveTime) {
        const time = new Date(controller.field.value);
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
      }

      controller.field.onChange(date.getTime());
    }
  };

  return (
    <Calendar
      onChange={handleCalendarChange}
      ref={controller.field.ref}
      value={new Date(controller.field.value)}
      {...rest}
    />
  );
}

function _Calendar(
  props: CalendarProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return <Styled.Calendar ref={ref} {...props} />;
}

export const Calendar = React.forwardRef(_Calendar);
