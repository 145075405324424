import { theme } from '../../../theme';

export const colors = [
  theme.colors['dataVisualisation1'],
  theme.colors['dataVisualisation3'],
  theme.colors['dataVisualisation5'],
  theme.colors['dataVisualisation7'],
  theme.colors['dataVisualisation9'],
  theme.colors['dataVisualisation11'],
  theme.colors['dataVisualisation13'],
  theme.colors['dataVisualisation15'],
];

export const compareColors = [
  theme.colors['dataVisualisation1'],
  theme.colors['dataVisualisation2'],
  theme.colors['dataVisualisation3'],
  theme.colors['dataVisualisation4'],
  theme.colors['dataVisualisation5'],
  theme.colors['dataVisualisation6'],
  theme.colors['dataVisualisation7'],
  theme.colors['dataVisualisation8'],
  theme.colors['dataVisualisation9'],
  theme.colors['dataVisualisation10'],
  theme.colors['dataVisualisation11'],
  theme.colors['dataVisualisation12'],
  theme.colors['dataVisualisation13'],
  theme.colors['dataVisualisation14'],
  theme.colors['dataVisualisation15'],
  theme.colors['dataVisualisation16'],
];
