import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

import notFoundSvg from './not-found.svg';

const NotFound = styled(Box)`
  &:before {
    position: absolute;
    inset: 0;
    content: '';
    background-image: url(${notFoundSvg});
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(20px);
    -webkit-filter: blur(8px);
  }
`;

const NotFoundContent = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      margin: auto;
      z-index: 1;
      box-shadow: ${theme.elevation.strong};
    `;
  }}
`;

export const Styled = { NotFound, NotFoundContent };
