import {
  Box,
  Button,
  Card,
  Chip,
  Icon,
  Link,
  Status,
  Text,
  sumRecordValues,
  translate,
} from '@orbiapp/components';
import React from 'react';

import { useOrbiBusinessCalendar } from '../../../../../helpers';
import { PartialTalentPoolUser } from '../../../../../models';
import {
  LiveInsightsSelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../../../../store';
import { Styled } from './product-stats.styled';
import {
  ProductStatsCardProps,
  ProductStatsRowProps,
  TalentPoolUserStatsRowProps,
} from './product-stats.types';

function ProductStatsRow(props: ProductStatsRowProps) {
  const { value, ...rest } = props;

  return (
    <Box flex flexJustify="between">
      <Status {...rest} />
      <Text text={value} />
    </Box>
  );
}

function ProductStatsCard(
  props: React.PropsWithChildren<ProductStatsCardProps>,
) {
  const { children, titleTx, value, linkTo } = props;

  return (
    <Card height="100%" flexJustify="between">
      <Box flex flexDirection="column" gap={16}>
        <Box flex flexJustify="between">
          <Text variant="bodyMdBold" tx={titleTx} />
          <Text variant="titleMd" text={value} />
        </Box>

        <Box flex flexDirection="column" gap={8}>
          {children}
        </Box>
      </Box>

      <Box flex flexJustify="end">
        <Link to={linkTo} tx="link.general.view-all" variant="secondary" />
      </Box>
    </Card>
  );
}

function TalentPoolUserStatsRow(
  props: TalentPoolUserStatsRowProps & { index: number },
) {
  const { fullName, index, ...rest } = props;

  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  const shouldBlur = Boolean(!connectModule?.talentPoolIsEnabled) && index >= 2;

  return (
    <Styled.TablePoolUserTableRow
      flex
      flexJustify="between"
      gap={8}
      maxWidth="100%"
      shouldBlur={shouldBlur}
    >
      <Text
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        text={fullName}
      />

      <Chip
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxWidth="50%"
        {...rest}
      />
    </Styled.TablePoolUserTableRow>
  );
}

function TalentPoolStatsCardContent() {
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);
  const productStats = useSelector(LiveInsightsSelector.selectData);

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  if (!connectModule?.talentPoolIsEnabled) {
    return (
      <Card
        flex
        flexAlign="center"
        flexDirection="column"
        p={24}
        backgroundColor="talentPoolBlockedCardBackground"
        mx={8}
      >
        <Icon color="talentPoolUsersStatsCardIcon" name="lock-closed-solid" />
        <Text textAlign="center" tx="label.unlock-talent-pool-message" />
        <Button
          href={orbiBusinessCalendarUrl}
          variant="primary"
          tx="label.connect.contact-sales"
        />
      </Card>
    );
  }

  if (productStats?.talentPool.users.length === 0) {
    return (
      <Card
        flex
        flexAlign="center"
        flexDirection="column"
        p={24}
        backgroundColor="talentPoolBlockedCardBackground"
        mx={8}
      >
        <Icon color="talentPoolUsersStatsCardIcon" name="link" />
        <Text textAlign="center" tx="label.talent-pool-empty-message" />
        <Button
          to="/connect/qr-codes?sidebar=true"
          variant="secondary"
          tx="label.connect.new-qr-code"
        />
      </Card>
    );
  }

  return (
    <Box flex flexDirection="column" gap={8}>
      <Box flex flexJustify="between">
        <Text
          variant="bodySm"
          tx="label.connect.name"
          color="talentPoolUsersStatsCardRowHeader"
        />
        <Text
          variant="bodySm"
          tx="label.connect.source"
          color="talentPoolUsersStatsCardRowHeader"
        />
      </Box>

      {productStats?.talentPool.users.map(renderTalentPoolUserStatsRow)}
    </Box>
  );
}

function renderTalentPoolUserStatsRow(
  talentPoolUser: PartialTalentPoolUser,
  index: number,
) {
  return (
    <TalentPoolUserStatsRow
      key={talentPoolUser.userKey}
      index={index}
      fullName={`${talentPoolUser.firstName} ${talentPoolUser.lastName}`}
      text={
        talentPoolUser.companyQr
          ? talentPoolUser.companyQr.name
          : translate('label.connect.profile')
      }
      variant={2}
    />
  );
}

function TalentPoolUsersStatsCard() {
  const productStats = useSelector(LiveInsightsSelector.selectData);

  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );

  if (!enableProfiles) {
    return null;
  }

  return (
    <Card relative overflow="hidden" height="100%" flexJustify="between">
      <Box flex flexDirection="column" gap={16}>
        <Box flex flexJustify="between">
          <Text variant="bodyMdBold" tx="label.product-titles.talent-pool" />
          <Text variant="titleMd" text={productStats?.talentPool.userCount} />
        </Box>

        <TalentPoolStatsCardContent />
      </Box>

      <Box flex flexJustify="end">
        <Link
          to="/connect/talent-pool"
          tx="link.general.view-all"
          variant="secondary"
        />
      </Box>
    </Card>
  );
}

export function ProductStats() {
  const productStats = useSelector(LiveInsightsSelector.selectData);
  const moduleSettings = useSelector(ModuleSettingsSelector.selectData);

  if (!productStats) {
    return null;
  }

  return (
    <Styled.ProductStatsContainerGrid>
      {moduleSettings?.companyProfilesModule && (
        <ProductStatsCard
          linkTo="/profiles"
          titleTx="label.product-titles.profiles"
          value={sumRecordValues(productStats.companyProfiles)}
        >
          <ProductStatsRow
            variant="success"
            tx="label.product-status.live"
            value={productStats.companyProfiles.live}
          />
          <ProductStatsRow
            variant="warning"
            tx="label.product-status.draft"
            value={productStats.companyProfiles.draft}
          />
        </ProductStatsCard>
      )}

      {moduleSettings?.jobsModule && (
        <ProductStatsCard
          linkTo="/jobs"
          titleTx="label.product-titles.jobs"
          value={sumRecordValues(productStats.jobs)}
        >
          <ProductStatsRow
            variant="success"
            tx="label.product-status.live"
            value={productStats.jobs.live}
          />
          <ProductStatsRow
            variant="info"
            tx="label.product-status.scheduled"
            value={productStats.jobs.scheduled}
          />
          <ProductStatsRow
            variant="warning"
            tx="label.product-status.draft"
            value={productStats.jobs.draft}
          />
          <ProductStatsRow
            variant="error"
            tx="label.product-status.closed"
            value={productStats.jobs.closed}
          />
        </ProductStatsCard>
      )}

      <TalentPoolUsersStatsCard />

      {moduleSettings?.companyAdsModule && (
        <ProductStatsCard
          linkTo="/company-ads"
          titleTx="label.product-titles.boosters"
          value={sumRecordValues(productStats.companyAds)}
        >
          <ProductStatsRow
            variant="success"
            tx="label.product-status.live"
            value={productStats.companyAds.live}
          />
          <ProductStatsRow
            variant="info"
            tx="label.product-status.scheduled"
            value={productStats.companyAds.scheduled}
          />
          <ProductStatsRow
            variant="error"
            tx="label.product-status.past"
            value={productStats.companyAds.past}
          />
        </ProductStatsCard>
      )}

      {moduleSettings?.offersModule && (
        <ProductStatsCard
          linkTo="/offers"
          titleTx="label.product-titles.offers"
          value={sumRecordValues(productStats.offers)}
        >
          <ProductStatsRow
            variant="success"
            tx="label.product-status.live"
            value={productStats.offers.live}
          />
          <ProductStatsRow
            variant="info"
            tx="label.product-status.scheduled"
            value={productStats.offers.scheduled}
          />
          <ProductStatsRow
            variant="warning"
            tx="label.product-status.draft"
            value={productStats.offers.draft}
          />
          <ProductStatsRow
            variant="error"
            tx="label.product-status.closed"
            value={productStats.offers.closed}
          />
        </ProductStatsCard>
      )}
    </Styled.ProductStatsContainerGrid>
  );
}
