import {
  Box,
  ControlledTextarea,
  DangerIconButton,
  DragDrop,
  DragDropItem,
  DragDropItemHandle,
  FormSection,
  FormSectionHeader,
  FormSectionHeaderRow,
  IconButton,
  SolidIconButton,
  Status,
  Text,
  TinySelect,
  TinySelectOptionProps,
  Tooltip,
  isTxString,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CompanyProfileForm,
  CreateIndexedPerk,
  PROFILE_PERK_DESCRIPTION_MAX_LENGTH,
} from '../../../../../models';
import { PerkCategoriesSelector, useSelector } from '../../../../../store';
import { SaveFieldOnClickOutside } from '../save-field-on-click-outside';
import { ProfileContext } from './profile-form.context';
import { Styled } from './styled';

const ICON_HOLDER_HEIGHT = 56;
const PERKS_INPUT_HEIGHT = 105;

function renderValue(value?: TinySelectOptionProps<string | number>) {
  return (
    <Text
      variant="bodyMd"
      text={value?.text}
      tx={value?.text ? undefined : 'label.profile.perks.inputs.perk'}
      color="perksFieldSelect"
    />
  );
}

function PerkPlaceholder(props: {
  addPerk: (perKey?: string) => void;
  removePerk: () => void;
  tinySelectOptions: TinySelectOptionProps<string | number>[];
}) {
  const { addPerk, removePerk, tinySelectOptions } = props;

  const onChange = (value?: string | number) =>
    typeof value === 'string' ? addPerk(value) : undefined;

  const onClick = () => addPerk();

  const renderOptionValue = (
    option: TinySelectOptionProps<string | number>,
  ) => {
    return typeof option.value === 'string' ? (
      <Text
        text={option.text}
        variant="bodySm"
        as="span"
        color="perksFieldSelectOptionCategory"
      />
    ) : (
      <Text
        variant="bodySmBold"
        text={option.text}
        color="perksFieldSelectOptionCategory"
      />
    );
  };

  return (
    <Box flex gap={8} height={PERKS_INPUT_HEIGHT + 31}>
      <Box height={ICON_HOLDER_HEIGHT} flex flexAlign="center">
        <IconButton disabled icon="drag" />
      </Box>

      <Styled.TextAreaContainer width="100%">
        <ControlledTextarea
          tinySelect={
            <Tooltip placement="right" titleTx="label.tooltip.select">
              <TinySelect
                invertMenu
                onChange={onChange}
                options={tinySelectOptions}
                value=""
                renderValue={renderValue}
                renderOptionValue={renderOptionValue}
                maxHeight={330}
                minWidth={250}
                px={4}
                py={3}
                chevronIconSize={24}
              />
            </Tooltip>
          }
          name=""
          onClick={onClick}
          resize="none"
          minHeight={PERKS_INPUT_HEIGHT}
          maxHeight={PERKS_INPUT_HEIGHT}
        />
      </Styled.TextAreaContainer>

      <Box height={ICON_HOLDER_HEIGHT} flex flexAlign="center">
        <Tooltip placement="top" titleTx="label.tooltip.remove">
          <DangerIconButton icon="minus-circle-outline" onClick={removePerk} />
        </Tooltip>
      </Box>
    </Box>
  );
}

function PerkListItem(props: {
  perk: CreateIndexedPerk;
  removePerk: () => void;
  setFocusItem: React.Dispatch<React.SetStateAction<number | null>>;
  tinySelectOptions: TinySelectOptionProps<string | number>[];
}) {
  const { perk, removePerk, setFocusItem, tinySelectOptions } = props;

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CompanyProfileForm>();

  const perks = watch('perks') ?? [];
  const perkKeys = new Set(perks.map((perk) => perk.perkKey));

  const options: TinySelectOptionProps<string | number>[] =
    tinySelectOptions.map((tinySelectOption) => ({
      text: tinySelectOption.text,
      value: tinySelectOption.value,
      disabled:
        typeof tinySelectOption.value === 'number'
          ? true
          : perk.perkKey !== tinySelectOption.value &&
            perkKeys.has(tinySelectOption.value),
    }));

  const renderOptionValue = (
    option: TinySelectOptionProps<string | number>,
  ) => {
    return typeof option.value === 'string' ? (
      perk.perkKey !== option.value && perkKeys.has(option.value) ? (
        <Styled.SelectedPerk
          text={option.text}
          variant="bodySm"
          color="perksFieldSelectOptionCategory"
        />
      ) : (
        <Text
          text={option.text}
          variant="bodySm"
          as="span"
          color="perksFieldSelectOptionCategory"
        />
      )
    ) : (
      <Text
        variant="bodySmBold"
        text={option.text}
        color="perksFieldSelectOptionCategory"
      />
    );
  };

  const onChange = (value?: string | number) => {
    if (typeof value !== 'string') {
      return;
    }

    setValue(`perks.${perk.index}.perkKey`, value, {
      shouldDirty: true,
    });

    if (perk.description.length === 0) {
      setFocusItem(perk.index);
    }
  };

  const getErrorTx = () => {
    const txString =
      errors?.perks?.[perk.index]?.perkKey?.message ??
      errors?.perks?.[perk.index]?.description?.message;

    return isTxString(txString) ? txString : undefined;
  };

  return (
    <DragDropItem
      id={`${perk.index}`}
      flex
      gap={8}
      height={PERKS_INPUT_HEIGHT + 31}
    >
      <Box height={ICON_HOLDER_HEIGHT} flex flexAlign="center">
        <Tooltip placement="top" titleTx="label.tooltip.move">
          <DragDropItemHandle />
        </Tooltip>
      </Box>

      <Styled.TextAreaContainer width="100%">
        <ControlledTextarea
          name={`perks.${perk.index}.description`}
          tinySelect={
            <Tooltip placement="right" titleTx="label.tooltip.select">
              <TinySelect
                defaultIsOpen={!perk.perkKey}
                invertMenu
                onChange={onChange}
                options={options}
                value={perk.perkKey}
                renderValue={renderValue}
                renderOptionValue={renderOptionValue}
                maxHeight={330}
                minWidth={250}
                px={4}
                py={3}
                chevronIconSize={24}
              />
            </Tooltip>
          }
          errorTx={getErrorTx()}
          maxLength={PROFILE_PERK_DESCRIPTION_MAX_LENGTH}
          resize="none"
          minHeight={PERKS_INPUT_HEIGHT}
          maxHeight={PERKS_INPUT_HEIGHT}
        />
      </Styled.TextAreaContainer>

      <Box height={ICON_HOLDER_HEIGHT} flex flexAlign="center">
        <Tooltip placement="top" titleTx="label.tooltip.remove">
          <DangerIconButton icon="minus-circle-outline" onClick={removePerk} />{' '}
        </Tooltip>
      </Box>
    </DragDropItem>
  );
}

function PerkItems(props: {
  perks: CreateIndexedPerk[];
  removePlaceholder: () => void;
  setFocusItem: React.Dispatch<React.SetStateAction<number | null>>;
  tinySelectOptions: TinySelectOptionProps<string | number>[];
}) {
  const { perks, removePlaceholder, setFocusItem, tinySelectOptions } = props;

  const { formMode, saveField } = React.useContext(ProfileContext);
  const isDraft = formMode === 'createProfile';

  const { setValue } = useFormContext<CompanyProfileForm>();

  const [items, setItems] = React.useState<
    ({ id: string } & CreateIndexedPerk)[]
  >([]);

  const handleChange = (newState: typeof items) => {
    setItems(newState);

    setValue(
      'perks',
      newState.map((item, index) => ({
        description: item.description,
        index,
        perkKey: item.perkKey,
      })),
      {
        shouldDirty: true,
      },
    );

    if (isDraft) {
      saveField('perks');
    }
  };

  React.useEffect(() => {
    setItems(perks.map((perk) => ({ ...perk, id: `${perk.index}` })) ?? []);
  }, [perks]);

  const renderPerk = (perk: CreateIndexedPerk, index: number) => {
    const removePerk = () => {
      const _perks = perks.filter((_, _index) => _index !== index);

      setValue(
        'perks',
        _perks.length === 0
          ? null
          : _perks.map((perk, index) => ({ ...perk, index })),
        {
          shouldDirty: true,
        },
      );

      removePlaceholder();
    };

    return (
      <PerkListItem
        key={`perk-item-${perk.index}`}
        perk={perk}
        removePerk={removePerk}
        setFocusItem={setFocusItem}
        tinySelectOptions={tinySelectOptions}
      />
    );
  };

  return (
    <DragDrop items={items} onChange={handleChange}>
      {perks.map(renderPerk)}
    </DragDrop>
  );
}

export function Perks() {
  const perkCategories = useSelector(PerkCategoriesSelector.selectAll);

  const { formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const {
    formState: { errors },
    watch,
    setValue,
    setFocus,
  } = useFormContext<CompanyProfileForm>();

  const perks = watch('perks') ?? [];

  const [focusItem, setFocusItem] = React.useState<number | null>(null);

  const [perkPlaceholderIsVisible, setPerkPlaceholderIsVisible] =
    React.useState(isDraft && perks.length === 0);

  const removePlaceholder = () => setPerkPlaceholderIsVisible(false);

  const tinySelectOptions: TinySelectOptionProps<string | number>[] =
    React.useMemo(() => {
      return perkCategories.flatMap(
        (perkCategority, index): TinySelectOptionProps<string | number>[] => {
          return [
            {
              text: perkCategority.name,
              value: index,
              disabled: true,
            },
            ...perkCategority.perks.map((perk) => ({
              text: perk.emojiAndName,
              value: perk.perkKey,
            })),
          ];
        },
      );
    }, [perkCategories]);

  React.useEffect(() => {
    if (focusItem !== null) {
      setFocus(`perks.${focusItem}.description`);
      setFocusItem(null);
    }
  }, [focusItem, setFocus]);

  const addPerk = (perkKey = '') => {
    setValue(
      'perks',
      [...perks, { description: '', index: perks.length, perkKey }],
      {
        shouldDirty: true,
      },
    );

    setFocusItem(perks.length);
  };

  const addEmptyPerk = () => addPerk();

  const errorMessage = errors.perks?.message ?? errors.perks?.root?.message;

  const allPerksCount = tinySelectOptions.length - perkCategories.length;

  return (
    <SaveFieldOnClickOutside field="perks" disabled={!isDraft}>
      <FormSection>
        <FormSectionHeaderRow>
          <FormSectionHeader>
            <Text
              tx="label.profile.perks.header"
              variant="bodyMdBold"
              color="formSectionHeader"
            />
            <Text
              tx="label.profile.perks.description"
              variant="bodyMd"
              color="formSectionDescription"
            />
          </FormSectionHeader>

          <Status
            ml="auto"
            variant="warning"
            tx="label.profile.perks.counter-status"
            whiteSpace="nowrap"
            txArgs={{ length: perks.length, max: allPerksCount }}
          />

          <Tooltip
            placement="top"
            titleTx="label.tooltip.add"
            disabled={perks.length >= allPerksCount}
          >
            <SolidIconButton
              onClick={addEmptyPerk}
              icon="plus-circle-outline"
              disabled={perks.length >= allPerksCount}
            />
          </Tooltip>
        </FormSectionHeaderRow>

        {!errors.perks?.[0] && isTxString(errorMessage) && (
          <Text
            mt={4}
            height={26}
            tx={errorMessage}
            variant="bodyMd"
            color="errorLabel"
          />
        )}

        <Box flex flexDirection="column" gap={8}>
          {perks.length ? (
            <PerkItems
              perks={perks}
              removePlaceholder={removePlaceholder}
              setFocusItem={setFocusItem}
              tinySelectOptions={tinySelectOptions}
            />
          ) : (
            perkPlaceholderIsVisible && (
              <PerkPlaceholder
                addPerk={addPerk}
                removePerk={removePlaceholder}
                tinySelectOptions={tinySelectOptions}
              />
            )
          )}
        </Box>
      </FormSection>
    </SaveFieldOnClickOutside>
  );
}
