import { joiResolver } from '@hookform/resolvers/joi';
import { Box, ControlledInput } from '@orbiapp/components';
import { useForm } from 'react-hook-form';

import { InviteTeamMembersForm, InviteTeamMembersValidation } from '../models';
import {
  CreateInvitationsSelector,
  PendingInvitationsSelector,
  UsersSelector,
  createInvitationsThunk,
  useDispatch,
  useSelector,
} from '../store';

function renderInvitationRow(_: any, index: number, array: any[]) {
  return (
    <Box flexWrap gap={16} flex key={`invitation-row-${index}`}>
      <Box flexGrow={1}>
        <ControlledInput
          name={`invitations.${index}.email`}
          labelTx="label.team.email"
        />
      </Box>
    </Box>
  );
}

export function useInviteTeamMembers(props: { onSuccess: () => void }) {
  const { onSuccess } = props;

  const existingTeamMemberEmails = useSelector(UsersSelector.selectAllEmails);
  const pendingTeamMemberEmails = useSelector(
    PendingInvitationsSelector.selectAllEmails,
  );
  const createInvitationsStatus = useSelector(
    CreateInvitationsSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const formMethods = useForm<InviteTeamMembersForm>({
    resolver: joiResolver(
      InviteTeamMembersValidation({
        existingTeamMemberEmails,
        pendingTeamMemberEmails,
      }),
    ),
    defaultValues: {
      invitations: [{ email: '' }, { email: '' }, { email: '' }],
    },
  });

  const invites = formMethods.watch('invitations');

  const everyEmailIsEmptyString = invites.every(
    (invitation) => invitation.email === '',
  );

  const sendInvites = formMethods.handleSubmit(async (data) => {
    if (everyEmailIsEmptyString) return;

    const invitations = data.invitations.filter(
      (invitation) => invitation.email !== '',
    );

    const res = await dispatch(createInvitationsThunk({ invitations }));
    if (res.meta.requestStatus === 'fulfilled') {
      onSuccess?.();
    }
  });

  const addInvite = () => {
    formMethods.setValue('invitations', [...invites, { email: '' }], {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });
  };

  const isLoading = createInvitationsStatus === 'pending';

  return {
    formMethods,
    everyEmailIsEmptyString,
    sendInvites,
    addInvite,
    isLoading,
    invites: invites.map(renderInvitationRow),
  };
}
