import { Color, TextVariant } from '@orbiapp/theme';

import { Box, BoxProps } from '../box';
import { Chip } from '../chip';
import { Icon, IconName } from '../icon';
import { Spinner } from '../spinner';
import { Text, TextProps, TxArgs } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './selection-card.styled';

interface SelectionCardListItemProps {
  label?: string;
  labelTx?: TxString;
  labelTxArgs?: TxArgs;

  tooltip?: string;
  tooltipTx?: TxString;
  tooltipTxArgs?: TxArgs;

  icon?: IconName;
  iconColor?: Color;
}

function SelectionCardListItem(props: SelectionCardListItemProps) {
  const {
    label,
    labelTx,
    labelTxArgs,

    tooltip,
    tooltipTx,
    tooltipTxArgs,

    iconColor = 'selectionCardIcon',
    icon = 'check-circle-solid',
  } = props;

  const selectionCardContent = (
    <Box flex gap={8} flexAlign="center">
      <Icon color={iconColor} name={icon} />

      <Text
        variant="bodyMd"
        lineHeight="unset"
        text={label}
        tx={labelTx}
        txArgs={labelTxArgs}
      />
    </Box>
  );

  if (!tooltip && !tooltipTx) {
    return selectionCardContent;
  }

  return (
    <Box flexAlign="center" flex flexJustify="between" gap={16}>
      {selectionCardContent}

      <Tooltip
        placement="bottom"
        titleTx={tooltipTx}
        title={tooltip}
        titleTxArgs={tooltipTxArgs}
      >
        <Icon
          color="selectionCardTooltipIcon"
          name="question-mark-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

function SelectionCardList(props: BoxProps) {
  return <Box flex flexDirection="column" {...props} />;
}

function renderSelectionCardListItem(
  listItem: SelectionCardListItemProps,
  index: number,
) {
  return <SelectionCardListItem key={index} {...listItem} />;
}

interface SelectionCardProps extends BoxProps {
  chipText?: string;
  chipTx?: TxString;
  chipTxArgs?: TxArgs;

  disabled?: boolean;
  isLoading?: boolean;
  listItems?: SelectionCardListItemProps[];
  listGap?: number;
  onClick: () => void;

  subtitleVariant?: TextVariant;
  subtitle?: string;
  subtitleTx?: TxString;
  subtitleTxArgs?: TxArgs;
  subtitleColor?: Color;
  subtitleMaxWidth?: TextProps['maxWidth'];

  title?: string;
  titleTx?: TxString;
  titleTxArgs?: TxArgs;
  titleVariant?: TextVariant;
  toggled: boolean;

  icon?: IconName;
  toggledIcon?: IconName;
  iconColor?: Color;
  iconSize?: number;
}

export function SelectionCard(props: SelectionCardProps) {
  const {
    disabled,
    isLoading,
    toggled,
    listItems,
    listGap = 16,
    onClick,

    chipTx,
    chipText,
    chipTxArgs,

    subtitle,
    subtitleTx,
    subtitleTxArgs,
    subtitleVariant = 'bodyMd',
    subtitleColor = 'selectionCardLabel',
    subtitleMaxWidth = '32ch',

    title,
    titleTx,
    titleTxArgs,
    titleVariant = 'bodyMdBold',

    icon,
    toggledIcon = icon,
    iconSize = 40,

    ...rest
  } = props;

  return (
    <Styled.SelectionCard
      aria-disabled={disabled}
      disabled={disabled || isLoading}
      flex
      flexDirection="column"
      onClick={onClick}
      toggled={toggled}
      p={32}
      r={16}
      cursor="pointer"
      relative
      backgroundColor="selectionCardBackground"
      {...rest}
    >
      {isLoading && (
        <Box
          flex
          flexJustify="center"
          flexAlign="center"
          zIndex={5}
          absolute
          inset
        >
          <Spinner />
        </Box>
      )}

      <Box flexAlign={icon ? 'center' : undefined} gap={8} flex>
        {icon && toggledIcon && (
          <Icon
            size={iconSize}
            color={toggled ? 'iconToggled' : 'iconPrimary'}
            name={toggled ? toggledIcon : icon}
          />
        )}

        <Box flex flexJustify="between" gap={4} flexAlign="start">
          <Box flex flexDirection="column" gap={4}>
            <Text
              color="selectionCardTitle"
              text={title}
              tx={titleTx}
              variant={titleVariant}
            />

            {(subtitleTx || subtitle) && (
              <Text
                color={subtitleColor}
                maxWidth={subtitleMaxWidth}
                text={subtitle}
                tx={subtitleTx}
                txArgs={subtitleTxArgs}
                variant={subtitleVariant}
              />
            )}
          </Box>

          {(chipTx || chipText) && (
            <Chip text={chipText} tx={chipTx} txArgs={chipTxArgs} variant={2} />
          )}
        </Box>
      </Box>

      {listItems && (
        <SelectionCardList gap={listGap}>
          {listItems.map(renderSelectionCardListItem)}
        </SelectionCardList>
      )}
    </Styled.SelectionCard>
  );
}
