import { Time } from '@orbiapp/components';
import { ApisauceConfig, create } from 'apisauce';

import { API_URL } from '../../config';
import {
  authTransformer,
  emulateUserTransformer,
  loggerTransformer,
  workspaceTransformer,
} from './api.transformers';

const apisauceConfig: ApisauceConfig = {
  baseURL: API_URL,
  timeout: 10 * Time.Second,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export const apisauce = create(apisauceConfig);
export const apisauceAuth = create(apisauceConfig);

apisauce.addResponseTransform(loggerTransformer);
apisauce.addAsyncRequestTransform(authTransformer);
apisauce.addAsyncRequestTransform(workspaceTransformer);
apisauce.addAsyncRequestTransform(emulateUserTransformer);

apisauceAuth.addResponseTransform(loggerTransformer);
apisauceAuth.addAsyncRequestTransform(authTransformer);

const call = <ReturnValue, Params>(
  endpoint: (params: Params) => ReturnValue,
  params: Params,
): ReturnValue => endpoint(params);

export const OrbiApi = { call };
