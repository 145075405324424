import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Text } from '../text';
import { StyledRadioProps } from './radio.types';

const RadioBackground = styled(Box)`
  border-radius: 50%;
  height: 32px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  z-index: -1;
`;

const RadioDot = styled(Box)`
  border-radius: 50%;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  z-index: 3;
`;

const RadioCircle = styled(Box)`
  border-radius: 50%;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border: 2px solid transparent;
`;

const RadioText = styled(Text)``;

const RadioSubtitle = styled(Text)``;

const RadioTextBox = styled(Box)<{ disabled?: boolean; checked: boolean }>`
  ${(props) => {
    const { theme, disabled, checked } = props;

    return css`
      cursor: pointer;

      ${RadioText} {
        color: ${theme.colors.radioLabel};
      }

      ${RadioSubtitle} {
        color: ${theme.colors.radioSubtitle};
      }

      ${disabled &&
      checked &&
      css`
        pointer-events: none;

        ${RadioText} {
          opacity: 0.5;
          color: ${theme.colors.radioLabelDisabled};
        }

        ${RadioSubtitle} {
          opacity: 0.5;
          color: ${theme.colors.radioSubtitleDisabled};
        }
      `}

      ${disabled &&
      !checked &&
      css`
        pointer-events: none;

        ${RadioText} {
          opacity: 0.5;
          color: ${theme.colors.radioLabelDisabled};
        }

        ${RadioSubtitle} {
          opacity: 0.5;
          color: ${theme.colors.radioSubtitleDisabled};
        }
      `}
    `;
  }}
`;

const Radio = styled(Box)<StyledRadioProps>`
  ${(props) => {
    const { theme, disabled, checked } = props;

    return css`
      cursor: pointer;
      border-radius: 50%;
      position: relative;

      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;

      ${RadioText} {
        color: ${theme.colors.radioLabel};
      }

      ${RadioSubtitle} {
        color: ${theme.colors.radioSubtitle};
      }

      ${RadioBackground} {
        background-color: ${theme.colors.radioBackground};
      }

      ${RadioDot} {
        background-color: ${theme.colors.radioDot};
      }

      ${RadioCircle} {
        border-color: ${theme.colors.radioBorder};
      }

      &:hover {
        ${RadioText} {
          color: ${theme.colors.radioLabelHover};
        }

        ${RadioSubtitle} {
          color: ${theme.colors.radioSubtitleHover};
        }

        ${RadioBackground} {
          background-color: ${theme.colors.radioBackgroundHover};
        }

        ${!checked &&
        css`
          ${RadioDot} {
            background-color: ${theme.colors.radioDotHover};
          }

          ${RadioCircle} {
            border-color: ${theme.colors.radioBorderHover};
          }
        `}
      }

      &:active {
        ${RadioText} {
          color: ${theme.colors.radioLabelActive};
        }

        ${RadioSubtitle} {
          color: ${theme.colors.radioSubtitleActive};
        }

        ${RadioBackground} {
          background-color: ${theme.colors.radioBackgroundActive};
        }

        ${!checked &&
        css`
          ${RadioDot} {
            background-color: ${theme.colors.radioDotActive};
          }

          ${RadioCircle} {
            border-color: ${theme.colors.radioBorderActive};
          }
        `}
      }

      ${checked &&
      css`
        ${RadioText} {
          color: ${theme.colors.radioLabelToggled};
        }

        ${RadioSubtitle} {
          color: ${theme.colors.radioSubtitleToggled};
        }

        ${RadioBackground} {
          background-color: ${theme.colors.radioBackgroundToggled};
        }

        ${RadioDot} {
          background-color: ${theme.colors.radioDotToggled};
        }

        ${RadioCircle} {
          border-color: ${theme.colors.radioBorderToggled};
        }
      `}

      ${disabled &&
      checked &&
      css`
        pointer-events: none;

        ${RadioText} {
          opacity: 0.5;
          color: ${theme.colors.radioLabelDisabled};
        }

        ${RadioSubtitle} {
          opacity: 0.5;
          color: ${theme.colors.radioSubtitleDisabled};
        }

        ${RadioBackground} {
          background-color: ${theme.colors.radioDisabledBackgroundToggled};
        }

        ${RadioDot} {
          background-color: ${theme.colors.radioDisabledDotToggled};
        }

        ${RadioCircle} {
          border-color: ${theme.colors.radioDisabledBorderToggled};
        }
      `}

      ${disabled &&
      !checked &&
      css`
        pointer-events: none;

        ${RadioText} {
          opacity: 0.5;
          color: ${theme.colors.radioLabelDisabled};
        }

        ${RadioSubtitle} {
          opacity: 0.5;
          color: ${theme.colors.radioSubtitleDisabled};
        }

        ${RadioBackground} {
          background-color: ${theme.colors.radioBackgroundDisabled};
        }

        ${RadioDot} {
          background-color: ${theme.colors.radioDotDisabled};
        }

        ${RadioCircle} {
          border-color: ${theme.colors.radioBorderDisabled};
        }
      `}
    `;
  }}
`;

export const Styled = {
  Radio,
  RadioBackground,
  RadioCircle,
  RadioDot,
  RadioText,
  RadioTextBox,
  RadioSubtitle,
};
