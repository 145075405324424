import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const DuplicateProfilesList = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;

  li {
    list-style-type: none;

    &:first-child,
    &:last-child {
      border-radius: 0px;
    }
  }

  ${(props) => css`
    border: 1px solid ${props.theme.colors.duplicateProfileModalListBorder};
    border-radius: ${props.theme.borderRadii.s}px;
  `}
`;

export const Styled = { DuplicateProfilesList };
