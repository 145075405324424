import { FormBox, useFeatureFlag } from '@orbiapp/components';

import { AdditionalInformation } from './additional-information';
import { Budget } from './budget';
import { ContactDetails } from './contact-details';
import { JobDetails } from './job-details';
import { Locations } from './locations';
import { TargetGroup } from './target-group';
import { TypeOfPositions } from './type-of-positions';

export function ViewJobForm() {
  const enableJobBudget = useFeatureFlag('company_dashboard_enable_job_budget');

  return (
    <FormBox>
      <JobDetails />

      <AdditionalInformation />

      <TargetGroup />

      <Locations />

      <TypeOfPositions />

      <ContactDetails />

      {enableJobBudget && <Budget />}
    </FormBox>
  );
}
