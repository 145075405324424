import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(utc);

export const dateOptions = Intl.DateTimeFormat().resolvedOptions();

export const sentryCultureContext = {
  calendar: dateOptions.calendar,
  locale: dateOptions.locale,
  timezone: dateOptions.timeZone,
};

const formattingOptions = [
  'timeAgo',
  'M',
  'H',
  'D',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm',
  'DD MMM YYYY HH:mm',
  'DD MMM YYYY, HH:mm',
  'YYYY-MM-DD HH:mm',
  'DD MMM YYYY',
  'DD MMM YYYY HH:mm:ss',
  'ddd DD MMM',
] as const;

export type DateFormat = (typeof formattingOptions)[number];

export const formatDate = (
  date: dayjs.ConfigType,
  dateFormat: DateFormat,
  timezone?: string,
) => {
  if (dateFormat === 'timeAgo') {
    return newDayjs(date, timezone).fromNow();
  }

  return newDayjs(date, timezone).format(dateFormat);
};

export function formatPublishDate(date: dayjs.ConfigType) {
  return dayjs(date).calendar(null, {
    sameDay: '[Today at] HH:mm',
    nextDay: '[Tomorrow at] HH:mm',
    nextWeek: 'dddd [at] HH:mm',
    lastDay: '[Yesterday at] HH:mm',
    lastWeek: '[Last] dddd [at] HH:mm',
    sameElse: 'DD/MM/YYYY [at] HH:mm',
  });
}

export function isInvalidDate(date: dayjs.ConfigType) {
  return !dayjs(date).isValid();
}

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function newDayjs(
  date?: dayjs.ConfigType,
  timezone: string = browserTimezone,
) {
  return dayjs.tz(date, timezone);
}
