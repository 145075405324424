import {
  Banner,
  Box,
  Link,
  Status,
  StatusVariant,
  numberFormatter,
} from '@orbiapp/components';

import { useOrbiBusinessCalendar } from '../../../../../helpers';
import { ModuleSettingsSelector, useSelector } from '../../../../../store';

export function AvailableQrCodesStatusChip() {
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  if (!connectModule || connectModule.maxQrCodeCount === null) {
    return null;
  }

  const remainingQrCodeCount = Math.max(
    0,
    connectModule.maxQrCodeCount - connectModule.usedQrCodeCount,
  );

  let variant: StatusVariant = 'success';
  if (remainingQrCodeCount === 0) {
    variant = 'error';
  }
  if (remainingQrCodeCount === 1) {
    variant = 'warning';
  }

  return (
    <Box flex flexAlign="center" gap={8}>
      <Status
        tx="label.connect.qr-codes-available"
        txArgs={{ count: numberFormatter.format(remainingQrCodeCount) }}
        variant={variant}
      />

      <Link
        href={orbiBusinessCalendarUrl}
        small
        tx="label.connect.contact-sales"
        variant="secondary"
      />
    </Box>
  );
}

export function ConnectLicenseStatusChip() {
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  if (connectModule?.talentPoolIsEnabled) {
    return null;
  }

  return (
    <Banner
      variant="warning"
      titleTx="label.connect.no-license"
      linkTx="label.connect.contact-sales"
      linkHref={orbiBusinessCalendarUrl}
    />
  );
}
