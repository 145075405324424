import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ControlledInput,
  Form,
  Link,
  SignInForm,
  SplitScreenContentContainer,
  Text,
  usePasswordInput,
} from '@orbiapp/components';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { SignedOutToolbar } from '../../../components';
import { CREATE_ACCOUNT_URL, ORBI_URL } from '../../../constants';
import { EmailSchema, SignInValidation } from '../../../models';
import {
  AuthStateSelector,
  signInThunk,
  useDispatch,
  useSelector,
} from '../../../store';

function SignInContent() {
  const authStateStatus = useSelector(AuthStateSelector.selectStatus);

  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { icon, inputType, toggleShowPassword } = usePasswordInput();

  const signInFormMethods = useForm<SignInForm>({
    defaultValues: { email: '', password: '' },
    resolver: joiResolver(SignInValidation),
  });

  React.useEffect(() => {
    const email = new URLSearchParams(window.location.search).get('email');

    if (email && EmailSchema.validate(email)) {
      signInFormMethods.setValue('email', email);
      signInFormMethods.setFocus('password');
    }
  }, [signInFormMethods]);

  const signIn = signInFormMethods.handleSubmit(async (data) => {
    const res = await dispatch(signInThunk(data));

    if (res.meta.requestStatus === 'fulfilled') {
      navigate(location.state?.returnTo ?? '/insights');
    }
  });

  const isLoading = authStateStatus === 'pending';

  return (
    <React.Fragment>
      <Text
        color="signInRightSideTitle"
        variant="titleMd"
        tx="label.auth.sign-in.welcome-to-orbi"
      />

      <Form
        flex
        flexDirection="column"
        formMethods={signInFormMethods}
        width="100%"
        gap={16}
        onSubmit={signIn}
      >
        <ControlledInput
          disabled={isLoading}
          labelTx="label.auth.email"
          name="email"
          type="email"
        />

        <ControlledInput
          labelTx="label.auth.password"
          name="password"
          disabled={isLoading}
          trailingElements={[
            {
              type: 'button',
              icon,
              onClick: toggleShowPassword,
            },
          ]}
          type={inputType}
        />

        <Button
          isLoading={isLoading}
          width="100%"
          tx="button.auth.log-in"
          type="submit"
          variant="primary"
          large
        />
      </Form>

      <Box flexAlign="center" flex gap={32}>
        <Link
          variant="primary"
          small
          to="/reset-password"
          tx="link.auth.forgot-your-password"
        />
        <Link
          small
          href={CREATE_ACCOUNT_URL}
          tx="link.auth.create-an-account"
          target="_blank"
          variant="secondary"
        />
      </Box>
    </React.Fragment>
  );
}

export function SignIn() {
  return (
    <React.Fragment>
      <SignedOutToolbar />

      <SplitScreenContentContainer>
        <SignInContent />

        <Box absolute bottom={40}>
          <Link
            tx="link.auth.back-to-orbi"
            href={ORBI_URL}
            variant="secondary"
            small
          />
        </Box>
      </SplitScreenContentContainer>
    </React.Fragment>
  );
}
