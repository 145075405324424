import styled, { css } from 'styled-components';

import { getOrbiGradientBorder } from '../../utils';
import { Icon } from '../icon';
import { Text } from '../text';

const VerticalButtonIcon = styled(Icon)`
  transform: rotate(90deg);
`;

const VerticalButtonText = styled(Text)`
  transform: rotate(180deg);
`;

const RotatedBox = styled.div`
  transform: rotate(-90deg);
  width: fit-content;
  height: fit-content;
`;

const VerticalButton = styled.button`
  ${(props) => {
    const { theme, disabled } = props;

    return css`
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      border-radius: 4px 4px 0px 0px;
      border: none;
      outline: none;
      cursor: pointer;

      background-color: ${theme.colors.verticalButtonBackground};
      box-shadow: 0 0 0 1px ${theme.colors.verticalButtonBorder};

      ${VerticalButtonIcon} {
        color: ${theme.colors.verticalButtonIcon};
      }

      ${VerticalButtonText} {
        color: ${theme.colors.verticalButtonLabel};
      }

      ${!disabled &&
      css`
        &:hover {
          background-color: ${theme.colors.verticalButtonBackgroundHover};
          box-shadow: 0 0 0 2px ${theme.colors.verticalButtonBorderHover};
          ${getOrbiGradientBorder(2)};

          ${VerticalButtonIcon} {
            color: ${theme.colors.verticalButtonIconHover};
          }

          ${VerticalButtonText} {
            color: ${theme.colors.verticalButtonLabelHover};
          }
        }

        &:active {
          background-color: ${theme.colors.verticalButtonBackgroundActive};
          box-shadow: 0 0 0 1px ${theme.colors.verticalButtonBorderActive};

          &::before {
            content: unset;
          }

          ${VerticalButtonIcon} {
            color: ${theme.colors.verticalButtonIconActive};
          }

          ${VerticalButtonText} {
            color: ${theme.colors.verticalButtonLabelActive};
          }
        }
      `}

      &:disabled {
        pointer-events: none;
        background-color: ${theme.colors.verticalButtonBackgroundDisabled};
        box-shadow: 0 0 0 1px ${theme.colors.verticalButtonBorderDisabled};

        ${VerticalButtonIcon} {
          color: ${theme.colors.verticalButtonIconDisabled};
        }

        ${VerticalButtonText} {
          color: ${theme.colors.verticalButtonLabelDisabled};
        }
      }
    `;
  }}
`;

export const Styled = {
  RotatedBox,
  VerticalButton,
  VerticalButtonIcon,
  VerticalButtonText,
};
