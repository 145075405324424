import { joiResolver } from '@hookform/resolvers/joi';
import {
  Address,
  Box,
  Button,
  Chip,
  ControlledInput,
  ControlledSelect,
  DangerIconButton,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  Input,
  InputChip,
  InputChips,
  Link,
  LoadingContainer,
  Menu,
  MenuItem,
  PaperContentContainer,
  Text,
  WrapBox,
  flattenFieldErrorsObject,
  getFileFromUrl,
  getUID,
  isCoordinates,
  isTxString,
  useFetch,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { assets } from '../../../../assets';
import {
  NavBlocker,
  UnsavedChangesBox,
  UploadLogo,
} from '../../../../components';
import {
  useLocationSearch,
  useOrbiBusinessCalendar,
} from '../../../../helpers';
import {
  COMPANY_NAME_MAX_LENGTH,
  EmployeeCount,
  Industry,
  Option,
  TAGS_MAX_COUNT,
  TAG_MAX_LENGTH,
  UpdateCompanyForm,
  UpdateCompanyFormValidation,
  VisibilityFilterByCountry,
  employeeCountList,
  formatIndustry,
  industryList,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  CompanySelector,
  CompanyVisibilityFilterSelector,
  OfficeLocationsSelector,
  UpdateCompanySelector,
  getCoordinatesThunk,
  getCountriesThunk,
  getOfficeLocationsThunk,
  updateCompanyThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getOptionalLabelText } from '../../../../utils';
import { Styled } from './company-settings.styled';

const sessionToken = getUID();

const EMPLOYEE_COUNT_OPTIONS: Option<EmployeeCount>[] = employeeCountList.map(
  (employeeCount) => ({
    value: employeeCount,
    text: employeeCount,
  }),
);

const INDUSTRY_OPTIONS: Option<Industry>[] = industryList.map((industry) => ({
  value: industry,
  text: formatIndustry(industry),
}));

function AdditionalInformation() {
  const inputChipsRef = React.useRef<HTMLInputElement>(null);

  const formContext = useFormContext<UpdateCompanyForm>();

  const tags = formContext.watch('tags') ?? [];

  const [tag, setTag] = React.useState('');

  const handleAddChip = (value: string) => {
    const _value = value.trim();

    if (!_value || tags.includes(_value)) {
      return;
    }

    formContext.setValue('tags', tags.concat(_value), {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    setTag('');
  };

  const handleRemoveChip = (id: number) => {
    const _tags = tags.filter((_, index) => index !== id);

    formContext.setValue('tags', _tags.length ? _tags : null, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const handleInputChipValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setTag(e.target.value);
  };

  const chips: InputChip<number>[] = tags.map((tag, index) => ({
    id: index,
    text: tag,
    maxWidth: '65ch',
  }));

  const handlePlusClick = () => handleAddChip(tag);

  return (
    <InnerPaperContentContainerSection>
      <Box flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column" gap={4}>
          <Text variant="bodyMdBold" tx="label.company-settings.tags.header" />
        </Box>
        <InputChips
          ref={inputChipsRef}
          value={tag}
          showInput={!!tag}
          chips={chips}
          label={getOptionalLabelText('label.company-settings.tags.label')}
          errorTx={
            isTxString(formContext.formState.errors.tags?.message)
              ? formContext.formState.errors.tags?.message
              : undefined
          }
          maxChips={TAGS_MAX_COUNT}
          maxLength={TAG_MAX_LENGTH}
          onAddChip={handleAddChip}
          onRemoveChip={handleRemoveChip}
          onChange={handleInputChipValueChange}
          trailingElements={[
            {
              type: 'button',
              icon: 'plus-circle-outline',
              onClick: handlePlusClick,
              tooltipTx: 'label.tooltip.add',
              tooltipPlacement: 'left',
              disabled: chips.length >= TAGS_MAX_COUNT,
            },
          ]}
        />
      </Box>
    </InnerPaperContentContainerSection>
  );
}

function LocationInput(props: { index: number }) {
  const { index } = props;

  const dispatch = useDispatch();

  const formContext = useFormContext<UpdateCompanyForm>();

  const location = formContext.getValues(`locations.${index}`);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState(location.address);

  const menuState = useMenu();

  const { isLoading, search, searchResult } = useLocationSearch(sessionToken);

  const hasPicked = Boolean(location.latitude && location.longitude);

  const isOpen = !hasPicked && menuState.isOpen && searchResult.length;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);

    if (e.target.value.trim()) {
      search(e.target.value);
    }
  };

  const clearLocation = () => {
    formContext.setValue(
      `locations.${index}`,
      {
        placeId: location.placeId,
        officeLocationKey: location.officeLocationKey,
        address: '',
        label: location.label,
        latitude: 0,
        longitude: 0,
      },
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
    setValue('');

    window.setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const preventDefault: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  const renderSearchResult = (address: Address) => {
    const handleSelect = async () => {
      const res = await dispatch(
        getCoordinatesThunk({
          placeId: address.placeId,
          sessionToken,
        }),
      );

      if (isCoordinates(res.payload)) {
        formContext.setValue(
          `locations.${index}`,
          {
            officeLocationKey: getUID(),
            placeId: address.placeId,
            address: address.address,
            label: '',
            latitude: res.payload.latitude,
            longitude: res.payload.longitude,
          },
          {
            shouldDirty: true,
            shouldValidate: formContext.formState.isSubmitted,
          },
        );

        setValue(address.address);

        formContext.setFocus(`locations.${index}.label`);
      }
    };

    return (
      <MenuItem
        key={address.placeId}
        onClick={handleSelect}
        onMouseDown={preventDefault}
        text={address.address}
      />
    );
  };

  const errorTx = isTxString(
    formContext.formState.errors.locations?.[index]?.address?.message,
  )
    ? formContext.formState.errors.locations?.[index]?.address?.message
    : undefined;

  return (
    <Box ref={menuState.menuRef} flexGrow={1} relative>
      <Input
        ref={inputRef}
        disabled={hasPicked}
        onChange={handleChange}
        value={value}
        onFocus={menuState.openMenu}
        onBlur={menuState.closeMenu}
        errorTx={errorTx as TxString}
        leadingElements={[
          {
            type: 'icon',
            name: 'magnifying-glass',
          },
        ]}
        trailingElements={[
          {
            type: 'spinner',
            size: 20,
            hidden: !isLoading,
          },
          {
            type: 'button',
            icon: isOpen ? 'chevron-up' : 'chevron-down',
            onClick: menuState.toggleMenu,
            hidden: hasPicked,
          },
          {
            type: 'button',
            icon: 'x-mark',
            onClick: clearLocation,
            hidden: !hasPicked,
          },
        ]}
        labelTx="placeholder.select-location"
      />

      <Menu
        maxHeight={300}
        bottom="100%"
        mb={8}
        absolute
        isOpen={Boolean(isOpen)}
        width="100%"
      >
        {searchResult.map(renderSearchResult)}

        {searchResult.length === 0 && value.length > 0 && (
          <MenuItem tx="placeholder.no-addresses" />
        )}
      </Menu>
    </Box>
  );
}

function Locations() {
  const formContext = useFormContext<UpdateCompanyForm>();

  const arrayMethods = useFieldArray({
    name: 'locations',
    control: formContext.control,
  });

  const addLocation = () => {
    arrayMethods.append(
      {
        placeId: '',
        address: '',
        label: '',
        latitude: 0,
        longitude: 0,
        officeLocationKey: getUID(),
      },
      {
        shouldFocus: false,
      },
    );
  };

  const renderLocation = (
    location: (typeof arrayMethods.fields)[number],
    index: number,
  ) => {
    const removeLocation = () => {
      formContext.setValue(
        'locations',
        formContext.getValues('locations').filter((_, i) => i !== index),
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );
    };

    const clearLabel = () => {
      formContext.setValue(`locations.${index}.label`, '', {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
      formContext.setFocus(`locations.${index}.label`);
    };

    const label = formContext.getValues(`locations.${index}.label`);

    return (
      <Box key={location.id} gap={16} flex>
        <Box flex flexDirection="column" gap={8} flexGrow={1}>
          <LocationInput index={index} />

          <ControlledInput
            labelTx="label.offer-form.location-label"
            name={`locations.${index}.label`}
            trailingElements={[
              {
                type: 'button',
                icon: 'x-mark',
                onClick: clearLabel,
                hidden: !label.length,
              },
            ]}
          />
        </Box>

        <DangerIconButton
          icon="minus-circle-outline"
          mt={54}
          onClick={removeLocation}
        />
      </Box>
    );
  };

  return (
    <InnerPaperContentContainerSection>
      <Box flex flexJustify="between" flexAlign="start">
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.company-settings.locations.label"
            variant="bodyMdBold"
          />

          <Text
            tx="label.company-settings.locations.description"
            variant="bodyMd"
            color="formSectionDescription"
          />
        </Box>

        <Button
          variant="secondary"
          tx="button.add-location"
          onClick={addLocation}
        />
      </Box>

      <Box flexGrow={1} mt={16}>
        <Box flex flexDirection="column" gap={16}>
          {arrayMethods.fields.map(renderLocation)}
        </Box>
      </Box>
    </InnerPaperContentContainerSection>
  );
}

function SocialMedia() {
  return (
    <InnerPaperContentContainerSection>
      <Box flex flexJustify="between" flexAlign="start">
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.company-settings.company-info.social-media.title"
            variant="bodyMdBold"
          />

          <Text
            tx="label.company-settings.company-info.social-media.description"
            variant="bodyMd"
            color="formSectionDescription"
          />
        </Box>
      </Box>

      <Box flex flexDirection="column" gap={16} mt={16}>
        <ControlledInput
          name="facebookUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.facebook',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'facebook-colored',
            },
          ]}
        />

        <ControlledInput
          name="instagramUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.instagram',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'instagram-colored',
            },
          ]}
        />

        <ControlledInput
          name="linkedinUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.linkedin',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'linkedin-colored',
            },
          ]}
        />

        <ControlledInput
          name="tiktokUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.tiktok',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'tiktok-colored',
            },
          ]}
        />

        <ControlledInput
          name="twitterUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.twitter',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'x-colored',
            },
          ]}
        />

        <ControlledInput
          name="youtubeUrl"
          label={getOptionalLabelText(
            'label.company-settings.company-info.youtube',
          )}
          leadingElements={[
            {
              type: 'icon',
              name: 'youtube-colored',
            },
          ]}
        />
      </Box>
    </InnerPaperContentContainerSection>
  );
}

function ProfileDetails() {
  return (
    <InnerPaperContentContainerSection>
      <Box flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.company-settings.company-details.header"
            variant="bodyMdBold"
          />

          <Text
            tx="label.company-settings.company-details.description"
            variant="bodyMd"
            color="formSectionDescription"
          />
        </Box>

        <WrapBox breakpoint="xs" width="100%" flex gap={16} flexAlign="center">
          <UploadLogo width={144} height={144} name="logo" />

          <Box flex flexDirection="column" width="100%" gap={8}>
            <ControlledInput
              maxLength={COMPANY_NAME_MAX_LENGTH}
              labelTx="label.company-settings.name.label"
              name="name"
            />

            <WrapBox breakpoint="sm" width="100%" flex gap={8}>
              <Box flexGrow={1}>
                <ControlledSelect
                  options={INDUSTRY_OPTIONS}
                  name="industry"
                  labelTx="label.company-settings.industry.label"
                />
              </Box>

              <Box flexGrow={1}>
                <ControlledSelect
                  options={EMPLOYEE_COUNT_OPTIONS}
                  name="employeeCount"
                  labelTx="label.company-settings.employees.label"
                />
              </Box>
            </WrapBox>
          </Box>
        </WrapBox>
      </Box>
    </InnerPaperContentContainerSection>
  );
}

function CompanySettingsForm(props: { defaultValues: UpdateCompanyForm }) {
  const { defaultValues } = props;

  const updateCompanyStatus = useSelector(UpdateCompanySelector.selectStatus);

  const dispatch = useDispatch();

  const formMethods = useForm<UpdateCompanyForm>({
    resolver: joiResolver(UpdateCompanyFormValidation),
    defaultValues,
  });

  const reset = () => {
    formMethods.reset();
  };

  const updateCompany = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(updateCompanyThunk(data));
      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset(data);
      }
    },
    (err) => {
      Logger.warning('updateCompany Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <FormProvider {...formMethods}>
      <NavBlocker shouldBlock={formMethods.formState.isDirty} />

      {(formMethods.formState.isDirty || updateCompanyStatus === 'pending') && (
        <UnsavedChangesBox
          onRevert={reset}
          onSave={updateCompany}
          isLoading={updateCompanyStatus === 'pending'}
        />
      )}

      <InnerPaperContentContainerSectionsContainer>
        <ProfileDetails />

        <VisibilityFilter />

        <Locations />

        <SocialMedia />

        <AdditionalInformation />
      </InnerPaperContentContainerSectionsContainer>
    </FormProvider>
  );
}

function useDefaultValues() {
  const company = useSelector(CompanySelector.selectData);
  const officeLocations = useSelector(
    OfficeLocationsSelector.selectOfficeLocations,
  );
  const officeLocationsStatus = useSelector(
    OfficeLocationsSelector.selectStatus,
  );

  const getDefaultValues =
    React.useCallback(async (): Promise<UpdateCompanyForm | null> => {
      if (!company || officeLocationsStatus !== 'completed') {
        return null;
      }

      const logo = await getFileFromUrl(company.logo.original.url);
      if (!logo) {
        return null;
      }

      return {
        locations: officeLocations,
        employeeCount: company.employeeCount,
        industry: company.industry,
        logo,
        name: company.name,
        tags: company.tags,
        facebookUrl: company.facebookUrl,
        instagramUrl: company.instagramUrl,
        linkedinUrl: company.linkedinUrl,
        tiktokUrl: company.tiktokUrl,
        twitterUrl: company.twitterUrl,
        youtubeUrl: company.youtubeUrl,
      };
    }, [company, officeLocationsStatus, officeLocations]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

const countryNameToFlagSrc = (countryName: string) => {
  const countryNameLower = countryName.toLowerCase();

  const test =
    assets.countryFlags[countryNameLower as keyof typeof assets.countryFlags];

  if (test) {
    return test;
  }

  return undefined;
};

const renderVisibilityFilterByCountry = (
  visibilityFilterByCountry: VisibilityFilterByCountry,
) => {
  const src = countryNameToFlagSrc(visibilityFilterByCountry.name);

  return (
    <Box
      flex
      flexDirection="column"
      key={visibilityFilterByCountry.orgNodeKey}
      gap={8}
    >
      <Box flex flexAlign="center" flexJustify="start" gap={8}>
        {!!src?.length && <Styled.Flag src={src} />}
        <Text text={visibilityFilterByCountry.name} variant="bodyMdBold" />
      </Box>

      {visibilityFilterByCountry.children.length > 0 ? (
        <Box flex gap={8} flexWrap>
          {visibilityFilterByCountry.children.map((orgNode) => (
            <Chip key={orgNode.orgNodeKey} text={orgNode.name} variant={3} />
          ))}
        </Box>
      ) : (
        <Chip
          tx="label.company-settings.visibility-filter.all-schools"
          variant={3}
        />
      )}
    </Box>
  );
};

function VisibilityFilter() {
  const visibilityFilterByCountry = useSelector(
    CompanyVisibilityFilterSelector.selectVisibilityFilterByCountry,
  );

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  return (
    <InnerPaperContentContainerSection>
      <Box flex flexJustify="between" flexAlign="start">
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.company-settings.visibility-filter.header"
            variant="bodyMdBold"
          />

          <Box flex flexDirection="column" flexAlign="start">
            <Text
              tx="label.company-settings.visibility-filter.description"
              variant="bodyMd"
              color="formSectionDescription"
            />

            <Link
              tx="label.company-settings.visibility-filter.link-text"
              href={orbiBusinessCalendarUrl}
            />
          </Box>
        </Box>
      </Box>

      <Box flex flexDirection="column" gap={16} mt={24}>
        {visibilityFilterByCountry?.map(renderVisibilityFilterByCountry)}
      </Box>
    </InnerPaperContentContainerSection>
  );
}

export function CompanySettings() {
  const dispatch = useDispatch();

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    dispatch(getOfficeLocationsThunk());
    dispatch(getCountriesThunk());
  }, [dispatch]);

  if (!defaultValues) {
    return <LoadingContainer />;
  }

  return (
    <PaperContentContainer>
      <InnerPaperContentContainer>
        <CompanySettingsForm defaultValues={defaultValues} />
      </InnerPaperContentContainer>
    </PaperContentContainer>
  );
}
