import { ORBI_BUSINESS_CALENDAR_URL } from '../constants';
import { AccountSelector, useSelector } from '../store';

export function useOrbiBusinessCalendar() {
  const email = useSelector(AccountSelector.selectEmail);
  const userKey = useSelector(AccountSelector.selectUserKey);

  if (!email || !userKey) {
    return { orbiBusinessCalendarUrl: ORBI_BUSINESS_CALENDAR_URL };
  }

  const searchParams = new URLSearchParams({
    utm_campaign: 'company_dashboard',
    utm_medium: userKey,
    email,
  });

  return {
    orbiBusinessCalendarUrl: `${ORBI_BUSINESS_CALENDAR_URL}?${searchParams}`,
  };
}
