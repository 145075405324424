import { SidebarContainer } from '@orbiapp/components';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import {
  MainSidebar,
  NoProfilesBanner,
  SignedInToolbar,
  VisibilityFilterBanner,
} from '../../components';
import {
  AccountSelector,
  AuthStateSelector,
  getCompanyProfilesThunk,
  useDispatch,
  useSelector,
} from '../../store';

function _DashboardOutlet() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCompanyProfilesThunk());
  }, [dispatch]);

  return (
    <React.Fragment>
      <NoProfilesBanner />

      <VisibilityFilterBanner />

      <SignedInToolbar />

      <SidebarContainer>
        <MainSidebar />

        <Outlet />
      </SidebarContainer>
    </React.Fragment>
  );
}
const DashboardOutlet = React.memo(_DashboardOutlet);
DashboardOutlet.displayName = 'DashboardOutlet';

export function SignedInOutlet() {
  const accountVerified = useSelector(AuthStateSelector.accountVerified);
  const onlyHasStudentWorkspace = useSelector(
    AccountSelector.selectOnlyHasStudentWorkspace,
  );
  const onlyHasDepartmentWorkspace = useSelector(
    AccountSelector.selectOnlyHasDepartmentWorkspace,
  );
  const hasCorrectRole = useSelector(AccountSelector.selectHasCorrectRole);
  const lastUsedDepartmentWorkspaceKey = useSelector(
    AccountSelector.selectLastUsedDepartmentWorkspaceKey,
  );

  if (onlyHasStudentWorkspace) {
    return <Navigate to="/access-denied-student" />;
  }

  if (onlyHasDepartmentWorkspace) {
    return (
      <Navigate
        to={`/workspace/department/${lastUsedDepartmentWorkspaceKey}`}
      />
    );
  }

  if (!hasCorrectRole) {
    return <Navigate to="/access-denied" replace />;
  }

  if (!accountVerified) {
    return <Navigate to="/email-not-verified" replace />;
  }

  return <DashboardOutlet />;
}
