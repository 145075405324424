import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Callout,
  ControlledInput,
  ControlledPasswordInput,
  Form,
  Icon,
  Input,
  Language,
  Link,
  OrbiLoader,
  ResponsiveBox,
  SignInForm,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSide,
  Text,
  usePasswordInput,
} from '@orbiapp/components';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { SignedOutToolbar } from '../../../components';
import { TERMS_AND_CONDITIONS_URL } from '../../../constants';
import {
  CreateAccountByInvitationForm,
  CreateAccountByInvitationValidation,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_NUMBERS,
  PASSWORD_MIN_SPECIAL_CHARACTERS,
  SignInValidation,
  formatIndustry,
} from '../../../models';
import {
  AuthStateSelector,
  CompanyInvitationSelector,
  CreateAccountByInvitationSelector,
  SignUpSelector,
  UpdateAccountByInvitationSelector,
  createAccountByInvitationThunk,
  getCompanyInvitationThunk,
  signOutThunk,
  updateAccountByInvitationThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { isAnyPending } from '../../../utils';

function UserExistsPasswordInput() {
  const { icon, inputType, toggleShowPassword } = usePasswordInput();

  return (
    <ControlledInput
      trailingElements={[
        {
          type: 'button',
          icon: icon,
          onClick: toggleShowPassword,
        },
      ]}
      labelTx="label.auth.password"
      name="password"
      type={inputType}
      errorTxArgs={{
        minCharacters: PASSWORD_MIN_LENGTH,
        minSpecialCharacters: PASSWORD_MIN_SPECIAL_CHARACTERS,
        minNumbers: PASSWORD_MIN_NUMBERS,
      }}
    />
  );
}

function UserExistsForm() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);

  const authStatus = useSelector(AuthStateSelector.selectStatus);
  const updateUserByInvitationStatus = useSelector(
    UpdateAccountByInvitationSelector.selectStatus,
  );

  const userExistsFormMethods = useForm<SignInForm>({
    defaultValues: { email: companyInvitation?.email ?? '', password: '' },
    resolver: joiResolver(SignInValidation),
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = userExistsFormMethods.handleSubmit(async (data) => {
    if (!companyInvitation) return;

    const res = await dispatch(
      updateAccountByInvitationThunk({
        email: data.email,
        password: data.password,
        companyInvitationKey: companyInvitation.companyInvitationKey,
        hasCompany: !!companyInvitation.companyDetails,
      }),
    );
    if (res.meta.requestStatus !== 'fulfilled') return;

    navigate(
      !!companyInvitation.companyDetails ? '/onboarded' : '/onboarding',
      {
        replace: true,
      },
    );
  });

  const isLoading = isAnyPending(authStatus, updateUserByInvitationStatus);

  return (
    <Form
      flex
      flexDirection="column"
      formMethods={userExistsFormMethods}
      gap={32}
      onSubmit={onSubmit}
      width="100%"
    >
      {companyInvitation?.companyDetails && (
        <Callout
          variant="info"
          subtitleTx="label.auth.sign-up.create-account-user-exists-callout"
          subtitleTxArgs={{
            companyName: companyInvitation.companyDetails.name,
          }}
        />
      )}

      <ControlledInput disabled labelTx="label.auth.email" name="email" />

      <UserExistsPasswordInput />

      <Button
        width="100%"
        isLoading={isLoading}
        tx="button.auth.log-in"
        type="submit"
        variant="primary"
        large
      />

      <Box flex flexJustify="center" flexAlign="center" gap={4}>
        <Link to="/reset-password" small tx="link.auth.forgot-your-password" />
      </Box>
    </Form>
  );
}

function SignUpFromInvitationForm() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);

  const createUserByInvitationStatus = useSelector(
    CreateAccountByInvitationSelector.selectStatus,
  );
  const authStatus = useSelector(AuthStateSelector.selectStatus);

  const joinOrbiFormMethods = useForm<CreateAccountByInvitationForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
    },
    resolver: joiResolver(CreateAccountByInvitationValidation),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = joinOrbiFormMethods.handleSubmit(async (data) => {
    if (!companyInvitation) return;

    const res = await dispatch(
      createAccountByInvitationThunk({
        companyInvitationKey: companyInvitation.companyInvitationKey,
        email: companyInvitation.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        hasCompany: !!companyInvitation.companyDetails,
        language: Language.EN,
      }),
    );
    if (res.meta.requestStatus !== 'fulfilled') return;

    navigate(
      !!companyInvitation.companyDetails ? '/onboarded' : '/onboarding',
      {
        replace: true,
      },
    );
  });

  const [firstName, lastName] = joinOrbiFormMethods.watch([
    'firstName',
    'lastName',
  ]);

  const clearFirstName = () => {
    joinOrbiFormMethods.setValue('firstName', '');
    joinOrbiFormMethods.setFocus('firstName');
  };

  const clearLastName = () => {
    joinOrbiFormMethods.setValue('lastName', '');
    joinOrbiFormMethods.setFocus('lastName');
  };

  const isLoading = isAnyPending(authStatus, createUserByInvitationStatus);

  return (
    <Form
      flex
      flexDirection="column"
      formMethods={joinOrbiFormMethods}
      gap={32}
      onSubmit={onSubmit}
      width="100%"
    >
      {companyInvitation?.companyDetails && (
        <Callout
          variant="info"
          subtitleTx="label.auth.sign-up.create-account-callout"
          subtitleTxArgs={{
            companyName: companyInvitation.companyDetails.name,
          }}
        />
      )}

      <Input
        defaultValue={companyInvitation?.email ?? ''}
        disabled
        labelTx="label.auth.email"
      />

      <ControlledInput
        disabled={isLoading}
        labelTx="label.auth.first-name"
        name="firstName"
        type="text"
        trailingElements={[
          {
            hidden: !firstName.length,
            type: 'button',
            icon: 'x-circle-outline',
            onClick: clearFirstName,
          },
        ]}
      />

      <ControlledInput
        disabled={isLoading}
        labelTx="label.auth.last-name"
        name="lastName"
        type="text"
        trailingElements={[
          {
            hidden: !lastName.length,
            type: 'button',
            icon: 'x-circle-outline',
            onClick: clearLastName,
          },
        ]}
      />

      <ControlledPasswordInput
        minCharacters={PASSWORD_MIN_LENGTH}
        minSpecialCharacters={PASSWORD_MIN_SPECIAL_CHARACTERS}
        minNumbers={PASSWORD_MIN_NUMBERS}
        labelTx="label.auth.password"
        name="password"
      />

      <Button
        variant="primary"
        tx="button.auth.create-account"
        type="submit"
        isLoading={isLoading}
        width="100%"
        large
      />

      <Box flex flexJustify="center">
        <ResponsiveBox
          xxs={
            <Box flex gap={4} flexAlign="center">
              <Text variant="bodySm" tx="label.auth.sign-up.terms" />
              <Link
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                small
                tx="label.auth.sign-up.terms-link"
              />
            </Box>
          }
        >
          <Box flex gap={4} flexAlign="center" flexDirection="column">
            <Text variant="bodySm" tx="label.auth.sign-up.terms" />
            <Link
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              small
              tx="label.auth.sign-up.terms-link"
            />
          </Box>
        </ResponsiveBox>
      </Box>
    </Form>
  );
}

function SignUpFromInvitationContent() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);
  const teamInvitationError = useSelector(
    CompanyInvitationSelector.selectError,
  );

  if (teamInvitationError || !companyInvitation) {
    return (
      <Box flex flexDirection="column" flexAlign="center" gap={32}>
        <Icon
          name="exclamation-triangle-solid"
          size={100}
          color="invitationNotFoundIcon"
        />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.not-found.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.not-found.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (companyInvitation.consumedAt) {
    return (
      <Box
        flex
        flexDirection="column"
        flexAlign="center"
        gap={32}
        data-testid="sign-up-by-invitation-consumed"
      >
        <Icon name="x-circle-solid" size={100} color="invalidInvitationIcon" />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.consumed.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.consumed.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (Date.now() > companyInvitation.expiresAt) {
    return (
      <Box
        flex
        flexDirection="column"
        flexAlign="center"
        gap={32}
        data-testid="sign-up-from-invitation-expired"
      >
        <Icon name="clock-solid" size={100} color="invitationExpiredIcon" />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.expired.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.expired.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (companyInvitation.userExists) {
    return (
      <React.Fragment>
        <Box flex flexDirection="column" gap={8}>
          <Text
            textAlign="center"
            tx="label.auth.sign-in.title"
            variant="titleMd"
            width="32ch"
            color="signUpRightSideTitle"
          />

          {!companyInvitation.companyDetails && (
            <Text
              tx="label.auth.sign-up.user-exists-subtitle-no-details"
              variant="bodyMd"
              color="signUpRightSideSubtitle"
              textAlign="center"
            />
          )}
        </Box>

        <UserExistsForm />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box flex flexDirection="column" gap={8}>
        <Text
          variant="titleMd"
          tx="label.auth.sign-up.title"
          color="signUpRightSideTitle"
          textAlign="center"
        />

        {!companyInvitation.companyDetails && (
          <Text
            tx="label.auth.sign-up.subtitle-no-details"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
            textAlign="center"
          />
        )}
      </Box>

      <SignUpFromInvitationForm />
    </React.Fragment>
  );
}

export function Content() {
  const { companyDetails, isConsumed, isExpired, teamInvitationStatus, error } =
    useSelector(CompanyInvitationSelector.selectSignUpByInvitationContent);

  const { companyInvitationKey } = useParams<{
    companyInvitationKey: string;
  }>();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!companyInvitationKey) return;

    dispatch(getCompanyInvitationThunk(companyInvitationKey));
  }, [dispatch, companyInvitationKey]);

  if (teamInvitationStatus !== 'completed' && !error) {
    return <OrbiLoader data-testid="sign-up-from-invitation-content-loader" />;
  }

  return (
    <React.Fragment>
      <SignedOutToolbar />

      {!companyDetails || isConsumed || isExpired ? (
        <SplitScreenContentContainer data-testid="sign-up-by-invitation-content">
          <SignUpFromInvitationContent />
        </SplitScreenContentContainer>
      ) : (
        <SplitScreenContainer data-testid="sign-up-by-invitation-content">
          <SplitScreenLeftSide
            data-testid="sign-up-from-invitation-left-side"
            avatarSrc={companyDetails.logo.thumbnail64.url}
            title={companyDetails.name}
            blurredCards={[
              {
                titleTx: 'label.auth.sign-up.followers',
                subtitle: companyDetails.followerCount,
                icon: 'user-group-outline',
              },
              {
                titleTx: 'label.auth.sign-up.industry',
                subtitle: formatIndustry(companyDetails.industry),
                icon: 'building-storefront-outline',
              },
              {
                titleTx: 'label.auth.sign-up.company-size',
                subtitle: companyDetails.employeeCount,
                icon: 'users-outline',
              },
            ]}
          />

          <SplitScreenContentContainer>
            <SignUpFromInvitationContent />
          </SplitScreenContentContainer>
        </SplitScreenContainer>
      )}
    </React.Fragment>
  );
}

export function SignUpFromInvitation() {
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  const isNotIdle = useSelector(SignUpSelector.selectIsNotIdle);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!authenticated || isNotIdle) return;

    dispatch(signOutThunk());
  }, [dispatch, authenticated, isNotIdle]);

  if (authenticated || isNotIdle) {
    return <OrbiLoader data-testid="sign-up-from-invitation-loader" />;
  }

  return <Content />;
}

export function SignUp() {
  return <Navigate to="/sign-in" replace />;
}
