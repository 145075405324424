import React from 'react';

type ScrollContextType = {
  onScroll: (scrollTop: number) => void;
  toolbarRef: React.RefObject<HTMLDivElement>;
};

export const ScrollContext = React.createContext<ScrollContextType>({} as any);

export function ScrollProvider(props: React.PropsWithChildren) {
  const scrollY = React.useRef(0);

  const toolbarRef = React.useRef<HTMLDivElement>(null);

  const onScroll = (scrollTop: number) => {
    if (scrollTop > scrollY.current) {
      toolbarRef.current?.classList.add('hidden');
    }

    if (scrollTop < scrollY.current) {
      toolbarRef.current?.classList.remove('hidden');
    }

    scrollY.current = scrollTop;
  };

  return (
    <ScrollContext.Provider value={{ onScroll, toolbarRef }}>
      {props.children}
    </ScrollContext.Provider>
  );
}
