import { getTextStyles } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const TitleInput = styled.input`
  ${(props) => {
    const { theme } = props;

    return css`
      ${getTextStyles('titleMd', theme)};

      border: none;
      outline: none;
      flex-grow: 1;

      &::placeholder {
        color: ${theme.colors.inputPlaceholder};
      }
    `;
  }}
`;

export const Styled = { TitleInput };
