import { joiResolver } from '@hookform/resolvers/joi';
import {
  Button,
  Confirm,
  FormBox,
  IconButton,
  InnerContentContainerAlt,
  Tooltip,
  flattenFieldErrorsObject,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import {
  FieldErrors,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate } from 'react-router';

import { NavBlocker, PublishButton } from '../../../../components';
import {
  CreateOfferForm,
  CreateOfferValidation,
  UpdateOfferDraftValidation,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  CreateOfferSelector,
  DeleteOfferDraftSelector,
  OfferDraftSelector,
  UpdateOfferDraftSelector,
  createOfferThunk,
  deleteOfferDraftThunk,
  globalUiStateActions,
  setAlert,
  updateOfferDraftThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import {
  ContactDetails,
  Countries,
  DiscountCodes,
  EndDate,
  GeneralInformation,
  Locations,
  OfferCategories,
  OfferFormHeader,
  StudentVerification,
  TypeOfOffer,
} from '../components';

function SaveOfferDraftButton() {
  const offerDraftKey = useSelector(OfferDraftSelector.selectOfferDraftKey);
  const updateOfferDraftStatus = useSelector(
    UpdateOfferDraftSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const formContext = useFormContext<CreateOfferForm>();

  const saveDraftHandler = async () => {
    if (!offerDraftKey) return;

    const createOfferForm = formContext.getValues();
    const result = UpdateOfferDraftValidation.validate(createOfferForm);
    if (result.error) {
      result.error.details.forEach((error) => {
        formContext.trigger(error.path.join('.') as any, { shouldFocus: true });
      });

      dispatch(setAlert('error-in-form'));
      return;
    }

    const res = await dispatch(
      updateOfferDraftThunk({ offerDraftKey, createOfferForm }),
    );
    if (res.meta.requestStatus === 'fulfilled') {
      formContext.reset(createOfferForm);
    }
  };

  return (
    <Button
      variant="secondary"
      tx="button.save"
      onClick={saveDraftHandler}
      isLoading={updateOfferDraftStatus === 'pending'}
      height={32}
    />
  );
}

function DeleteOfferDraftButton() {
  const deleteOfferDraftStatus = useSelector(
    DeleteOfferDraftSelector.selectStatus,
  );

  const offerDraftKey = useSelector(OfferDraftSelector.selectOfferDraftKey);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirmState = useConfirm();

  const deleteOfferDraft = async () => {
    if (!offerDraftKey) return;

    await dispatch(deleteOfferDraftThunk(offerDraftKey));

    confirmState.closeConfirm();

    navigate('/offers');
  };

  return (
    <React.Fragment>
      <Confirm
        onConfirm={deleteOfferDraft}
        onCancel={confirmState.closeConfirm}
        isOpen={confirmState.isOpen}
        isLoading={deleteOfferDraftStatus === 'pending'}
        titleTx="prompt.delete-offer-draft.title"
        messageTx="prompt.delete-offer-draft.message"
        cancelTx="prompt.delete-offer-draft.cancel"
        confirmTx="prompt.delete-offer-draft.confirm"
      />

      <Tooltip placement="bottom" titleTx="label.tooltip.delete">
        <IconButton onClick={confirmState.openConfirm} icon="trash-outline" />
      </Tooltip>
    </React.Fragment>
  );
}

function OfferFormContent(props: { defaultValues: CreateOfferForm }) {
  const { defaultValues } = props;

  const createdOfferKey = useSelector(CreateOfferSelector.selectData);
  const createOfferStatus = useSelector(CreateOfferSelector.selectStatus);

  const formMethods = useForm<CreateOfferForm>({
    defaultValues,
    resolver: joiResolver(CreateOfferValidation),
  });

  const editMeta = useSelector(OfferDraftSelector.selectEditMeta);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const publishOffer = async (createOfferForm: CreateOfferForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(createOfferThunk(createOfferForm));
    if (res.payload && 'offerKey' in res.payload) {
      navigate(`/offers/${res.payload.offerKey}`);
    }
  };

  const handleError = (err: FieldErrors<CreateOfferForm>) => {
    Logger.warning('createOffer Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  return (
    <FormProvider {...formMethods}>
      <InnerContentContainerAlt>
        <NavBlocker
          shouldBlock={formMethods.formState.isDirty && !createdOfferKey}
        />

        <OfferFormHeader editMeta={editMeta} isCreateOffer>
          <DeleteOfferDraftButton />

          <SaveOfferDraftButton />

          <PublishButton
            isLoading={createOfferStatus === 'pending'}
            onSubmit={publishOffer}
            onError={handleError}
            includeTimePicker
          />
        </OfferFormHeader>

        <FormBox>
          <GeneralInformation />

          <ContactDetails />

          <OfferCategories />

          <Countries />

          <TypeOfOffer />

          <DiscountCodes />

          <Locations />

          <StudentVerification />

          <EndDate />
        </FormBox>
      </InnerContentContainerAlt>
    </FormProvider>
  );
}

export function OfferForm(props: { defaultValues: CreateOfferForm }) {
  const { defaultValues } = props;

  return <OfferFormContent defaultValues={defaultValues} />;
}
